import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import styled from "styled-components";

//Custom Hooks
import { useAmplifyGet, useAmplifyPost } from "../hooks/useAmplifyApi";
import useRecentFeednames from "../hooks/useRecentFeedNames";

const StyledStreamSelector = styled.div`
  margin-bottom: 15px;

  input {
    width: 100%;
    height: 100%;
    border-width: 0px 0px 1px;
    font-size: 1em;
  }

  input:focus {
    border-color: Salmon;
    border-width: 0px 0px 2px;
  }
`;

export function StreamSelector(props) {
  const [recentFeeds, addRecentFeed] = useRecentFeednames();
  //const [streamList, streamListLoading, streamListFetchError] =
  // useAmplifyGet("stream_config/list");

  function makeEnterBlur(event) {
    if (event.keyCode === 13) {
      event.target.blur();
    }
  }

  const options = recentFeeds
  return (
    <StyledStreamSelector>
      <input
        underlined={"true"}
        onBlur={props.onBlur}
        onKeyDown={props.onKeyDown || makeEnterBlur}
        onChange={props.onChange}
        placeholder={props.placeholder || "Stream Name"}
        list="streamNames"
        value={props.value}
      />
      {options ? 
      <datalist id="streamNames">
        {options.map((streamName, index) => (
          <option key={index} value={streamName} />
        ))}
      </datalist>
      :
      null
}
    </StyledStreamSelector>
  );
}
