import React, { useEffect, useState } from "react";
//Components
import Bubble from "../Bubble";
//Custom Hooks
import { useAmplifyGet } from "../../hooks/useAmplifyApi";


function EndStateBubble({ id, streamName, execution_arn, status, removeSelf, pushToStaged, children }) {
  //Example ARN
  //arn:aws:states:us-west-2:382630417328:execution:create_emp_stream_no_emp:test2021avc1080daip1-lhr-1-76f88649-1873-4aa2-81e4-6116cbc94aa7

  const [executionData, executionLoading, executionError] = useAmplifyGet(
    "step_functions/read",
    { action: "status", execution_arn: execution_arn }
  );
  const [
    _arnLiteral,
    _awsLiteral,
    service,
    region,
    account,
    _executionLiteral,
    stateMachine,
    executionId,
  ] = execution_arn.split(":");

  const bubbleData = {
    name: streamName,
    execution_region: region,
    state_machine: stateMachine,
    execution_id: executionId,
  };

  function rerun() {
    pushToStaged({ id: id, name: streamName })
    removeSelf()
  }

  return (
    <Bubble
      data={bubbleData}
      headerKeys={["name", "state_machine", "execution_region"]}
      status={status}
      enabled={true}
      deleteSelf={removeSelf}
      rerun={rerun}
    >
      {children}
      {executionData ? (
        <div>
          <h5>Execution Data</h5>
          <code>{JSON.stringify(executionData, null, 2)}</code>
        </div>
      ) : null}
      {executionError ? (
        <div>
          <h5>Error In Execution</h5>
          <code>{executionError}</code>
        </div>
      ) : null}
    </Bubble>
  );
}

export default EndStateBubble;
