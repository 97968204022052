import React, { useEffect, useState } from 'react';
//Components
import Bubble from '../Bubble';
//Custom Hooks
import { useAmplifyGet } from '../../hooks/useAmplifyApi';
import { useInterval } from '../../hooks/useInterval';



function PollingBubble({ id, streamName, execution_arn, children, removeFromRunning, pushToComplete, pushToFailed }) {
    //Example ARN
    //arn:aws:states:us-west-2:382630417328:execution:create_emp_stream_no_emp:test2021avc1080daip1-lhr-1-76f88649-1873-4aa2-81e4-6116cbc94aa7
  
    const [executionData, executionLoading, executionError, run] = useAmplifyGet(
      "step_functions/read",
      { action: "status", execution_arn: execution_arn }
    );
    const stopPolling = useInterval(run, 5000);
    const [status, setStatus] = useState("Running");
    const [
      _arnLiteral,
      _awsLiteral,
      service,
      region,
      account,
      _executionLiteral,
      stateMachine,
      executionId,
    ] = execution_arn.split(":");
  
    const bubbleData = {
      name: streamName,
      execution_region: region,
      state_machine: stateMachine,
      execution_id: executionId,
    };
  
    useEffect(() => {
      if (executionData) {
        setStatus(executionData.status);
        console.log(status);
        switch (status.toUpperCase()) {
          case "FAILURE":
            pushToFailed({ name:streamName, id:id, execution_arn:execution_arn, status:status })
            removeFromRunning(id)
            stopPolling()
            break
          case "SUCCEEDED":
            pushToComplete({ name:streamName, id:id, execution_arn:execution_arn, status:status })
            removeFromRunning(id)
            stopPolling();
            break;
        }
      } else if (executionError) {
        console.log(executionError);
        setStatus("Error");
        stopPolling();
      }
    }, [executionData, executionError]);
  
    return (
      <Bubble
        data={bubbleData}
        headerKeys={["name", "state_machine", "execution_region"]}
        status={status}
        enabled={true}
      >
        {children}
        {executionData && status !== "Failure" ? (
          <div>
            <h5>Execution Data</h5>
            <code>{JSON.stringify(executionData, null, 2)}</code>
          </div>
        ) : null}
        {executionError ? (
          <div>
            <h5>Error In Execution</h5>
            <code>{executionError}</code>
          </div>
        ) : null}
      </Bubble>
    );
  }

  export default PollingBubble