import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Card, Container, Col, Row } from 'react-bootstrap';
import { useField, useFormikContext } from "formik";


export function EPDProfile({...props}) {
    console.log(props);
    const [field, meta] = useField(props);
    return(
            <Field {...props}
              type="text"
              placeholder="Enter EPD Profile ID"
              style={{width:"450px", marginBottom: "10px"}}
              className={'form-control font-size-0-8' + (meta.error && meta.touched ? "is-invalid" : '')}
            >
            </Field>
    );
}


export function VideoProfile({...props}) {
    console.log(props);
    const [field, meta] = useField(props);
    console.log(meta)
    console.log(field)
    const videoProfiles = [
        "Lynx_1080p_SFR_AVC_v1",
        "DASH/HLS AVC SFR 1080p P1 for Live DAI",
        "Lynx_720p_SFR_AVC_v1",
        "DASH/HLS AVC SFR 1080p P6 for Live DAI",
        "Jaguar UHD HDR HFR HEVC/H.265 P5 v3.0",
        "Jaguar 1080p HFR AVC P2 v3.0",
        "Jaguar 1080p HFR AVC P2 v3.0 (IntegTest Only)",
        "Lynx_1080p_HFR_AVC_v1",
        "Lynx_1080p_HFR_AVC_v2",
        "Lynx_720p_HFR_AVC_v3",
        "Jaguar 1080p HFR HEVC/H.265 P7 v3.0",
        "Jaguar 1080p HFR AVC P2 v4.0",
        "Cougar 1080p SFR AVC P1 v1.0",
        "Cougar 1080p HFR AVC P2 v1.0",
        "Cougar 1080p HFR HEVC P7 v1.0",
        "Hawk P2 Version 2.0",
        "Hawk P1 Version 2.0",
        "Hawk P2 Version 3.0",
        "Hawk P6 Version 3.0",
        "Hawk P7 Version 3.0"
    ]
    return (
        <Field style ={{marginBottom: "10px"}}
          {...props}
          as="select" 
          className={'form-control font-size-0-8' + (meta.error && meta.touched ? "is-invalid" : '')}
        >
        <option value=''>Choose...</option>
        {videoProfiles.map((op, idx) => (
            <option key={`videoprofile-idx-${op}`}   id={`idx-${op}`} >{op}</option>
        ))}
        </Field>
    );
}


export function Bugfile({...props}){
    const [field, meta] = useField(props);
    const pvbug = [
        "Not Applicable",
        "prime_bug_HD_60.png",
        "prime_bug_HD_60_720p.png",
        "prime_bug_HD_60_720p_lowerright.png",
        "WW_Prime-Video_Watermark_Keyed_REC709_108p_V2.png",
        "WW_Prime-Video_Watermark_Keyed_REC709_1080p_BottomRight.png"
    ]
    return(
        <Field style = {{marginBottom: "10px"}}
          {...props}
          as="select" 
          className={'form-control font-size-0-8' + (meta.errors && meta.touched ? ' is-invalid' : '')}
        >
        <option value=''>Choose...</option>
        {pvbug.map((op, idx) => (
            <option key={`pvbug-idx-${op}`}   id={`idx-${op}`} >{op}</option>
        ))}
        </Field>
    );
}


export function GPUPinning({...props}){
    const [field, meta] = useField(props);
    const gpudistribution = [
        "Not Applicable",
        "Standard",
        "Custom"
    ]
    return(
        <Field style={{marginBottom: "10px"}}
          {...props}
          as="select"
          className={'form-control font-size-0-8' + (meta.errors && meta.touched ? ' is-invalid' : '')}
        >
        <option value=''>Choose...</option>
        {gpudistribution.map((op, idx) => (
            <option key={`gpudistribution-idx-${op}`}   id={`idx-${op}`} >{op}</option>
        ))}
        </Field>
    );
}

export function GPUDistribution({...props}){
    const [field, meta] = useField(props);
    return (
        <Field 
          {...props}
          style={{width:"450px"}}
          type="text" placeholder="Enter GPU Distribution eg. 0-0-1,2-0.."
          className={'form-control font-size-0-8' + (meta.erros && meta.touched ? ' is-invalid' : '')}
        >
        </Field>
    );
}


export function DAIEnabled({...props}){
    const [field, meta] = useField(props);
    return(
        <Field style={{marginRight: "82px", marginBottom: "10px"}}
          {...props}
          type="checkbox"
          value="True"
          className={'form-control font-size-0-8' + (meta.errors && meta.touched ? ' is-invalid' : '')}
        >
        </Field>
        )
}

  
export function PseudoLiveFields({...props}){
    const [field, meta] = useField(props);
    const mappingFields = {
        "dai1": {
            "name": "P1 PseudoLive",
            "baseprofile": "baseprofile1pl",
            "videoProfile": "videoProfile1pl",
            "bugfile": "bugfile1pl",
            "gpuPinning": "gpuPinning1pl"
        },
        "dai2": {
            "name": "P2 PseudoLive",
            "baseprofile": "baseprofile2pl",
            "videoProfile": "videoProfile2pl",
            "bugfile": "bugfile2pl",
            "gpuPinning": "gpuPinning2pl"
        },
        "dai3": {
            "name": "P5 PseudoLive",
            "baseprofile": "baseprofile3pl",
            "videoProfile": "videoProfile3pl",
            "bugfile": "bugfile3pl",
            "gpuPinning": "gpuPinning3pl"
        },
        "dai4": {
            "name": "P7 PseudoLive",
            "baseprofile": "baseprofile4pl",
            "videoProfile": "videoProfile4pl",
            "bugfile": "bugfile4pl",
            "gpuPinning": "gpuPinning4pl"
        }
        
    }
        return(
        <Row {...props} {...field}>
        <Row style = {{marginBottom: "5px"}}>
        <label htmlFor="Title" style={{marginRight: "32px", }}><strong>{mappingFields[field.name]["name"]}</strong></label>
        </Row>
        <Row>
        <label htmlFor={mappingFields[field.name]["baseprofile"]} style={{marginRight: "32px"}}><strong>EPD Profile ID:</strong></label>
        <EPDProfile name= {mappingFields[field.name]["baseprofile"]} />
        </Row>
        <Row>
        <label htmlFor={mappingFields[field.name]["videoProfile"]} style={{marginRight: "39px"}}><strong>Video Profile:</strong></label>
        <VideoProfile name= {mappingFields[field.name]["videoProfile"]} />
        </Row>
        <Row>
        <label htmlFor={mappingFields[field.name]["bugfile"]} style={{marginRight: "14px"}}><strong>Prime Video Bug:</strong></label>
        <Bugfile name = {mappingFields[field.name]["bugfile"]}/>
        </Row>
        <Row>
        <label htmlFor={mappingFields[field.name]["gpuPinning"]} style={{marginRight: "41px"}}><strong>GPU Pinning:</strong></label>
        <GPUPinning name={mappingFields[field.name]["gpuPinning"]}/>
        </Row>
        </Row>
        )
}


export function ProfileFields({...props}){
    const [field, meta] = useField(props);
    const mappingFields = {
        "profile1": {
            "baseprofile": "baseprofile1",
            "videoProfile": "videoProfile1",
            "bugfile": "bugfile1",
            "gpuPinning": "gpuPinning1"
        },
        "profile2": {
            "baseprofile": "baseprofile2",
            "videoProfile": "videoProfile2",
            "bugfile": "bugfile2",
            "gpuPinning": "gpuPinning2"
        },
        "profile3": {
            "baseprofile": "baseprofile3",
            "videoProfile": "videoProfile3",
            "bugfile": "bugfile3",
            "gpuPinning": "gpuPinning3"
        },
        "profile4": {
            "baseprofile": "baseprofile4",
            "videoProfile": "videoProfile4",
            "bugfile": "bugfile4",
            "gpuPinning": "gpuPinning4"
        }
    }
    return (
        <div>
            <Row>
            <label htmlFor={mappingFields[field.name]["baseprofile"]} style={{marginRight: "32px"}}><strong>EPD Profile ID:</strong></label>
            <EPDProfile name= {mappingFields[field.name]["baseprofile"]} />
            </Row>
            <Row>
            <label htmlFor={mappingFields[field.name]["videoProfile"]} style={{marginRight: "39px"}}><strong>Video Profile:</strong></label>
            <VideoProfile name= {mappingFields[field.name]["videoProfile"]} />
            </Row>
            <Row>
            <label htmlFor={mappingFields[field.name]["bugfile"]} style={{marginRight: "14px"}}><strong>Prime Video Bug:</strong></label>
            <Bugfile name = {mappingFields[field.name]["bugfile"]}/>
            </Row>
            <Row>
            <label htmlFor={mappingFields[field.name]["gpuPinning"]} style={{marginRight: "41px"}}><strong>GPU Pinning:</strong></label>
            <GPUPinning name={mappingFields[field.name]["gpuPinning"]}/>
            </Row>
        </div>
    )
}


export function AudioTracks({...props}){
    const [field, meta] = useField(props);
    return(
        <Field {...props}
          type="text" placeholder="all audio tracks eg.1,2,3"
          className={'form-control font-size-0-10' + (meta.errors && meta.touched ? ' is-invalid' : '')}
        >
        </Field>
        )
}


export function DDPTracks({...props}){
    const [field, meta] = useField(props);
    return(
        <Field 
          {...props}
          type="text" placeholder="eg.3,4,5(if 3,4,5 are ddp tracks)"
          className={'form-control font-size-0-10' + (meta.errors && meta.touched ? ' is-invalid' : '')}
        >
        </Field>
    )
}

export function ATMOSTracks({...props}){
    const [field, meta] = useField(props);
    return(
        <Field 
          {...props}
          type="text" placeholder="eg.3,4,5,6,7(if 3,4,5.6.7 are atmos tracks)"
          className={'form-control font-size-0-10' + (meta.errors && meta.touched ? ' is-invalid' : '')}
        >
        </Field>
    )
}


export function DualOutput({...props}){
    const [field, meta] = useField(props);
    const dualoutputgroups = [
        "Single Output",
        "Shared Subregion",
        "NonPL/PL Combined"
    ]
    return(
        <Field 
          {...props}
          as="select" 
          className={'form-control font-size-0-8' + (meta.errors && meta.touched ? ' is-invalid' : '')}
        >
        <option value=''>Choose...</option>
        {dualoutputgroups.map((op, idx) => (
            <option key={`dualoutputgroup-idx-${op}`}   id={`idx-${op}`} >{op}</option>
        ))}
        </Field>
    )
}


export function UDPOutput({...props}){
    const [field, meta] = useField(props);
    return(
        <Field
          {...props}
          type="checkbox"
          value="True"
          className={'form-control font-size-0-8' + (meta.errors && meta.touched ? ' is-invalid' : '')}
        >
        </Field>
    )
}


export function DisasterRecovery({...props}){
    const [field, meta] = useField(props);
    return(
        <Field
          {...props}
          type="checkbox"
          value="True"
          className={'form-control font-size-0-8' + (meta.errors && meta.touched ? ' is-invalid' : '')}
        >
        </Field>
    )
}