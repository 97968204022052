import React, { useState, useContext } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { API, Auth } from 'aws-amplify';
import ReactJson from 'react-json-view';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { MemberRestrictedSection } from '../components/RoleBasedAccessControl';

import { AuthContext } from '../contexts/AuthProvider.js';
import { ColumnFlexPage } from '../components/Page';
import { StatusBadge } from '../components/StatusBadge';
import Bubble, { BubbleGroup } from '../components/Bubble';
import { Button } from '../components/BasicControls';


function Review(props) {
    console.log(props);
    let location = useLocation();
    let crUrl = location.pathname.split("/")[2];
    const [crStatus, setCrStatus] = useState("STATUS");
    const [streams, setStreams] = useState([{ name: crUrl, command: '', status: crStatus, data: "preview cr here" , active: true }]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [value, setValue] = useState("Add comment here");
    const [description, setDescription] = useState("");
    let newJson = "";
    const [revision, setRevision] = useState(0);
    const [created, setCreated] = useState("");
    const [author, setAuthor] =useState("");
    const [streamName, setStreamName] = useState("");
    let history = useHistory();
    
    
    const LoadingIndicator = props => {
       const { promiseInProgress } = usePromiseTracker();
       return (
         promiseInProgress &&
         <div
           style={{
           width: "100%",
           height: "100",
           display: "flex",
           justifyContent: "center",
           alignItems: "center"
         }}
       >
         <h1>Loading... </h1>
       </div>
       );  
     };
    
    const {user} = useContext(AuthContext);
    let userName = JSON.parse(user.attributes.identities)[0].userId;


    function bubbleClickHandler(i) {
        const originalValue = streams[i].active;
        setStreams(streams => {
          let newStreams = streams.slice();
          newStreams.map((value, index) => {
            newStreams[index].active = false;
          });
          newStreams[i].active = !originalValue;
          setActiveIndex(i);
          console.log(newStreams);
          return newStreams;
        });
    }


    function setActiveBubble(newValues) {
      setStreams(streams => {
        let newStreams = streams.slice();
        Object.keys(newValues).map(function(key, index) {
          newStreams[activeIndex][key] = newValues[key];
        });
        return newStreams;
      });
    }

    // checks if the json is empty
    function isEmpty( obj ) {
      for ( var name in obj ) {
          return false;
      }
      return true;
    }

    function onEdit(event, type) {
      console.log(event);
      newJson = JSON.stringify(event.updated_src);
      console.log(newJson);
      return true;
    }

    function onAdd(event, type) {
      console.log(event);
      newJson = JSON.stringify(event.updated_src);
      return true;
    }
    
    function onDelete(event,type) {
      console.log(event);
      newJson = JSON.stringify(event.updated_src);
      return true;
    }

    // fetches the CR from the data table
    function bubbleGetCr(path, cr_ID) {
      setActiveBubble({
        status: "Running",
        command: "get_cr"
      });
      trackPromise(
      API.get(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            headers: {
              'Cache-Control': 'max-age=87000',
              'Content-type': 'application/json',
            },
            queryStringParameters: {
              cr_id: cr_ID
            }
          })
        .then(response => {
          // updates the revision in the url based on the response
          history.push(`/review/${cr_ID}/revisions/${response._version}\#`);
          setCrStatus(response.status);
          setRevision(response._version);
          setCreated(response._created);
          setAuthor(response.author);
          if (response.property_id){
            console.log(response)
            setStreamName(response.property_id);
            setActiveBubble({
              data: JSON.stringify(response, null, 2),
              status: "Success"
            });
          }
          else if (response.new_blob.onboard_cr_payload){
            console.log(response)
            setStreamName(response.stream_names);
            setActiveBubble({
              data: JSON.stringify(response.new_blob.onboard_cr_payload, null, 2),
              status: "Success"
            });
          }
          else if (response.new_blob.group_id){
            console.log(response)
            setStreamName(response.new_blob.group_id);
            setActiveBubble({
              data: JSON.stringify(response, null, 2),
              status: "Success"
            });
          }
          else {
            console.log(response)
            setStreamName(response.stream_names);
            setActiveBubble({
            data: JSON.stringify(response, null, 2),
            status: "Success"
          });
          }
          setDescription(response.cr_description);
          console.log(description);
          console.log(response);
          console.log(response._version);
        })
        .catch(error => {
          console.log(error);
          console.log(error.response);
          setActiveBubble({
            data: error.response.data,
            status: "Failure"
          });
        }));
    }

    // submits a CR
    function bubbleSubmit(path, cr_id, status) {
      setActiveBubble({
        status: "Running"
      });
      console.log(newJson);
      console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
      trackPromise(
      API.post(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            response: true,
            body: {
              cr_id: cr_id,
              new_data: {
                "status": status,
                "author": author,
                "new_blob": newJson,
                "cr_description": description,
              }
            }
          })
        .then(response => {
          console.log(response);
          setCrStatus(response.data.status);
          setRevision(response.data._version);
          setActiveBubble({
            data: JSON.stringify(response.data, null, 2),
            status: "Success"
          });
        })
        .catch(error => {
          console.log(error.response);
          setActiveBubble({
            data: error.response.data,
            status: "Failure"
          });
        }));
    }

    // Delete CR
    function bubbleDelete(path, cr_id) {
      setActiveBubble({
        status: "Running"
      });
      console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
      trackPromise(
      API.del(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            body: {
              cr_id: cr_id
            }
          })
        .then(response => {
          setCrStatus("deleted");
          console.log(response);
          setActiveBubble({
            data: JSON.stringify(response, null, 2),
            status: "Success"
          });
          console.log(response);
        })
        .catch(error => {
          console.log(error.response);
          setActiveBubble({
            data: error.response.data,
            status: "Failure"
          });
        }));
    }

    // Adds comments to the CR
    function bubblePut(path, cr_id) {
      setActiveBubble({
        status: "Running"
      });
      // checks if user has entered a comment
      if (value !== "Add comment here"){
        trackPromise(
        API.put(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            response:true,
            body: {
              "cr_id": cr_id,
              "cr_author": author,
              "comment_author": userName,
              "comment_text": value,
            }
          })
        .then(response => {
          // changes the status to draft once the comment is successfully added
          bubbleSubmit("redactor/cr", cr_id, "DRAFT");
          setActiveBubble({
            data: JSON.stringify(response.data, null, 2),
            status: "Success"
          });
          console.log(response.data);
        })
        .catch(error => {
          console.log(error);
          console.log(error.response);
          setActiveBubble({
            data: error.response.data,
            status: "Failure"
          });
        }));
      }
      // prompts user to add a comment
      else {
        alert('Please add comment');
        return;
      }
    }
      

    // merges/uploads the approved configs
    function bubblePost(path, changedConfig, action) {
      setActiveBubble({
        status: "Running"
      });
      console.log(streamName)
      console.log(action)
      console.log(path)
      trackPromise(
      API.post(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            response: true,
            body: {
              "action": action,
              "stream_names": streamName,
              "config_dicts": changedConfig
            }
          })
        .then(response => {
          setCrStatus("MERGED");
          console.log(response);
          setActiveBubble({
            data: JSON.stringify(response.data, null, 2),
            status: "Success"
          });
          console.log(response.data);
          // if newly uploaded configs then marks it as "pending deployment"
          if (action == "upload_config"){
            bubblePost("redactor/write",changedConfig,"pending_deployment")
          }
          // updates the status of the CR to merged
          bubbleSubmit("redactor/cr", crUrl, "MERGED");
        })
        .catch(error => {
          console.log(error);
          console.log(error.response);
          setActiveBubble({
            data: error.response.data,
            status: "Failure"
          });
        }));
    }

    function bubbleMerge(path, changedConfig, action) {
      setActiveBubble({
        status: "Running"
      });
      console.log(streamName)
      console.log(action)
      console.log(path)
      trackPromise(
      API.post(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            response: true,
            body: {
              "action": action,
              "config_dicts": changedConfig
            }
          })
        .then(response => {
          setCrStatus("MERGED");
          console.log(response);
          setActiveBubble({
            data: JSON.stringify(response.data, null, 2),
            status: "Success"
          });
          console.log(response.data);
          // if newly uploaded configs then marks it as "pending deployment"
          // updates the status of the CR to merged
          bubbleSubmit("redactor/cr", crUrl, "MERGED");
        })
        .catch(error => {
          console.log(error);
          console.log(error.response);
          setActiveBubble({
            data: error.response.data,
            status: "Failure"
          });
        }));
    }
    
    // Converts the config to a json, as ReactJson needs a Json input
    function getJson(config){
      console.log(config);
      if (config.groupId){
        console.log("inside get json function")
        return config;
      }
      else if(config.includes("preview cr here")){
        return {"value": config};
      }
      else if (config.includes("Deleted")){
        return {"value": config};
      }
      else if (config.includes("Traceback")){
        return {"Error": config};
      }
      else if (config.includes("Error")){
        console.log("returning error here")
        return {"Error": config};
      }
      else if (config.includes("invalid")){
        console.log("returning invalid here")
        return {"Error": config};
      }
      else if (config.includes("arn")){
        console.log("returning arn here")
        return {"Response": config};
      }
      else{
        let data = JSON.parse(config);
        if (data.new_blob.onboard_property_payload){
          console.log(data.new_blob.cr_payload)
          return data.new_blob.cr_payload;
        }
        if (data.new_blob.cs_names){
          console.log(data.new_blob.cs_names)
          return data.new_blob;
        }
        else if(data.new_blob){
          data = JSON.parse(data.new_blob);
          console.log(data);
          return data;
        }
        else{
          return data;
        }
      }
    }


    function onboardGroup(path, changedConfig, action) {
      setActiveBubble({
        status: "Running"
      });
      console.log(changedConfig)
      console.log(action)
      console.log(path)
      API.post(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            response: true,
            body: changedConfig
          })
        .then(response => {
          console.log(response);
          console.log(typeof(response.data))
          if (response.data.groupId) {
            console.log("created group here")
            setCrStatus("CREATED GROUP")
            SubmitCR("redactor/cr", crUrl, "CREATED GROUP", response.data);
          }
          else if (response.data.includes("Error")){
            console.log("Failed here")
            setCrStatus("FAILED")
            SubmitCR("redactor/cr", crUrl, "FAILED", response.data);
          }
          console.log("here in oboard group")
          setActiveBubble({
            data: response.data,
            status: crStatus
          });
          console.log(response.data);
          
        })
        .catch(error => {
          console.log(error);
          console.log(error.response);
          console.log("errored here")
          setActiveBubble({
            data: error.response.data,
            status: "Failure"
          });
        });
    }
    
    function SubmitCR(path, cr_id, status, result) {
      setActiveBubble({
        status: "Running"
      });
      console.log(newJson);
      console.log(result);
      console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
      API.post(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            response: true,
            body: {
              cr_id: cr_id,
              new_data: {
                "status": status,
                "author": author,
                "new_blob": newJson,
                "cr_description": description,
              }
            }
          })
        .then(response => {
          console.log(response);
          if (result.groupId){
            console.log("result has groupID")
            console.log(result)
            setActiveBubble({
              data: result,
              status: status
            });
          }
          else {
            console.log("result:",result)
            setActiveBubble({
              data: JSON.stringify(result, null, 2),
              status: status
            });
          }
        })
        .catch(error => {
          console.log(error.response);
          console.log("error here")
          setActiveBubble({
            data: error.response.data,
            status: "Failure"
          });
        });
    }
    
    function onboardCs(path, changedConfig, action) {
      setActiveBubble({
        status: "Running"
      });
      console.log(changedConfig)
      console.log(changedConfig.group_id)
      console.log(action)
      console.log(path)
      console.log(changedConfig["uuid"])
      console.log(changedConfig["infra_group"])
      console.log((changedConfig.environment).toLowerCase())
      const headers = {
        "Content-Type": "application/json",
      };
      const requestConfig = {
        headers: headers,
        body: {
            action: action,
            state_machine: "onboard_cs_existing_group",
            cs_names: changedConfig.cs_names,
            group_id: changedConfig.group_id,
            environment: process.env.REACT_APP_DV_LIVE_ENVIRONMENT,
            infra_group: changedConfig.infra_group,
            uuid: changedConfig.uuid,
          },
      };
      console.log(requestConfig)
      API.post(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, requestConfig)
        .then(response => {
          console.log(response);
          if (response.includes("arn")) {
            console.log("created CS")
            setCrStatus("IN_PROGRESS")
            SubmitCR("redactor/cr", crUrl, "IN_PROGRESS", response);
          }
          else if (response.data.includes("Error")){
            console.log("Errored while creating CS")
            setCrStatus("FAILED")
            SubmitCR("redactor/cr", crUrl, "FAILED", response.data);
          }
        })
        .catch(error => {
          console.log(error);
          console.log(error.response);
          if (error.response.data){
            console.log("Failed creating CS")
            setCrStatus("FAILED")
            SubmitCR("redactor/cr", crUrl, "FAILED", error.response.data);
          }
          console.log("error cs")
        });
    }


    // Converts the input config to a string as ReactDiffViewer accepts string inputs
    function getValue(config, type){
      console.log(config);
      if(config.includes("preview cr here")){
        return config;
      }
      if(config.includes("invalid")){
        console.log("inside invalid")
        return config;
      }
      if(config.includes("Deleted")){
        return config;
      }
      if(config.includes("Merged")){
        return config;
      }
      if(config.includes("merged")){
        return config;
      }
      if(config.includes("Traceback")){
        return config;
      }
      if(config.includes("uploaded")){
        return config;
      }
      if(config.includes("updated")){
        return config;
      }
      if(config.includes("invalid")){
        console.log("inside invalid")
        return config;
      }
      if(type == "old"){
        let data = JSON.parse(config);
        console.log(data.original_blob)
        // in case of new configs the original blob would be empty, so return nothing
        if(isEmpty(data.original_blob)){
          return "";
        }
        else{
          // arranging the sequence of key-value pairs in json, to show the diff
          data = JSONstringifyOrder(JSON.parse(data.original_blob), 2);
          console.log(data);
          return data;
        }
      }
      if(type == "new"){
        let data = JSON.parse(config);
        if (data.new_blob !== "" && data.new_blob.onboard_property_payload){
          data = JSONstringifyOrder(data.new_blob.cr_payload, 2);
          console.log(data);
          return data;
        }
        if(data.new_blob !== ""){
          // arranging the sequence of key-value pairs in json, to show the diff
          data = JSONstringifyOrder(JSON.parse(data.new_blob), 2);
          console.log(data);
          return data;
        }
        else{
          return null;
        }
      }
    }
    
    // parsing the comments into a json
    function getComments(config){
      console.log(config);
      if (config.includes("preview cr here")){
        return null;
      }
      else if(config.includes("Deleted")){
        return null;
      }
      else if(config.includes("Traceback")){
        return null;
      }
      else{
        let data = JSON.parse(config).comments;
        if(data !== null){
          console.log(data);
          return data;
        }
      }
    }
    
    // arranges the key-values in json in an order
    function JSONstringifyOrder( config, space )
    {
      var allKeys = [];
      var seen = {};
      JSON.stringify(config, function (key, value) {
          if (!(key in seen)) {
              allKeys.push(key);
              seen[key] = null;
          }
          return value;
      });
      allKeys.sort();
      return JSON.stringify(config, allKeys, space);
    }

    // checks if the config is a new config or existng 
    // and accordingly calls the corresponding stream config handler
    function mergeChanges(config){
      if (config.includes("preview cr here")){
        return null;
      }
      else if(config.includes("Traceback")){
        return null;
      }
      else if(config.includes("property_id")){
        let data = JSON.parse(config).new_blob
        let old_data = JSON.parse(config).original_blob
        if ((data !== null) && (isEmpty(old_data))){
          let newConfig = JSON.parse(data);
          bubbleMerge("redactor/write", newConfig, "upload_tag_configs")
        }
        else if(isEmpty(old_data)!== true){
          let changedConfig = JSON.parse(data);
          bubbleMerge("redactor/write", changedConfig, "merge_tag_config");
        }
      }
      else if(JSON.parse(config).new_blob.onboard_property_payload){
        let data = JSON.parse(config).new_blob.onboard_property_payload
        let old_data = JSON.parse(config).original_blob
        console.log(data)
        if ((data !== null) && (isEmpty(old_data))){
          console.log(data)
          onboardGroup("nexium/onboard_cs/new_group", data, "")
        }
      }
      else if(config.includes("group_id")){
        let data = JSON.parse(config).new_blob
        let old_data = JSON.parse(config).original_blob
        if ((data !== null) && (isEmpty(old_data))){
          let newConfig = JSON.parse(data);
          onboardCs("step_functions/write", newConfig, "start_cs_onboard")
        }
      }
      else{
        let data = JSON.parse(config).new_blob;
        let old_data = JSON.parse(config).original_blob
        console.log(JSON.parse(config).stream_names)
        if (JSON.parse(config).stream_names === "Multiple Config Changes"){
          let changedConfig = JSON.parse(data);
          bubblePost("stream_config/merge", changedConfig, "merge_config_list" )
        }
        else if((data !== null) && (isEmpty(old_data))){
          let changedConfig = JSON.parse(data);
          bubblePost("stream_config/upload",changedConfig, "upload_config")
        }
        else if(data !== null){
          let changedConfig = JSON.parse(data);
          bubblePost("stream_config/merge",changedConfig, "merge_config_list");
        }
        else{
          return null;
        }
      }
    }

    return (
      <ColumnFlexPage style={{paddingLeft:"30px", paddingRight:"50px", paddingTop:"10px", boxSizing:"border-box", width:"100vw", backgroundColor:"white"}}>
      <LoadingIndicator/>
      <div name="Header" style={{display:"flex", justifyContent:"space-between"}}>
            <h2>Author:{author}</h2>
            <h2>Revision:{revision}</h2>
            <h2>Created:{created}</h2>
            <StatusBadge status={crStatus}/>
      </div>
      <h3>{streamName}</h3>
      { crStatus === "DRAFT"?
      <input style= {{top: "40px", left: "10px", padding: "1rem"}} type="text" value={description} onChange={(e)=>setDescription(e.target.value)}></input>:
      <h4>CR Description:{description}</h4>}
      <BubbleGroup id="bubbleGroup" style={{marginRight:"40px"}}>
        {streams.map((stream, index) => (
        <Bubble active={true} key={index} data={stream} headerKeys={['name', 'command']} headerClick={() => bubbleClickHandler(index)}>
        { crStatus === "UNDER_REVIEW" ?
          <ReactDiffViewer oldValue={getValue(stream.data, "old")} newValue={getValue(stream.data, "new")} compareMethod={DiffMethod.LINES} splitView={false} />
          :
          crStatus === "DRAFT"?
          <ReactJson src={getJson(stream.data)}
            name={stream.name}
            collapsed={2}
            onEdit={(e)=>onEdit(e)}
            onAdd={(e)=>onAdd(e)}
            onDelete={(e)=>onDelete(e)}
            displayDataTypes={false}
            displayObjectSize={false}
            displayArrayKey={false}
            theme="bright-inverted"
          />
          :
          crStatus === "FAILED"?
          <ReactJson src={getJson(stream.data)}
            name={stream.name}
            collapsed={2}
            onEdit={false}
            onAdd={false}
            onDelete={false}
            displayDataTypes={false}
            displayObjectSize={false}
            displayArrayKey={false}
            theme="bright-inverted"
          />
          :
          crStatus === "CREATED GROUP"?
          <ReactJson src={getJson(stream.data)}
            name={stream.name}
            collapsed={2}
            onEdit={false}
            onAdd={false}
            onDelete={false}
            displayDataTypes={false}
            displayObjectSize={false}
            displayArrayKey={false}
            theme="bright-inverted"
          />
          :
          crStatus === "IN_PROGRESS"?
          <ReactJson src={getJson(stream.data)}
            name={stream.name}
            collapsed={2}
            onEdit={false}
            onAdd={false}
            onDelete={false}
            displayDataTypes={false}
            displayObjectSize={false}
            displayArrayKey={false}
            theme="bright-inverted"
          />
          :
          <ReactDiffViewer oldValue={getValue(stream.data, "old")} newValue={getValue(stream.data, "new")} compareMethod={DiffMethod.LINES} splitView={false} />
        }
        { crStatus === "DRAFT"?
          <div>
            <Button style= {{bottom: "50px", right: "230px", padding: "1rem", position: "fixed"}} onClick={() => bubbleSubmit("redactor/cr", crUrl, "UNDER_REVIEW")}>Submit CR</Button>
            <Button style= {{bottom: "50px", right: "130px", padding: "1rem", position: "fixed"}} onClick={() => bubbleDelete("redactor/cr", crUrl)}>Discard CR</Button>
          </div>:
          crStatus === "UNDER_REVIEW"?
          <MemberRestrictedSection includeGroups={["redactor-edit-create-config-access"]}>
            <Button style= {{bottom: "50px", right: "340px", padding: "1rem", position: "fixed"}} onClick={() => bubblePut("redactor/cr/comments", crUrl)}>Add Comment</Button>
            <Button style= {{bottom: "50px", right: "230px", padding: "1rem", position: "fixed"}} onClick={() => bubbleSubmit("redactor/cr", crUrl, "APPROVED")}>Approve CR</Button>
            <Button style= {{bottom: "50px", right: "130px", padding: "1rem", position: "fixed"}} onClick={() => bubbleDelete("redactor/cr", crUrl)}>Discard CR</Button>
          </MemberRestrictedSection>:
          crStatus === "APPROVED"?
          <MemberRestrictedSection includeGroups={["redactor-edit-create-config-access"]}>
            <Button style= {{bottom: "50px", right: "280px", padding: "1rem", position: "fixed"}} onClick={() => mergeChanges(stream.data)}>Merge CR</Button>
            <Button style= {{bottom: "50px", right: "130px", padding: "1rem", position: "fixed"}} onClick={() => bubbleDelete("redactor/cr", crUrl)}>Discard CR</Button>
          </MemberRestrictedSection>:
          crStatus === "STATUS"?
            <Button style= {{bottom: "50px", right: "180px", padding: "1rem", position: "fixed"}} onClick={() => bubbleGetCr("redactor/cr", crUrl)}>Preview CR</Button>:
            null
        }
        </Bubble>
        ))}
      </BubbleGroup>
      {crStatus === "DRAFT"?
      <div>
      <BubbleGroup id="bubbleGroup" style={{marginBottom:"10px", borderRadius:"100px"}}>
        {streams.map((stream, index) => (
        <Bubble active={true} key={index} data={stream} headerKeys={[]} headerClick={() => bubbleClickHandler(index)}>
        <ReactJson src={getComments(stream.data)}
            name="comments"
            collapsed={false}
            onEdit={false}
            onAdd={false}
            onDelete={false}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={false}
            displayArrayKey={false}
            theme="bright-inverted"
          />
        </Bubble>
        ))}
      </BubbleGroup>
      </div>:
      crStatus === "DELETED"?
      null:
      <input style= {{bottom: "40px", left: "40px", padding: "1rem"}} type="text" value={value} onChange={(e)=>setValue(e.target.value)}></input>
      }
      </ColumnFlexPage>
    );
}

export default Review;