import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import DateTime from 'luxon/src/datetime.js'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { API, Auth } from 'aws-amplify';

//Components
import { ColumnFlexPage } from '../components/Page'
import { StreamSelector } from '../components/StreamSelector'
import { Button, StyledInput } from '../components/BasicControls'
import { StatusBadge } from '../components/StatusBadge'






const ResultBadge = styled.div `
  padding: 0 2rem;
  border-radius: 50px;
  background-color: ${props => props.color};
  margin: 5px 0 5px 0;
  color: white;
  text-align: center;
`

const Label = styled.div`
  background-color: white;
  padding: 0.5rem;
`

export default function Beaker() {
  const rowSpacer = "15px"
  const [streamName, setStreamName] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [timestampHuman, setTimestampHuman] = useState("");
  const [status, setStatus] = useState("Ready")
  const [showVerbose, setShowVerbose] = useState(false)
  const [showCopiedAlert, setShowCopiedAlert] = useState(false)
  const additionalInfoRef = useRef(null);
  const verboseRef = useRef(null);
  
  const [analysis, setAnalysis] = useState("What we think the issue is")
  const [nextAction, setNextAction] = useState("What you should do")
  const [additionalInfo, setAdditionalInfo] = useState({})
  const [verbose, setVerbose] = useState("Super giant garbage")
  const [errorText, setErrorText] = useState("")
  
  
  function handleTimestamp(val){
    console.log(val)
    try{
      const parsedTimestamp = DateTime.fromISO(val, {zone:'utc'})
      // Example: 2020-12-17T04:29:53.928Z
      const formattedTimestamp = parsedTimestamp.toString()
      const humanFormattedTimestamp = parsedTimestamp.setZone('local').toLocaleString(DateTime.DATETIME_FULL)
      setTimestamp(formattedTimestamp)
      setTimestampHuman(humanFormattedTimestamp)
    }catch(err){
      setTimestamp("Invalid DateTime")
    }
  }

  function setCurrentTime() {
    const currentTime = DateTime.now().setZone('local').toString()
    document.getElementById("timestampField").setAttribute("value", currentTime)
    handleTimestamp(currentTime)
  }

  function analyzeOfo() {
    setStatus("Running")
    console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT)
    API.get(
        process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
        'beaker/read', {
          response: true,
          queryStringParameters: {
            stream_name: streamName,
            action: 'analyze_ofo',
            timestamp: timestamp
          }
        })
      .then(response => {
        console.log(response)
        const {ofo_analysis, raw_data} = response.data
        console.log(ofo_analysis)

        const {"issue recommendation":recommendation, ...other_keys} = ofo_analysis
        
        const [analysis, nextAction ] = recommendation.split(':')
        
        setAnalysis(analysis)
        setNextAction(nextAction)
        console.log(other_keys)
        setAdditionalInfo(other_keys)
        setVerbose(JSON.stringify(raw_data, null, 2))
        
        setStatus("Success")
      })
      .catch(error => {
        console.log(error)
        setStatus("Error")
        setErrorText(error.response.data)
      });
  }
  
  function generateCherubimDashboard() {
    setStatus("Running")
    console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT)
    API.get(
        process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
        'beaker/read', {
          response: true,
          queryStringParameters: {
            stream_name: streamName,
            action: 'cherubim_link',
            timestamp: timestamp
          }
        })
      
      .then(response => {
        console.log(response)
        console.log(response.data)
        window.open(response.data)
        setStatus("Success")
      })
        
      .catch(error => {
        console.log(error)
        setStatus("Error")
        setErrorText(error.response.data)
      });
  }
  
  function toTitleCase(str){
    
    const strippedStr = str.replace('_', ' ') 
    let strLowerCase = strippedStr.toLowerCase();
    let wordArr = strLowerCase.split(" ").map(function(currentValue) {
        return currentValue[0].toUpperCase() + currentValue.substring(1);
    });

    return wordArr.join(" ");
  }
  
  function handleStreamName(e){
    const val = e.target.value.trim()
    e.target.value = val
    setStreamName(val)
  }
  
  // function copyAdditionalInfo(e){
  //   console.log(additionalInfoRef.current)
  //   additionalInfoRef.current.select();
  //   document.execCommand('copy');
  //   e.target.focus();
  // }
  
  function copyRef(ref) {
    var range = document.createRange()
    var selection = window.getSelection()
    range.selectNode(ref.current)
    selection.removeAllRanges(); // Remove all ranges from the selection.
    selection.addRange(range); // Add the new range.
    document.execCommand('copy')
    selection.removeAllRanges() //Unselect everything
    setShowCopiedAlert(true)
  }
  
    return (
        <ColumnFlexPage style={{marginLeft:"30px", marginRight:"30px", marginTop:"10px"}}>

          <div name="Header" style={{display:"flex", justifyContent:"space-between", marginTop:rowSpacer}}>
            <h1>Pipeline Analysis</h1>
            <StatusBadge status={status}/>
          </div>
          <div name="Data Entry" style={{display:"grid", gridTemplateColumns: "repeat(3, 1fr) fit-content(20rem)", columnGap:"20px", marginTop:rowSpacer, marginBottom:"25px"}}>
              <StreamSelector onChange={(event) => handleStreamName(event)} placeholder="Stream Name"/>
              <sub style={{gridColumn:1, gridRow:2}}>i.e: azcinema-iad-1</sub>
              <StyledInput>
                  <input id="timestampField" onChange={(event) => handleTimestamp(event.target.value)} placeholder="Timestamp"/>
              </StyledInput>
              <sub style={{gridColumn:2, gridRow:2}}>i.e: 2021-03-29T06:35:00.000Z</sub>
              <div>
                {
                  (timestamp === "Invalid DateTime")?
                  <StatusBadge status="Error" size="x-small">Invalid Timestamp</StatusBadge>
                  :
                  <div>
                    <div>ISO 8601 (UTC): {timestamp}</div>
                    <div>Local: {timestampHuman}</div>
                  </div>
                }
              </div>
              <Button onClick={analyzeOfo} style={{width:"10rem", borderRadius:"10px", float:"right"}}>Analyze OFO</Button>
              <Button onClick={setCurrentTime} style={{width:"10rem", borderRadius:"10px", float:"right"}}>Set Time to Now</Button>
          </div>
          <div>
              <Button onClick={generateCherubimDashboard} style={{width:"10rem", borderRadius:"10px", float:"right"}}>Cherubim</Button>
          </div>
          <div name="Output" style={
            {display:"grid", 
            gridTemplateColumns:"20rem 1fr", 
            rowGap:"10px", 
            columnGap: "5px", 
            marginTop:rowSpacer}
          }>

              <Label>Issue Analysis</Label>
              <Label id="issue">{analysis}</Label>

              <Label>Next Action</Label>
              <Label>{nextAction}</Label>

              <Label ><span>Additional Information</span> <Button style={{float:"right"}} onClick={() => copyRef(additionalInfoRef)}>Copy</Button></Label>
              <Label ref={additionalInfoRef} style={{minHeight:"10rem"}} id="other">
	        {status !== "Error"?
                Object.keys(additionalInfo).map((category_key, top_index) => (
                  <div key={top_index}>
                  {/* The category of information, for example */}
                    <div>{toTitleCase(category_key)}</div> 
                    {/* Loop over every key in each category, and write a line for each*/}
                    { 
                      Object.keys(additionalInfo[category_key]).map((key, index) => (
                      <div key={top_index+index}>
                        <strong>{toTitleCase(key)}: </strong>{additionalInfo[category_key][key]}
                      </div>
                      )
                    )}
                  </div>
                )):
		<div> 
		  <pre> {errorText} </pre> 
                </div>
		}
              </Label>
              
              <Label ><span>Full Verbose Output</span> <Button onClick={() => copyRef(verboseRef)} style={{float:"right"}}>Copy</Button></Label>
              <Label ref={verboseRef} id="verbose" style={{cursor:"pointer"}} onClick={() => setShowVerbose(!showVerbose)}>
                {showVerbose ? 
                  <pre style={{overflowX:"scroll", paddingBottom:"20px"}}>{verbose}</pre>
                  :
                  <div>Click to show verbose</div>
                }
              </Label>
          </div>
          <Snackbar open={showCopiedAlert} autoHideDuration={1300} onClose={() => setShowCopiedAlert(false)}>
            <MuiAlert onClose={() => setShowCopiedAlert(false)} severity="success" elevation={6} variant="filled">
              Text Copied!
            </MuiAlert>
          </Snackbar>
        </ColumnFlexPage>
        
    );
    
}
