import React from 'react';
import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
//Components
import Bubble from '../Bubble';
//Custom Hooks
import { useAmplifyPost } from '../../hooks/useAmplifyApi';
import { handleError } from '../../utilities/galileoApi';

function InitiatingBubble({
    id,
    streamName,
    stagedFunction,
    executionRegion,
    regionFilters,
    subregionFilters,
    removeFromInitiating,
    pushToRunning,
    status,
    setStatus,
    children,
    ...props
  }) {
    const [error, setError] = useState();
    const [executionArn, executionLoading, arnFetchError] = useAmplifyPost(
        "step_functions/write",
        {
            action: "start_step_function",
            state_machine: stagedFunction,
            region: executionRegion,
            stream_name: streamName,
          }
      );
  
    const bubbleData = {
      name: streamName,
      execution_region: executionRegion || "us-west-2",
      staged_function: stagedFunction,
    };

    useEffect(() => {
      setStatus("Starting")
        if (executionArn){
            console.log("Response", executionArn);
            pushToRunning({ id: id, name: streamName, execution_arn: executionArn });
            removeFromInitiating(id);
        }
        else if(arnFetchError){
          setStatus("Error");
          let parsedError = handleError(arnFetchError) 
          setError(parsedError);
        }
    }, [executionArn, executionLoading, arnFetchError, error, status])
  
    return (
      <Bubble
        data={bubbleData}
        headerKeys={["name", "staged_function", "execution_region"]}
        status={status}
        enabled={true}
        deleteSelf={() => removeFromInitiating(id)}
      >
        {children}
        {error ? (
          <div>
            <h5>Error Starting Execution</h5>
            <RenderedError error={error}/>
          </div>
        ) : null}
      </Bubble>
    );
  }

  export default InitiatingBubble

  export function RenderedError({error}){
    const parsedError = handleError(error)
    return (
      <code>{parsedError}</code>
    )
  }