import { Container } from "@material-ui/core";
import React, { useContext } from "react";
import { FlexboxRows } from "../components/Layout";
import { AuthContext } from "../contexts/AuthProvider.js";
import { Auth } from "aws-amplify";

import { Button } from "../components/BasicControls";

function ProfilePage() {
  const { username, groups, ...userObject } = useContext(AuthContext);

  console.log("userObject", userObject);
  return (
    <Container>
      <FlexboxRows>
        <h1>Hello From the Profile Page</h1>
        <div>
          If you expect to have a role, but it's not showing up, log out and
          back in
          <Button style={{ marginLeft: "5px" }} onClick={() => Auth.signOut()}>
            Sign Out
          </Button>
        </div>
        <table>
          <tbody>
            <tr>
              <td>Username</td>
              <td>{username}</td>
            </tr>
            <tr>
              <td>Roles</td>
              <td>
                <ul>
                  {groups.map((groupName, index) => {
                    return <li key={index}>{groupName}</li>;
                  })}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </FlexboxRows>
    </Container>
  );
}

export default ProfilePage;
