import { GroupShowAll } from "@fluentui/react";
import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthProvider.js";

/*
{
  "username": "AWSFederate_muraokad",
  "pool": {
    "userPoolId": "us-west-2_JRGz3ZQzO",
    "clientId": "42dv8a6fe8mvqju3mt5pl9o3vh",
    "client": {
      "endpoint": "https://cognito-idp.us-west-2.amazonaws.com/",
      "fetchOptions": {}
    },
    "advancedSecurityDataCollectionFlag": true,
    "storage": {
      "CognitoIdentityServiceProvider.42dv8a6fe8mvqju3mt5pl9o3vh.AWSFederate_muraokad.refreshToken": "",
      "CognitoIdentityServiceProvider.42dv8a6fe8mvqju3mt5pl9o3vh.AWSFederate_muraokad.clockDrift": "2",
      "amplify-signin-with-hostedUI": "true",
      "CognitoIdentityServiceProvider.42dv8a6fe8mvqju3mt5pl9o3vh.AWSFederate_muraokad.accessToken": "eyJraWQiOiJTeDVyOEJkT2dJUHpCXC9zS1hlZUN2UVNCQjkzTmJcL0J1MzYxNUhGdlN0bk09IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJiYjVmYWQ2NS0yYzI2LTRjNTAtYWE1NS01OTA0YTc1YzdiZDMiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy13ZXN0LTJfSlJHejNaUXpPX0FXU0ZlZGVyYXRlIl0sInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4gcGhvbmUgb3BlbmlkIHByb2ZpbGUgZW1haWwiLCJhdXRoX3RpbWUiOjE2MTIyNDA3ODYsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy13ZXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtd2VzdC0yX0pSR3ozWlF6TyIsImV4cCI6MTYxMjMyNzE4NiwiaWF0IjoxNjEyMjQwNzg2LCJ2ZXJzaW9uIjoyLCJqdGkiOiIzNWY1NzA3Yy1jZjA1LTQ1MGUtYWUzZC1iYjVlY2I3YWU2YWEiLCJjbGllbnRfaWQiOiI0MmR2OGE2ZmU4bXZxanUzbXQ1cGw5bzN2aCIsInVzZXJuYW1lIjoiQVdTRmVkZXJhdGVfbXVyYW9rYWQifQ.bn2JaDGMhRrZCTVL-hnbOS3l3EijA_aIrcZcAvTyeT9TQQ1ckigw4BD9AziA95mzch2sul1BikM2aAkb-vWYvep-BA55bXW_UnPDMgAAlbBxfBhTvfrM-GK5f-nGvP4CAjbV6BLiP0ZJQgyvyOBF02N2U_hfh9SO7vRmXj2NT_5mDL8-DDsJMPuAW72hW1xBDRNY6TBJnQM9tQ096Qqp9JcK5MWFV4u-OYfyuaauukPlHEeb3oL7nXfsc4WYUlAt4PnvQhgjKUjF2k-UMnJwKQLsk_fhM-mJtN312HYi6C8ZrVx5mJu18gBQeQDvM_MDnJiBCjlCVdXncNiCl5v5Dg",
      "CognitoIdentityServiceProvider.42dv8a6fe8mvqju3mt5pl9o3vh.AWSFederate_muraokad.userData": "{\"UserAttributes\":[{\"Name\":\"sub\",\"Value\":\"bb5fad65-2c26-4c50-aa55-5904a75c7bd3\"},{\"Name\":\"identities\",\"Value\":\"[{\\\"userId\\\":\\\"muraokad\\\",\\\"providerName\\\":\\\"AWSFederate\\\",\\\"providerType\\\":\\\"OIDC\\\",\\\"issuer\\\":null,\\\"primary\\\":true,\\\"dateCreated\\\":1600842133683}]\"}],\"Username\":\"AWSFederate_muraokad\"}",
      "CognitoIdentityServiceProvider.42dv8a6fe8mvqju3mt5pl9o3vh.AWSFederate_muraokad.idToken": "eyJraWQiOiIrQVJPSklxR0kwYks0cGloXC82SHZLNUt6Y0h2RVZcLzB0bG5ZVnZtbmdFQVE9IiwiYWxnIjoiUlMyNTYifQ.eyJhdF9oYXNoIjoiQmotWk9vYTd3d1I1djNpUHAxT0QtUSIsInN1YiI6ImJiNWZhZDY1LTJjMjYtNGM1MC1hYTU1LTU5MDRhNzVjN2JkMyIsImF1ZCI6IjQyZHY4YTZmZThtdnFqdTNtdDVwbDlvM3ZoIiwiY29nbml0bzpncm91cHMiOlsidXMtd2VzdC0yX0pSR3ozWlF6T19BV1NGZWRlcmF0ZSJdLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJtdXJhb2thZCIsInByb3ZpZGVyTmFtZSI6IkFXU0ZlZGVyYXRlIiwicHJvdmlkZXJUeXBlIjoiT0lEQyIsImlzc3VlciI6bnVsbCwicHJpbWFyeSI6InRydWUiLCJkYXRlQ3JlYXRlZCI6IjE2MDA4NDIxMzM2ODMifV0sInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjEyMjQwNzg2LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtd2VzdC0yLmFtYXpvbmF3cy5jb21cL3VzLXdlc3QtMl9KUkd6M1pRek8iLCJjb2duaXRvOnVzZXJuYW1lIjoiQVdTRmVkZXJhdGVfbXVyYW9rYWQiLCJleHAiOjE2MTIzMjcxODYsImlhdCI6MTYxMjI0MDc4Niwibm9uY2UiOiI5a3Q2RWhwOURoekNXNmdEZHp4enMzQXIwYXJ4ODI2RDg4Y2NzX1V4aGtDSmVHUVVSeXcwanhLNlFUdklpak96T1BISXExOGNTWjdfUS10OWtQLWl6VUVKcG4wVWd4cGU5RHZiazkzbFVmSjNGWHd2RWk4aXhBakZHUzMyZ3pWeWxRemRwUG1xb2hxUklQaXpNUzAtaVBYbDl0b3hqZGJtWHZrMktLRXpNT28ifQ.gdIJ_WVuL2nJsAt0Cb8W1oqivh0H-gWqVlyF1TSvu4_KXVKR7vZx21P0kDUF8asIw-YOsr2Xr00xxXZAR6QkBUyYiNIC8f-eglWLuDoj5qU-2vdEa3BLjRgqexveFBIu84VUeunDtddwQjpTkO0YtuAdopolJH5_UGCzZQJwVGLAzVv8zoYzwj5IyK2e6s_uhXtsnhhDZWpeesqrHUtJylolgEpQpE1sEsRxASJgOkpMhUnD60GHDu2js32LDkGej4nB5GeWjva3lHTeOD6RNM7PfKVo8mYt9kajrJvyICHn7OuowFfMtBs--yow7O5_Xxg1BMXwat4XJNvG9mN3OA",
      "amplify-redirected-from-hosted-ui": "true",
      "CognitoIdentityServiceProvider.42dv8a6fe8mvqju3mt5pl9o3vh.LastAuthUser": "AWSFederate_muraokad"
    }
  },
  "Session": null,
  "client": {
    "endpoint": "https://cognito-idp.us-west-2.amazonaws.com/",
    "fetchOptions": {}
  },
  "signInUserSession": {
    "idToken": {
      "jwtToken": "eyJraWQiOiIrQVJPSklxR0kwYks0cGloXC82SHZLNUt6Y0h2RVZcLzB0bG5ZVnZtbmdFQVE9IiwiYWxnIjoiUlMyNTYifQ.eyJhdF9oYXNoIjoiQmotWk9vYTd3d1I1djNpUHAxT0QtUSIsInN1YiI6ImJiNWZhZDY1LTJjMjYtNGM1MC1hYTU1LTU5MDRhNzVjN2JkMyIsImF1ZCI6IjQyZHY4YTZmZThtdnFqdTNtdDVwbDlvM3ZoIiwiY29nbml0bzpncm91cHMiOlsidXMtd2VzdC0yX0pSR3ozWlF6T19BV1NGZWRlcmF0ZSJdLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJtdXJhb2thZCIsInByb3ZpZGVyTmFtZSI6IkFXU0ZlZGVyYXRlIiwicHJvdmlkZXJUeXBlIjoiT0lEQyIsImlzc3VlciI6bnVsbCwicHJpbWFyeSI6InRydWUiLCJkYXRlQ3JlYXRlZCI6IjE2MDA4NDIxMzM2ODMifV0sInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjEyMjQwNzg2LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtd2VzdC0yLmFtYXpvbmF3cy5jb21cL3VzLXdlc3QtMl9KUkd6M1pRek8iLCJjb2duaXRvOnVzZXJuYW1lIjoiQVdTRmVkZXJhdGVfbXVyYW9rYWQiLCJleHAiOjE2MTIzMjcxODYsImlhdCI6MTYxMjI0MDc4Niwibm9uY2UiOiI5a3Q2RWhwOURoekNXNmdEZHp4enMzQXIwYXJ4ODI2RDg4Y2NzX1V4aGtDSmVHUVVSeXcwanhLNlFUdklpak96T1BISXExOGNTWjdfUS10OWtQLWl6VUVKcG4wVWd4cGU5RHZiazkzbFVmSjNGWHd2RWk4aXhBakZHUzMyZ3pWeWxRemRwUG1xb2hxUklQaXpNUzAtaVBYbDl0b3hqZGJtWHZrMktLRXpNT28ifQ.gdIJ_WVuL2nJsAt0Cb8W1oqivh0H-gWqVlyF1TSvu4_KXVKR7vZx21P0kDUF8asIw-YOsr2Xr00xxXZAR6QkBUyYiNIC8f-eglWLuDoj5qU-2vdEa3BLjRgqexveFBIu84VUeunDtddwQjpTkO0YtuAdopolJH5_UGCzZQJwVGLAzVv8zoYzwj5IyK2e6s_uhXtsnhhDZWpeesqrHUtJylolgEpQpE1sEsRxASJgOkpMhUnD60GHDu2js32LDkGej4nB5GeWjva3lHTeOD6RNM7PfKVo8mYt9kajrJvyICHn7OuowFfMtBs--yow7O5_Xxg1BMXwat4XJNvG9mN3OA",
      "payload": {
        "at_hash": "Bj-ZOoa7wwR5v3iPp1OD-Q",
        "sub": "bb5fad65-2c26-4c50-aa55-5904a75c7bd3",
        "aud": "42dv8a6fe8mvqju3mt5pl9o3vh",
        "cognito:groups": [
          "us-west-2_JRGz3ZQzO_AWSFederate"
        ],
        "identities": [{
          "userId": "muraokad",
          "providerName": "AWSFederate",
          "providerType": "OIDC",
          "issuer": null,
          "primary": "true",
          "dateCreated": "1600842133683"
        }],
        "token_use": "id",
        "auth_time": 1612240786,
        "iss": "https://cognito-idp.us-west-2.amazonaws.com/us-west-2_JRGz3ZQzO",
        "cognito:username": "AWSFederate_muraokad",
        "exp": 1612327186,
        "iat": 1612240786,
        "nonce": "9kt6Ehp9DhzCW6gDdzxzs3Ar0arx826D88ccs_UxhkCJeGQURyw0jxK6QTvIijOzOPHIq18cSZ7_Q-t9kP-izUEJpn0Ugxpe9Dvbk93lUfJ3FXwvEi8ixAjFGS32gzVylQzdpPmqohqRIPizMS0-iPXl9toxjdbmXvk2KKEzMOo"
      }
    },
    "refreshToken": {
      "token": ""
    },
    "accessToken": {
      "jwtToken": "eyJraWQiOiJTeDVyOEJkT2dJUHpCXC9zS1hlZUN2UVNCQjkzTmJcL0J1MzYxNUhGdlN0bk09IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJiYjVmYWQ2NS0yYzI2LTRjNTAtYWE1NS01OTA0YTc1YzdiZDMiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy13ZXN0LTJfSlJHejNaUXpPX0FXU0ZlZGVyYXRlIl0sInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4gcGhvbmUgb3BlbmlkIHByb2ZpbGUgZW1haWwiLCJhdXRoX3RpbWUiOjE2MTIyNDA3ODYsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy13ZXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtd2VzdC0yX0pSR3ozWlF6TyIsImV4cCI6MTYxMjMyNzE4NiwiaWF0IjoxNjEyMjQwNzg2LCJ2ZXJzaW9uIjoyLCJqdGkiOiIzNWY1NzA3Yy1jZjA1LTQ1MGUtYWUzZC1iYjVlY2I3YWU2YWEiLCJjbGllbnRfaWQiOiI0MmR2OGE2ZmU4bXZxanUzbXQ1cGw5bzN2aCIsInVzZXJuYW1lIjoiQVdTRmVkZXJhdGVfbXVyYW9rYWQifQ.bn2JaDGMhRrZCTVL-hnbOS3l3EijA_aIrcZcAvTyeT9TQQ1ckigw4BD9AziA95mzch2sul1BikM2aAkb-vWYvep-BA55bXW_UnPDMgAAlbBxfBhTvfrM-GK5f-nGvP4CAjbV6BLiP0ZJQgyvyOBF02N2U_hfh9SO7vRmXj2NT_5mDL8-DDsJMPuAW72hW1xBDRNY6TBJnQM9tQ096Qqp9JcK5MWFV4u-OYfyuaauukPlHEeb3oL7nXfsc4WYUlAt4PnvQhgjKUjF2k-UMnJwKQLsk_fhM-mJtN312HYi6C8ZrVx5mJu18gBQeQDvM_MDnJiBCjlCVdXncNiCl5v5Dg",
      "payload": {
        "sub": "bb5fad65-2c26-4c50-aa55-5904a75c7bd3",
        "cognito:groups": [
          "us-west-2_JRGz3ZQzO_AWSFederate"
        ],
        "token_use": "access",
        "scope": "aws.cognito.signin.user.admin phone openid profile email",
        "auth_time": 1612240786,
        "iss": "https://cognito-idp.us-west-2.amazonaws.com/us-west-2_JRGz3ZQzO",
        "exp": 1612327186,
        "iat": 1612240786,
        "version": 2,
        "jti": "35f5707c-cf05-450e-ae3d-bb5ecb7ae6aa",
        "client_id": "42dv8a6fe8mvqju3mt5pl9o3vh",
        "username": "AWSFederate_muraokad"
      }
    },
    "clockDrift": 2
  },
  "authenticationFlowType": "USER_SRP_AUTH",
  "storage": {
    "CognitoIdentityServiceProvider.42dv8a6fe8mvqju3mt5pl9o3vh.AWSFederate_muraokad.refreshToken": "",
    "CognitoIdentityServiceProvider.42dv8a6fe8mvqju3mt5pl9o3vh.AWSFederate_muraokad.clockDrift": "2",
    "amplify-signin-with-hostedUI": "true",
    "CognitoIdentityServiceProvider.42dv8a6fe8mvqju3mt5pl9o3vh.AWSFederate_muraokad.accessToken": "eyJraWQiOiJTeDVyOEJkT2dJUHpCXC9zS1hlZUN2UVNCQjkzTmJcL0J1MzYxNUhGdlN0bk09IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJiYjVmYWQ2NS0yYzI2LTRjNTAtYWE1NS01OTA0YTc1YzdiZDMiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy13ZXN0LTJfSlJHejNaUXpPX0FXU0ZlZGVyYXRlIl0sInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4gcGhvbmUgb3BlbmlkIHByb2ZpbGUgZW1haWwiLCJhdXRoX3RpbWUiOjE2MTIyNDA3ODYsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy13ZXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtd2VzdC0yX0pSR3ozWlF6TyIsImV4cCI6MTYxMjMyNzE4NiwiaWF0IjoxNjEyMjQwNzg2LCJ2ZXJzaW9uIjoyLCJqdGkiOiIzNWY1NzA3Yy1jZjA1LTQ1MGUtYWUzZC1iYjVlY2I3YWU2YWEiLCJjbGllbnRfaWQiOiI0MmR2OGE2ZmU4bXZxanUzbXQ1cGw5bzN2aCIsInVzZXJuYW1lIjoiQVdTRmVkZXJhdGVfbXVyYW9rYWQifQ.bn2JaDGMhRrZCTVL-hnbOS3l3EijA_aIrcZcAvTyeT9TQQ1ckigw4BD9AziA95mzch2sul1BikM2aAkb-vWYvep-BA55bXW_UnPDMgAAlbBxfBhTvfrM-GK5f-nGvP4CAjbV6BLiP0ZJQgyvyOBF02N2U_hfh9SO7vRmXj2NT_5mDL8-DDsJMPuAW72hW1xBDRNY6TBJnQM9tQ096Qqp9JcK5MWFV4u-OYfyuaauukPlHEeb3oL7nXfsc4WYUlAt4PnvQhgjKUjF2k-UMnJwKQLsk_fhM-mJtN312HYi6C8ZrVx5mJu18gBQeQDvM_MDnJiBCjlCVdXncNiCl5v5Dg",
    "CognitoIdentityServiceProvider.42dv8a6fe8mvqju3mt5pl9o3vh.AWSFederate_muraokad.userData": "{\"UserAttributes\":[{\"Name\":\"sub\",\"Value\":\"bb5fad65-2c26-4c50-aa55-5904a75c7bd3\"},{\"Name\":\"identities\",\"Value\":\"[{\\\"userId\\\":\\\"muraokad\\\",\\\"providerName\\\":\\\"AWSFederate\\\",\\\"providerType\\\":\\\"OIDC\\\",\\\"issuer\\\":null,\\\"primary\\\":true,\\\"dateCreated\\\":1600842133683}]\"}],\"Username\":\"AWSFederate_muraokad\"}",
    "CognitoIdentityServiceProvider.42dv8a6fe8mvqju3mt5pl9o3vh.AWSFederate_muraokad.idToken": "eyJraWQiOiIrQVJPSklxR0kwYks0cGloXC82SHZLNUt6Y0h2RVZcLzB0bG5ZVnZtbmdFQVE9IiwiYWxnIjoiUlMyNTYifQ.eyJhdF9oYXNoIjoiQmotWk9vYTd3d1I1djNpUHAxT0QtUSIsInN1YiI6ImJiNWZhZDY1LTJjMjYtNGM1MC1hYTU1LTU5MDRhNzVjN2JkMyIsImF1ZCI6IjQyZHY4YTZmZThtdnFqdTNtdDVwbDlvM3ZoIiwiY29nbml0bzpncm91cHMiOlsidXMtd2VzdC0yX0pSR3ozWlF6T19BV1NGZWRlcmF0ZSJdLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJtdXJhb2thZCIsInByb3ZpZGVyTmFtZSI6IkFXU0ZlZGVyYXRlIiwicHJvdmlkZXJUeXBlIjoiT0lEQyIsImlzc3VlciI6bnVsbCwicHJpbWFyeSI6InRydWUiLCJkYXRlQ3JlYXRlZCI6IjE2MDA4NDIxMzM2ODMifV0sInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjEyMjQwNzg2LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtd2VzdC0yLmFtYXpvbmF3cy5jb21cL3VzLXdlc3QtMl9KUkd6M1pRek8iLCJjb2duaXRvOnVzZXJuYW1lIjoiQVdTRmVkZXJhdGVfbXVyYW9rYWQiLCJleHAiOjE2MTIzMjcxODYsImlhdCI6MTYxMjI0MDc4Niwibm9uY2UiOiI5a3Q2RWhwOURoekNXNmdEZHp4enMzQXIwYXJ4ODI2RDg4Y2NzX1V4aGtDSmVHUVVSeXcwanhLNlFUdklpak96T1BISXExOGNTWjdfUS10OWtQLWl6VUVKcG4wVWd4cGU5RHZiazkzbFVmSjNGWHd2RWk4aXhBakZHUzMyZ3pWeWxRemRwUG1xb2hxUklQaXpNUzAtaVBYbDl0b3hqZGJtWHZrMktLRXpNT28ifQ.gdIJ_WVuL2nJsAt0Cb8W1oqivh0H-gWqVlyF1TSvu4_KXVKR7vZx21P0kDUF8asIw-YOsr2Xr00xxXZAR6QkBUyYiNIC8f-eglWLuDoj5qU-2vdEa3BLjRgqexveFBIu84VUeunDtddwQjpTkO0YtuAdopolJH5_UGCzZQJwVGLAzVv8zoYzwj5IyK2e6s_uhXtsnhhDZWpeesqrHUtJylolgEpQpE1sEsRxASJgOkpMhUnD60GHDu2js32LDkGej4nB5GeWjva3lHTeOD6RNM7PfKVo8mYt9kajrJvyICHn7OuowFfMtBs--yow7O5_Xxg1BMXwat4XJNvG9mN3OA",
    "amplify-redirected-from-hosted-ui": "true",
    "CognitoIdentityServiceProvider.42dv8a6fe8mvqju3mt5pl9o3vh.LastAuthUser": "AWSFederate_muraokad"
  },
  "keyPrefix": "CognitoIdentityServiceProvider.42dv8a6fe8mvqju3mt5pl9o3vh",
  "userDataKey": "CognitoIdentityServiceProvider.42dv8a6fe8mvqju3mt5pl9o3vh.AWSFederate_muraokad.userData",
  "attributes": {
    "sub": "bb5fad65-2c26-4c50-aa55-5904a75c7bd3",
    "identities": "[{\"userId\":\"muraokad\",\"providerName\":\"AWSFederate\",\"providerType\":\"OIDC\",\"issuer\":null,\"primary\":true,\"dateCreated\":1600842133683}]"
  },
  "preferredMFA": "NOMFA"
}
*/

export function MemberRestrictedSection(props) {
  const { username, groups, ...userObject } = useContext(AuthContext);

  console.log(
    "I am the RBAC system",
    `You are ${username}`,
    groups,
    userObject
  );

  const includeUsernames = props.includeUsernames || [];
  const excludeUsernames = props.excludeUsernames || [];
  const includeGroups = props.includeGroups || [];
  const excludeGroups = props.excludeGroups || [];

  const userGroupsIncluded = includeGroups.filter((value) =>
    groups.includes(value)
  );
  const userGroupsExcluded = excludeGroups.filter((value) =>
    groups.includes(value)
  );

  console.log(
    "Allow List",
    includeUsernames,
    excludeUsernames,
    includeGroups,
    excludeGroups
  );

  console.log("Group intersections", userGroupsIncluded, userGroupsExcluded);

  //Deny By default
  let isAllowed = false;

  //Allow explicitly allowed users and groups
  if (includeUsernames.includes(username)) {
    isAllowed = true;
  } else if (userGroupsIncluded.length > 0) {
    isAllowed = true;
  }

  //Deny explicitly denied users and groups
  //If a user is both denied and allowed, denial takes precedence
  if (excludeUsernames.includes(username)) {
    console.log("Explicitly denied by username!");
    isAllowed = false;
  } else if (userGroupsExcluded.length > 0) {
    console.log("Explicitly denied by group!");
    isAllowed = false;
  }

  if (isAllowed) {
    return props.children;
  } else {
    console.log("You are not worthy!");
    return null;
  }
}
