  import React, {useContext, useState, useEffect} from "react";
  import { makeStyles } from "@material-ui/core/styles";
  import CircularProgress from "@material-ui/core/CircularProgress";
  import Paper from "@material-ui/core/Paper";
  import Tabs from "@material-ui/core/Tabs";
  import Tab from "@material-ui/core/Tab";
  import { API } from 'aws-amplify';
  
  import Slate from './Slate';
  import  HWTable from "../components/HwConfigTable";
  import { HwContext } from "../contexts/HwContextProvider";

  import styles from "../styles.module.css";

  const tableHeaders = {
    // 'id': 'ID',
    'dc_name' : 'DC Name',
    'device_label' : 'Device Label',
    'name' : 'Name',
    'hostname' : 'Host Name',
    'route53' : 'Route53',
    'device_type' : 'Device Type',
    'os' : 'OS',
    'software_version' : 'Software Version',
    'tenant': 'Tenant', 
    // 'ipmi_idrac' : 'IPMI / IDRAC IP',
    'jobs' : 'Jobs',
    'sdi' : 'SDI',
    'serial_num' : 'Serial Number',
    'rack_elevation' : 'Rack Elevation',
    'rack_num'  : 'Rack Number',
    'model_number': 'Model Number',
    'network.eth0.ip' : 'Network eth0 ip',
    'network.eth0.purpose' : 'Network eth0 purpose',
    'network.eth1.ip' : 'Network eth1 ip',
    'network.eth1.purpose' : 'Network eth1 purpose',
    'network.eth2.ip' : 'Network eth2 ip',
    'network.eth2.purpose' : 'Network eth2 purpose',
    'network.eth3.ip' : 'Network eth3 ip',
    'network.eth3.purpose' : 'Network eth3 purpose',
    'network.eth4.ip' : 'network eth4 ip',
    'network.eth4.purpose' : 'Network eth4 purpose',
    'network.eth5.ip' : 'Network eth5 ip',
    'network.eth5.purpose': 'Network eth5 purpose',
    'network.eth6.ip' : 'Network eth6 ip',
    'network.eth6.purpose': 'Network eth6 purpose',
    'network.eth7.ip' : 'Network eth7 ip',
    'network.eth7.purpose': 'Network eth7 purpose',
    'network.ens5f0np0.ip' : 'Network ens5f0np0 ip',
    'network.ens5f0np0.purpose': 'Network ens5f0np0 purpose', 
    'network.ens5f1np1.ip' : 'Network ens5f1np1 ip',
    'network.ens5f1np1.purpose': 'Network ens5f1np1 purpose',
    'network.ens5f2np2.ip' : 'Network ens5f2np2 ip',
    'network.ens5f2np2.purpose': 'Network ens5f2np2 purpose',
    'network.ens5f3np3.ip' : 'Network ens5f3np3 ip',
    'network.ens5f3np3.purpose': 'Network ens5f3np3 purpose',
    'network.ens5f0.ip' : 'Network ens5f0 ip',
    'network.ens5f0.purpose': 'Network ens5f0 purpose',
    'network.ens5f1.ip' : 'Network ens5f1 ip',
    'network.ens5f1.purpose': 'Network ens5f1 purpose',
    'network.ipmi_idrac.ip' : 'Network ipmi_idrac ip',
    'network.ipmi_idrac.credential_name' : 'network ipmi_idrac credential_name',
    'network.ipmi_idrac.credential_location' : 'network ipmi_idrac credential_location',
    'power' : 'Power',
    '_created': '_created',
    '_user': '_user',
    '_version' : '_version',
    'comment' : 'Comment',
  }
  
  const dcNames = {
    'MX1SES_HAW' : 'mx1-haw',
    'EDM_ATL' : 'edm-atl',
    'EDM_STA' : 'edm-sta',
    'HAL_NEW' : 'halsey',
    'SOFTBANK_TKY': 'jp-nrt',
    'EDM_LHR' : 'edm-london',
    'IMG_LHR' : 'img-london',
    'SWITCH_LAX' : 'switch-lax',
    'NBC_EWC' : 'nbc-ewc',
    'MISC' : 'misc'
  }
  
  /** 
   * Flatten nested object for given object
   *  @param {string} obj - Nested object that needs to be flattened
  */
  const flattenDbObject = (obj) => {
    const toReturn = {};
    
    for (const i in obj) {
      if (!obj.hasOwnProperty(i)) continue;
      
      if ((typeof obj[i]) == 'object') {
        const flatObject = flattenDbObject(obj[i]);
        for (const x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;
          
          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = obj[i];
      }
    }
    return toReturn;
  };
  
  /** 
   * Tab panel component
   *  @param {string} props - includes row, columns, unique index
  */
  function TabPanel(props) {
    const { children, value, index, rows, columns, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className={styles.table}>
            <HWTable
              rows={rows}
              columns={columns}
            />
          </div>
        )}
      </div>
    );
  }
  
  /** 
   * Tabs props feeder
   *  @param {string} index - unique index for each tab
  */
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }
  
  /** 
   * Styling construct / wrapper used by MUI components
   *  @param {string} theme - unique index for each tab
  */
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    }
  }));

  /** 
   * Sort object elements based on key
   *  @param {string} first - first element to be sorted
   *  @param {string} second - second element to be sorted
   *  @param {string} key - key  with which element are to be sorted
  */
  const sortByKey = (first, second, key) => {
    if (first[key] < second[key]) return -1;
    if (first[key] > second[key]) return 1;
    return 0
  }

  /** 
   * Hardware config DB page within Dante
   *  @param {string} props - nothing passed at this time
  */
  export default function HwConfigSelector(props) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [dbData, setDbData] = useState([]);

    const [ hwState, hwDispatch] = useContext(HwContext);
    
    useEffect(() => {
      const path = "hw_config/view/allConfig";
      API.get(
        process.env.REACT_APP_API_ENVIRONMENT, 
        path, {
          headers: {
            'Cache-Control': 'no-cache',
            'Content-type': 'application/json',
          },
          queryStringParameters: {
            action : "view_all_configs",
            config_type : "hwconfig",
          }
      })
      .then(response => {
        // Flatten nested response
        let rowData = [];
        for (const [key, obj] of Object.entries(response)){ 
          rowData.push(flattenDbObject(obj));
        }
        rowData.sort( (a,b) => sortByKey(a,b, 'name'));
        setDbData(rowData);
        hwDispatch({ type: 'SET_DB_DATA_RECEIVED', dbDataReceived: true });
      })
      .catch(error => {
        console.log(error)
        console.log(error.response);
      });
    }, []);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    /** 
     * Check for duplicate rows and returns Boolean on match
     *  @param {string} dbData -  DB data state
     *  @param {string} newRowDataName - row to be added
    */
     const validateRowData = ( key, newRowData )=> {
      if (key === 'name'){
        if (newRowData.name === undefined || newRowData.name === ''){
          return { isValid: false, helperText: `Name can not be blank` };
        }
      }
      if (key === 'dc_name'){
        if (newRowData.dc_name === undefined || newRowData.dc_name === ''){
          return { isValid: false, helperText: `DC Name can not be blank` };
        }
      }
      return { isValid: true};
   }

    const columnData =  []; 
    for (const [ key, value] of Object.entries(tableHeaders)){ 
      columnData.push({
        validate: rowData => validateRowData(key, rowData),
        field: key,
        title: value,
        editable: (key === '_user' || key === '_version' || key === '_created') ? 'never' : 'always',
        cellStyle: {
          whiteSpace: 'nowrap',
          padding: '10px'
        }
      })
    }
    
    return  (
      <React.Fragment>
        {hwState.dbDataReceived ? 
          <div className={classes.root}>
            <Paper>
              <Tabs
                variant="standard"
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
              >
                {
                  Object.values(dcNames).map((tab, key) => {
                    return <Tab label={tab} {...a11yProps(key)} key={key} />;
                  })
                }
              </Tabs>
            </Paper>
            {
              Object.keys(dcNames).map((location, key) => { 
                return (
                  location === 'MISC' ?  
                    <TabPanel
                      value={value}
                      index={key}
                      key={key}
                      columns={columnData}
                      rows={dbData.filter( item => !(item.dc_name in dcNames) )}
                    /> : 
                    <TabPanel
                      value={value}
                      index={key}
                      key={key}
                      columns={columnData}
                      rows={dbData.filter( item => item.dc_name === location)}
                    />
                )
              })
            }
          </div>
        : 
          <Slate>
            <CircularProgress size={48} className={classes.buttonProgress} color="secondary" />
            <h2>Loading DB Data...</h2>
          </Slate>
        }
      </React.Fragment>
    );
  }