import React from 'react';
import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
//Components
import Bubble from '../Bubble';
//Custom Hooks
import { useDidMountEffect } from '../../hooks/useDidMountEffect';

function StagedBubble({
    id,
    streamName,
    stagedFunction,
    executionRegion,
    regionFilters,
    subregionFilters,
    removeFromStaged,
    pushToRunning,
    status,
    setStatus,
    children,
    ...props
  }) {
    const [feedName, region, subregion] = streamName.split("-");
    const [error, setError] = useState();
  
    const bubbleData = {
      name: streamName,
      execution_region: executionRegion || "us-west-2",
      staged_function: stagedFunction,
    };
  
    useEffect(() => {
      // If there are no filters, all regions are enabled
      const regionDisabled =
        regionFilters.value.length > 0 && !regionFilters.value.includes(region);
      const subregionDisabled =
        subregionFilters.value.length > 0 && !subregionFilters.value.includes(subregion);
  
      // Bubbles that are "filtered" out, are disabled
      // Bubble is enabled if both other conditions are false
      const bubbleIsDisabled = regionDisabled || subregionDisabled;
      console.log(
        "Checking filters for",
        id,
        streamName,
        `regionDisabled:${regionDisabled}`,
        `subregionDisabled:${subregionDisabled}`,
        `Disabled:${bubbleIsDisabled}`,
      );
      setStatus(bubbleIsDisabled ? "Disabled" : "Staged"); // Set status based on filters
    }, [regionFilters, subregionFilters]);
  
    return (
      <Bubble
        data={bubbleData}
        headerKeys={["name", "staged_function", "execution_region"]}
        status={status}
        enabled={true}
        deleteSelf={() => removeFromStaged(id)}
      >
        {children}
        {error ? (
          <div>
            <h5>Error Starting Execution</h5>
            <code>{error}</code>
          </div>
        ) : null}
      </Bubble>
    );
  }

  export default StagedBubble