export const awsconfig = {
  "Auth": {
    "region": "us-west-2",
    "userPoolId": "us-west-2_JRGz3ZQzO",
    "userPoolWebClientId": "42dv8a6fe8mvqju3mt5pl9o3vh",
    "identityPoolId": "us-west-2:bbc01df9-f8f5-4f0a-9802-dec276bd1180",
    "mandatorySignIn": false
  },
  "API": {
    "endpoints": [{
        "name": "Gamma",
        "endpoint": "https://gamma.api.amazonavoc.com/",
        "region": "us-west-2"
      },
      {
        "name": "Prod",
        "endpoint": "https://prod.api.amazonavoc.com/",
        "region": "us-west-2"
      }
    ]
  }
}

// AWS configuation for gamma branch
export const awsconfigGamma = {
  "Auth": {
    "region": "us-west-2",
    "userPoolId": "us-west-2_JRGz3ZQzO",
    "userPoolWebClientId": "6lju0rreabjut45ia1p3bijohu",
    "identityPoolId": "us-west-2:bbc01df9-f8f5-4f0a-9802-dec276bd1180",
    "mandatorySignIn": false
  },
  "API": {
    "endpoints": [{
        "name": "Gamma",
        "endpoint": "https://gamma.api.amazonavoc.com/",
        "region": "us-west-2"
      }
    ]
  }
}
