import React from 'react';
import styled from 'styled-components'

//Componenents
import { Red, Orange, Yellow, Green, Blue, Purple, LightGray } from './ColorTheme'


const StyledBadge = styled.div `
  padding: 0 2em;
  border-radius: 50px;
  background-color: ${props =>props.color};
  color: white;
  margin: 5px 0 5px 0;
  font-size: ${props => props.size || "inherit" };
  text-align: center;
`

export function StatusBadge(props) {

    let statusColor
    switch (props.status) {
        case "Success":
            statusColor = Green
            break;
        case "Error":
            statusColor = Red
            break;
        case "Running":
            statusColor = Yellow
            break;
        case "Ready":
            statusColor = Blue
            break;
        case "DRAFT":
            statusColor = Yellow
            break;
        case "UNDER_REVIEW":
            statusColor = Blue
            break;
        case "APPROVED":
            statusColor = Green
            break;
        case "MERGED":
            statusColor = Green
            break;
        case "DELETED":
            statusColor = Red
            break;
        case "CREATED GROUP":
            statusColor = Green
            break;
        case "IN_PROGRESS":
            statusColor = Orange
            break;
        case "FAILED":
            statusColor = Red
            break;
        default:
            statusColor = LightGray
    }

    return (
        <StyledBadge size={props.size} color={props.color || statusColor}>
            {
                props.children != undefined?
                    props.children
                :
                    <h1>{props.status}</h1>
            }
            
        </StyledBadge>
    )
}
