//Accent Colors
export const Red = '#ff0000'
export const Orange = '#ff6600'
export const Yellow = '#ffff00'
export const Green = '#00ff00'
export const Blue = '#3399ff'
export const Purple = '#cc00ff'

//Interface Colors
export const LightGray = '#b3b3b3'
