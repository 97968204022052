import React, { useState, useContext, useEffect} from 'react';
import { useArray } from "react-hanger";
import styled from 'styled-components';
import Select from 'react-select';

import { API, Auth } from 'aws-amplify';
import { AuthContext } from '../contexts/AuthProvider.js';

import { Button } from '../components/BasicControls';
import { Sidebar, SidebarGroup } from '../components/Sidebar';
import { ContentWithSidebarPage } from '../components/Page';
import { FlexboxRows } from "../components/Layout";
import { Accordion } from "../components/Accordion";

import OnboardStagedBubble from "../components/onboardStreams_bubbles/OnboardStagedBubble";
import InitiatingBubble from "../components/gilgamesh_bubbles/InitiatingBubble";
import PollingBubble from "../components/gilgamesh_bubbles/PollingBubble";
import EndStateBubble from "../components/gilgamesh_bubbles/EndStateBubble";

const StyledSelect = styled(Select)
`
    flex-grow: 1;
    font-size: 1rem;
`;


function OnboardStreams(){
    const [streams, setStreams] = useState([{ name: '', command: '', status: 'Ready', data: "", active: true }]);
    // const [executions, setExecutions] = useState([{ name: '', command: 'Step Function execution status', status: 'Ready', data: "", active: true }])
    const {user} = useContext(AuthContext);
    let userName = JSON.parse(user.attributes.identities)[0].userId;
    
    const stepFunctionOptions = [
    { value: 'create_nexium_stream', label: "Create Nexium Stream"},
    { value: 'create_stream_instances_only', label: 'Deploy Zixi Feeds'},
    { value: 'create_appeartv_channel', label: "Deploy Appeartv Feeds"},
    { value: 'delete_stream', label: "Delete Stream"},
    { value: 'reconfigure_live', label: "Reconfigure Live"}
  ];
  
    const [stream_names, setStream_names] = useState([]);
    const [selectedStreams, setSelectedStreams] = useState([]);
    const stagedForExecution = useArray([]);
    const [stagedFunction, setStagedFunction] = useState("");
    const executionsAwaitingArn = useArray([]);
    const [executionRegion, setExecutionRegion] = useState("us-west-2");
    const filteredRegions = useArray([]);
    const filteredSubregions = useArray([]);
    const runningExecutions = useArray([]);
    const succeededExecutions = useArray([]);
    const failedExecutions = useArray([]);
    console.log(filteredRegions, filteredSubregions);
    console.log(stagedForExecution);
    useEffect(() => {
       getList();
    }, []);
    
    useEffect(() =>{
      let executions;
      for (executions in succeededExecutions.value){
        // Checks for all the succeeded executions and updates the status as "deployed" for them
        console.log("succeeded",succeededExecutions.value[executions].name);
        updateStatus(succeededExecutions.value[executions].name);
      }
    }, [succeededExecutions]);
    
    // get list of streams pending deployment
    function getList(){
    let path = "redactor/read";
        console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
        API.get(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            headers: {
              'Cache-Control': 'max-age=87000',
              'Content-type': 'application/json',
            },
            queryStringParameters: {
              action: "get_streamList"
            }
        })
        .then(response => {
          console.log(response);
          setStream_names(response);
          console.log(stream_names);
        })
        .catch(error => {
          console.log(error.response);
        });
    }
    
    function handleOnChange(e){
      console.log(e);
      console.log(stagedForExecution);
      // Will check if the stream is already selected, if yes then remove it
      let position = selectedStreams.indexOf(e);
      console.log(position);
      if (position > -1) { 
        setSelectedStreams(selectedStreams.filter((item)=> item !== e));
        stagedForExecution.removeById(e);
      }
      // otherwise add it to selected streams
      else {
        let bubble_created = createBubble({"id":e, "name": e, "status":"Staged"});
        stagedForExecution.push(bubble_created);
        selectedStreams.push(e);
      }
      console.log(selectedStreams);
    }

    function createBubble(payload) {
      //Create Object
      const newStream = {
        ...payload,
      };
      return newStream;
    }

    function runStagedFunctions() {
      //Get bubbles in staged status
      const stagedExecutions = stagedForExecution.value.filter(
        (item) => item.status === "Staged"
      );
  
      // Map over all bubbles in staged status
      stagedExecutions.map((item) => {
        //Push those to initiating status
        executionsAwaitingArn.push(item);
        //Remove staged executions from staged
        stagedForExecution.removeById(item.id);
      });
    }
  
  function updateStatus(streamName){
    API.post(
        process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
        'redactor/write', {
          response: true,
            body: {
                action: "update_status", 
                stream_name: streamName
            }
        })
        .then(response => {
            console.log('Response', response)
        })
        .catch(error => {
            console.log(error.response);
        });  
    }
    
    return(
        <ContentWithSidebarPage>
          <Sidebar>
           <SidebarGroup name="Action">
             <div style = {{width: "230px"}}>
              <StyledSelect 
              options={stepFunctionOptions}
              onChange={({ value, label }) => setStagedFunction(value)}
              defaultValue={{ value: "", label: "Select a step function" }}/>
             </div>
            {stagedFunction ? (
            <Button onClick={runStagedFunctions}>
              Run{" "}
              {stagedForExecution.value.filter((item) => item.status === "Staged")
                .length || null}{" "}
              Executions
            </Button>
            ) : (
            <Button disabled={true}> Please Select a Step Function </Button>
            )}
            </SidebarGroup>
            <SidebarGroup name="List of Streams">
              {stream_names.map((name, index) => (
                  <div>
                   <label htmlFor="stream" style={{marginRight: "10px","fontSize":"small"}}>{name}</label>
                    <input
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      name="stream"
                      value={name}
                      onChange = {() => handleOnChange(name)}
                    />
                  </div>
                )
              )}
           </SidebarGroup>
          </Sidebar>
        <FlexboxRows>
        <Accordion title="Staged">
          {stagedForExecution.value.map(({ name, id, status }) => (
            <OnboardStagedBubble
              key={id}
              id={id}
              streamName={name}
              status={status}
              executionRegion={executionRegion}
              stagedFunction={stagedFunction}
              regionFilters={filteredRegions}
              subregionFilters={filteredSubregions}
              pushToRunning={executionsAwaitingArn.push}
              removeFromStaged={stagedForExecution.removeById}
              setStatus={(newStatus) =>
                stagedForExecution.modifyById(id, { status: newStatus })
              }
            />
          ))}
          {executionsAwaitingArn.value.map(({ name, id, status }) => (
            <InitiatingBubble
              key={id}
              id={id}
              streamName={name}
              status={status}
              executionRegion={executionRegion}
              stagedFunction={stagedFunction}
              regionFilters={filteredRegions}
              subregionFilters={filteredSubregions}
              pushToRunning={runningExecutions.push}
              removeFromInitiating={executionsAwaitingArn.removeById}
              setStatus={(newStatus) =>
                executionsAwaitingArn.modifyById(id, { status: newStatus })
              }
            />
          ))}
        </Accordion>
        <Accordion title="Running">
          {runningExecutions.value.map(
            ({ name, id, execution_arn, status }) => (
              <PollingBubble
                key={id}
                id={id}
                streamName={name}
                execution_arn={execution_arn}
                status={status}
                setStatus={(newStatus) =>
                  runningExecutions.modifyById(id, { status: newStatus })
                }
                removeFromRunning={runningExecutions.removeById}
                pushToComplete={succeededExecutions.push}
                pushToFailed={failedExecutions.push}
              />
            )
          )}
        </Accordion>
        <Accordion title="Failed"></Accordion>
        {failedExecutions.value.map(({ name, id, execution_arn, status }) => (
          <EndStateBubble
            key={id}
            id={id}
            streamName={name}
            execution_arn={execution_arn}
            status={status}
            removeSelf={() => failedExecutions.removeById(id)}
            pushToStaged={stagedForExecution.push}
          />
        ))}

        <Accordion title="Complete">
          {succeededExecutions.value.map(
            ({ name, id, execution_arn, status }) => (
              <EndStateBubble
                key={id}
                id={id}
                streamName={name}
                execution_arn={execution_arn}
                status={status}
                removeSelf={() => succeededExecutions.removeById(id)}
                pushToStaged={stagedForExecution.push}
              />
            )
          )}
        </Accordion>
        </FlexboxRows>
        </ContentWithSidebarPage>
        );
}
export default OnboardStreams;
