import React, { useEffect, useState } from 'react';
//Custom Hooks
import { useAmplifyGet } from '../../hooks/useAmplifyApi';
//Components
import Bubble from '../Bubble';

function PendingBubble({
    id,
    streamName,
    createBubble,
    pushToStaged,
    removeFromPending,
    children,
    status,
    setStatus
  }) {
    const [tagData, tagLoading, tagError] = useAmplifyGet("config_tags/read", {
      action: "get_tag",
      tag_name: streamName,
    });
    const [streamData, streamLoading, streamError] = useAmplifyGet(
      "stream_config/view/config",
      { stream_name: streamName }
    );
  
    useEffect(() => {
      // console.log("The hook ran")
      // console.log(tagData, streamData)
      if (!tagLoading && !streamLoading) {
        //Once both API calls have completed
        if (tagData) {
          tagData.map((val) => {
            let newBubble = createBubble({ name: val, status:"Staged" });
            pushToStaged(newBubble);
          });
          removeFromPending(id);
        } else if (streamData) {
          pushToStaged({ id: id, name: streamData.name });
          removeFromPending(id);
        } else if (tagLoading || streamLoading) {
          setStatus("Loading");
        } else if (tagError && streamError) {
          console.log(tagError);
          setStatus("Invalid");
        }
      }
    }, [tagLoading, streamLoading, tagData, streamData, tagError, streamError]);
  
    return (
      <Bubble
        data={{ name: streamName }}
        headerKeys={["name"]}
        status={status}
        enabled={true}
        deleteSelf={() => removeFromPending(id)}
      >
        {children}
  
        {streamError ? (
          <div>
            <h5>Error Fetching as Stream Name</h5>
            <code>{streamError}</code>
          </div>
        ) : null}
  
        {tagError ? (
          <div>
            <h5>Error Fetching as Tag</h5>
            <code>{streamError}</code>
          </div>
        ) : null}
      </Bubble>
    );
  }

  export default PendingBubble