import React, { createContext, useReducer } from 'react';

const dxConextInitialState = {
    tableRowData : [],
    dxCurrentUser : null,
    dbDataReceived : false,
    tableAlertMsg: null,
    tableAlertFlag: false,
}

const dxReducer = (dxState, dxAction) => {
    switch (dxAction.type) {

        case 'SET_MUI_TABLE_ALERT':
            return {
                ...dxState,
                tableAlertMsg: dxAction.tableAlertMsg,
                tableAlertFlag: dxAction.tableAlertFlag,
            };

        case 'SET_DX_TABLE_ROW_DATA':
            return {
                ...dxState,
                tableRowData: dxAction.tableRowData
            };

        case 'SET_DX_CURRENTUSER':
            return {
                ...dxState,
                dxCurrentUser: dxAction.dxCurrentUser
            }

        case 'SET_DB_DATA_RECEIVED':
            return {
                ...dxState,
                dbDataReceived: dxAction.dbDataReceived
            }

        default: return dxState;
    }
}

const DxContext = createContext(null);

const DxContextProvider = ({children}) => {
    const dxContextValue = useReducer(dxReducer, dxConextInitialState );

    return(
        <DxContext.Provider value={dxContextValue}>
            {children}
        </DxContext.Provider>
    )
}

export {
    DxContext,
    DxContextProvider,
}