import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import ReactJson from 'react-json-view';
import { Link } from "react-router-dom";
import { Card, Container, Col, Row } from 'react-bootstrap';
import * as XLSX from 'xlsx';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { API, Auth } from 'aws-amplify';
import { AuthContext } from '../contexts/AuthProvider.js';

import Bubble, { BubbleGroup } from '../components/Bubble';
import { Button } from '../components/BasicControls';
import { TwoColumnGridPage } from '../components/Page';
import MultiSelect from '../components/MultiSelect';

const StyledFormHeader = styled.h5 `
  justify-content: center;
  align-items: center;
  margin: 0 auto; 
  text-align: center;
  font-size: 1.8vw;
  font-weight: bold;
  letter-spacing: 0.2rem;
  margin-bottom: 12px;
  color: #e47911; /* Amazon Orange */
`;

const StyledEntryCard = styled(Card)`
  background-color: white;
  padding: 0.5%;
  font-size: 1.0vw;
  margin-left: 0.5rem;
  border: 0.5px solid white;
  border-radius: 0.5rem;
  width: 100%;
`

function TAGConfig(){
    const [streams, setStreams] = useState([{ name: 'Feed Onboarding Form', command: 'test', status: 'Ready', data: "Json preview here", active: true }]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [action, setAction] = useState("");
    const [propertyId, setPropertyId] = useState("");
    const {user} = useContext(AuthContext);
    const [streamData, setStreamData] = useState([]);
    const [preview, setPreview] = useState({data: [], cols: []})
    let userName = JSON.parse(user.attributes.identities)[0].userId
    let newJson = "";
    console.log(streamData)
    
    const formErrorStyle = { color:'red', fontSize: '0.8vw', margin: '0px'};
    
    const propertyTypes = [
        "Live",
        "Linear",
        "Other"
        ]
    
    const propertyThresholds = [
        "LiveOpsNBA",
        "LiveOpsMLB",
        "LiveOpsPassThru"
        ]
        
    const configWorkflows = [
        "enkidu",
        "cloud",
        "manual"
        ]
        
    const origin = [
        {"label":"iad-1", "value":"iad-1"},
        {"label":"iad-2", "value":"iad-2"},
        {"label":"pdx-1", "value":"pdx-1"},
        {"label":"pdx-2", "value":"pdx-2"},
        {"label":"sfo-1", "value":"sfo-1"},
        {"label":"sfo-2", "value":"sfo-2"},
        {"label":"dub-1", "value":"dub-1"},
        {"label":"dub-2", "value":"dub-2"},
        {"label":"lhr-1", "value":"lhr-1"},
        {"label":"lhr-2", "value":"lhr-2"},
        {"label":"fra-1", "value":"fra-1"},
        {"label":"fra-2", "value":"fra-2"},
        {"label":"gru-1", "value":"gru-1"},
        {"label":"gru-2", "value":"gru-2"},
        {"label":"bom-1", "value":"bom-1"},
        {"label":"bom-2", "value":"bom-2"},
        {"label":"sin-1", "value":"sin-1"},
        {"label":"sin-2", "value":"sin-2"},
        {"label":"nrt-1", "value":"nrt-1"},
        {"label":"nrt-2", "value":"nrt-2"},
        {"label":"nrt-3", "value":"nrt-3"},
        {"label":"nrt-4", "value":"nrt-4"}
    ]
    
    const profiles = [
        "p1",
        "p2",
        "p5",
        "p7"
        ]
        
    const overrides = [
        "config overrides",
        "channel level overrides",
        "profile level overrides",
        "feed level overrides"
    ]
    const formValidationSchema= Yup.object().shape({
        propertyId: Yup.string()
    })
    
    function bubbleClickHandler(i) {
      const originalValue = streams[i].active;
      setStreams(streams => {
        let newStreams = streams.slice();
        newStreams.map((value, index) => {
          newStreams[index].active = false;
        });
        newStreams[i].active = !originalValue;
        setActiveIndex(i);
        return newStreams;
      });
    }

    function handleSubmit(e){
        console.log(e);
        processValues(e);
    }
    
    function processValues(values){
        console.log(values)
        console.log(values.propertyId)
        setPropertyId(values.propertyId)
        let path = "redactor/write"
        console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
        console.log(streamData)
        API.post(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            response: true,
            body: {
                feed: values, 
                stream: streamData
            }
        })
        .then(response => {
          console.log(response.data);
          setActiveBubble({
            data: JSON.stringify(response.data, null, 2),
            status: "Success"
          });
        })
        .catch(error => {
          console.log(error.response);
          setActiveBubble({
            data: error.response.data,
            status: "Failure"
          });
        })
    }
    
    const handleFile = (file) => {
        let filereader = new FileReader()
        filereader.onload = (event) => {
            let data = event.target.result
            const wb = XLSX.read(data, {type: "binary"});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const formattedData = XLSX.utils.sheet_to_json(ws, {header:1});
            setStreamData(formattedData)
            console.log(streamData)
        }
        filereader.readAsBinaryString(file)
    };
    
    function setActiveBubble(newValues) {
      setStreams(streams => {
        let newStreams = streams.slice();
        Object.keys(newValues).map(function(key, index) {
          newStreams[activeIndex][key] = newValues[key];
          console.log(newStreams[activeIndex][key]);
        });
        console.log(newStreams);
        return newStreams;
      });
    }
    
    
    function getJson(data){
        if (data === "Json preview here"){
            return {"value": data};
        }
        else if (data.includes("Traceback")){
            return {"Error": data};
        }
        else {
          return JSON.parse(data);
        }
    }

    function bubblePut(path, old_json, new_json, property_id) {
      setActiveBubble({
        status: "Running"
      });
      console.log(old_json);
      console.log(new_json);
      API.put(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            response: true,
            body: {
              original_json: old_json,
              new_json: new_json,
              author: userName,
              cr_description: "Add CR details here",
              propertyId: property_id
            }
          })
        .then(response => {
          setAction("create_cr");
          setActiveBubble({
            data: JSON.stringify(response.data),
            status: "Success"
          });
          console.log(response);
          console.log(response.data);
        })
        .catch(error => {
          console.log(error);
          console.log(error.response);
          setActiveBubble({
            data: error.response.data,
            status: "Failure"
          });
        });
    }
    
    function formatURL(url){
      console.log(url);
      let formatted_url = url.replace(/"/g,"");
      return formatted_url;
    }
    
    function onEdit(event){
      console.log(event);
      newJson=JSON.stringify(event.updated_src);
      console.log(newJson);
      return true;
    }


    function onAdd(event){
      console.log(event);
      newJson=JSON.stringify(event.updated_src);
      console.log(newJson);
      return true;
    }


    function onDelete(event){
      console.log(event);
      newJson=JSON.stringify(event.updated_src);
      console.log(newJson);
      return true;
    }
    
    return (
        <TwoColumnGridPage>
            <BubbleGroup id="bubbleGroup">
                <Formik
                    validateOnMount={true}
                    initialValues={{ propertyId: '', file:'', }}
                    validationSchema={formValidationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={false}
                    validateOnChange={true}
                    >
                    {(props) => (
                        <StyledEntryCard>
                        <Container>
                        <StyledFormHeader>TAG Config Onboarding Form</StyledFormHeader>
                        <Form autoComplete="off">
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="propertyId" style={{marginRight: "75px"}}><strong>Property ID:</strong></label>
                                    <Field 
                                      name="propertyId"
                                      type="text" placeholder="eg. romeovoid"
                                      className={'form-control font-size-0-8' + (props.errors.propertyId && props.touched.propertyId ? ' is-invalid' : '')}
                                    >
                                    </Field>
                                    <ErrorMessage name="propertyId" component="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="propertyName" style={{marginRight: "51px"}}><strong>Property Name:</strong></label>
                                    <Field 
                                      name="propertyName"
                                      type="text" placeholder="eg. Romeo Void 2021"
                                      className={'form-control font-size-0-8' + (props.errors.propertyId && props.touched.propertyId ? ' is-invalid' : '')}
                                    >
                                    </Field>
                                    <ErrorMessage name="propertyName" component="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="propertyType" style={{marginRight: "58px"}}><strong>Property Type:</strong></label>
                                    <Field 
                                      name="propertyType"
                                      as="select" 
                                      className={'form-control' + (props.errors.propertyType && props.touched.propertyType ? ' is-invalid' : '')}
                                    >
                                    <option value=''>Choose...</option>
                                    {propertyTypes.map((op, idx) => (
                                        <option key={`propertyType-idx-${op}`}   id={`idx-${op}`} >{op}</option>
                                    ))}
                                    </Field>
                                    <ErrorMessage name="propertyType" component="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            {props.values.propertyType === "Other" && (
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="propertyThreshold" style={{marginRight: "25px"}}><strong>Property Threshold:</strong></label>
                                    <Field 
                                      name="propertyThreshold"
                                      as="select" 
                                      className={'form-control' + (props.errors.propertyThreshold && props.touched.propertyThreshold ? ' is-invalid' : '')}
                                    >
                                    <option value=''>Choose...</option>
                                    {propertyThresholds.map((op, idx) => (
                                        <option key={`propertyThreshold-idx-${op}`}   id={`idx-${op}`} >{op}</option>
                                    ))}
                                    </Field>
                                    <ErrorMessage name="propertyThreshold" component="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            )}
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="configWorkflow" style={{marginRight: "38px"}}><strong>Config WorkFlow:</strong></label>
                                    <Field 
                                      name="configWorkflow"
                                      as="select" 
                                      className={'form-control' + (props.errors.configWorkflow && props.touched.configWorkflow ? ' is-invalid' : '')}
                                    >
                                    <option value=''>Choose...</option>
                                    {configWorkflows.map((op, idx) => (
                                        <option key={`configWorkflow-idx-${op}`}   id={`idx-${op}`} >{op}</option>
                                    ))}
                                    </Field>
                                    <ErrorMessage name="configWorkflow" component="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            {props.values.propertyType === "Linear" && (
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="awsAccountId" style={{marginRight: "43px"}}><strong>AWS Account ID:</strong></label>
                                    <Field 
                                      name="awsAccountId"
                                      type="text" placeholder="eg. 123456789012"
                                      className={'form-control font-size-0-8' + (props.errors.awsAccountId && props.touched.awsAccountId ? ' is-invalid' : '')}
                                    >
                                    </Field>
                                    <ErrorMessage name="awsAccountId" component="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            )}
                            <Row style={{marginBottom: "15px"}}>
                                <Col >
                                    <label htmlFor="origins" style={{marginRight: "102px"}}><strong>Origins:</strong></label>
                                    <div style={{display:'inline-block', width:"300px"}}>
                                    <MultiSelect 
                                        name={'origins'}
                                        options={origin}
                                    />
                                    <ErrorMessage name="origins" component="div" className="invalid-feedback" />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="file" style={{marginRight: "65px"}}><strong>List of feeds:</strong> </label>
                                    <input
                                        id="file"
                                        name="file" 
                                        type="file" 
                                        accept = ".xlsx"
                                        onChange={(e) => {handleFile(e.currentTarget.files[0])}}
                                        />
                                </Col>
                            </Row>
                            {props.values.propertyType !== "Linear" && (
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                        <label htmlFor="profiles" style={{marginRight: "94px"}}><strong>Profiles:</strong> </label>
                                        {profiles.map((reg, index) => (
                                        <>
                                        <label htmlFor="profiles" style={{marginRight: "2px"}}><strong>{reg}</strong></label>
                                        <Field style={{marginRight: "50px"}}
                                          name="profiles"
                                          type="checkbox"
                                          value={reg}
                                          className={'form-control font-size-0-8' + (props.errors.profiles && props.touched.profiles ? ' is-invalid' : '')}
                                        />
                                        </>
                                        ))}
                                    <ErrorMessage name="profiles" component="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            )}
                            {props.values.propertyType === "Linear" && (
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="primaryLanguage" style={{marginRight: "30px"}}><strong>Primary Language:</strong></label>
                                    <Field 
                                      name="primaryLanguage"
                                      type="text" placeholder="eg. ita"
                                      className={'form-control font-size-0-8' + (props.errors.primaryLanguage && props.touched.primaryLanguage ? ' is-invalid' : '')}
                                    >
                                    </Field>
                                    <ErrorMessage name="primaryLanguage" component="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            )}
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="overrides" style={{marginRight: "86px"}}><strong>Overrides:</strong></label>
                                    <Field 
                                      name="overrides"
                                      as="select" 
                                      className={'form-control' + (props.errors.overrides && props.touched.overrides ? ' is-invalid' : '')}
                                    >
                                    <option value=''>Choose...</option>
                                    {overrides.map((op, idx) => (
                                        <option key={`overrides-idx-${op}`}   id={`idx-${op}`} >{op}</option>
                                    ))}
                                    </Field>
                                    <ErrorMessage name="overrides" component="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="multicastPrefix" style={{marginRight: "42px"}}><strong>Multicast Prefix:</strong> </label>
                                    <Field 
                                      name="multicastPrefix"
                                      type="text" placeholder="eg. 239.10.29"
                                      className={'form-control font-size-0-8' + (props.errors.multicastPrefix && props.touched.multicastPrefix ? ' is-invalid' : '')}
                                    >
                                    </Field>
                                    <ErrorMessage name="multicastPrefix" component="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            {props.values.propertyType === "Live" && (
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="zixiPortStart" style={{marginRight: "54px"}}><strong>Zixi Port Start:</strong> </label>
                                    <Field 
                                      name="zixiPortStart"
                                      type="text" placeholder="eg. 3600"
                                      className={'form-control font-size-0-8' + (props.errors.zixiPortStart && props.touched.zixiPortStart ? ' is-invalid' : '')}
                                    >
                                    </Field>
                                    <ErrorMessage name="zixiPortStart" component="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            )}
                            {props.values.propertyType !== "Linear" && (
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="audioTracks" style={{marginRight: "60px"}}><strong>Audio Tracks:</strong> </label>
                                    <Field 
                                      name="audioTracks"
                                      type="text" placeholder="eg. deu,sfd,"
                                      className={'form-control font-size-0-8' + (props.errors.audioTracks && props.touched.audioTracks ? ' is-invalid' : '')}
                                    >
                                    </Field>
                                    <ErrorMessage name="audioTracks" component="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            )}
                            {props.values.propertyType !== "Linear" && (
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="ddpTracks" style={{marginRight: "70px"}}><strong>DDP Tracks:</strong></label>
                                    <Field style={{marginRight: "50px"}}
                                      name="ddpTracks"
                                      type="checkbox"
                                      value="ddpTracks"
                                      className={'form-control font-size-0-8' + (props.errors.ddpTracks && props.touched.ddpTracks ? ' is-invalid' : '')}
                                    />
                                </Col>
                            </Row>
                            )}
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="addEztv" style={{marginRight: "63px"}}><strong>Add to EZTV:</strong></label>
                                    <Field style={{marginRight: "50px"}}
                                      name="addEztv"
                                      type="checkbox"
                                      value="EZTV"
                                      className={'form-control font-size-0-8' + (props.errors.addEztv && props.touched.addEztv ? ' is-invalid' : '')}
                                    />
                                </Col>
                            </Row>
                            {props.values.propertyType !== "Linear" && (
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="seperatePseudolive" style={{marginRight: "8px"}}><strong>Seperate PseudoLive:</strong></label>
                                    <Field style={{marginRight: "50px"}}
                                      name="seperatePseudolive"
                                      type="checkbox"
                                      value="seperatePseudoLive"
                                      className={'form-control font-size-0-8' + (props.errors.seperatePseudolive && props.touched.seperatePseudolive ? ' is-invalid' : '')}
                                    />
                                </Col>
                            </Row>
                            )}
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <Button style= {{bottom: "10px", left: "550px", padding: "1rem"}} type="submit" variant="outline-info" >Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                        </Container>
                        </StyledEntryCard>)}
                </Formik>
            </BubbleGroup>
            <BubbleGroup id="bubbleGroup" style={{marginBottom:"10px", borderRadius:"10px"}}>
            {streams.map((stream, index) => (
                <Bubble key={index} data={stream} headerKeys={[]} headerClick={() => bubbleClickHandler(index)}>
                { action === "create_cr" ?
                    <Link to={`/review/${formatURL(stream.data)}`}>{formatURL(stream.data)}</Link>
                    :
                    <ReactJson src={getJson(stream.data)}
                        name="config"
                        collapsed={2}
                        onEdit={(e)=>onEdit(e)}
                        onAdd={(e)=>onAdd(e)}
                        onDelete={(e)=>onDelete(e)}
                        enableClipboard={true}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        displayArrayKey={false}
                        theme="bright-inverted"
                    />
                }
                <Button style= {{top: "90px", right: "40px", padding: "1rem", position: "fixed"}}
                onClick={() => bubblePut("redactor/cr", {}, stream.data, propertyId)}>Create CR
                </Button>
                </Bubble>
            ))}
            </BubbleGroup>
        </TwoColumnGridPage>
        )
    }
export default TAGConfig;