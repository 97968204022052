import React, { useState } from "react";
import { handleError } from "../utilities/galileoApi";
import { API } from "aws-amplify";
import { Button, StyledInput, ToggleButton } from "../components/BasicControls";
import useRecentFeednames from "../hooks/useRecentFeedNames";

function SaveAsTagButton(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [recentFeeds, addRecentFeed] = useRecentFeednames();
  const [status, setStatus] = useState("Save As Tag");

  console.log("Stream Names", props.streamNames);

  let tagName = "cmt_" + inputValue;
  const styles = {
    display: "flex",
    justifyContent: "right",
  };

  function addTag() {
    setStatus("Saving...");
    const headers = {
      "Content-Type": "application/json",
    };

    const requestConfig = {
      headers: headers,
      body: {
        action: "add_tag",
        tag_name: tagName,
        stream_names: props.streamNames,
      },
    };

    API.post(
      process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment, //API endpoints are configured per environment
      "config_tags/write",
      requestConfig
    )
      .then((response) => {
        //console.log('Response', response)
        setLoading(false);
        setData(response);
        addRecentFeed(tagName);
        setStatus("Saved");
      })
      .catch((error) => {
        //console.log(error.response);
        setLoading(false);
        setError(handleError(error));
        setStatus("Error");
      });
  }

  return (
    <div style={styles}>
      <div
        style={{
          color: "gray",
          alignItems: "center",
          marginBottom: 0,
          marginTop: "auto",
          fontSize: "larger",
        }}
      >
        cmt_
      </div>
      <StyledInput>
        <input
          placeholder="Tag Name"
          onChange={(e) => setInputValue(e.target.value)}
        ></input>
      </StyledInput>
      <Button onClick={addTag}>{status}</Button>
    </div>
  );
}

export default SaveAsTagButton;
