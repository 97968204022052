import React, { useState } from "react";
import { Icon } from "@fluentui/react/lib/Icon";

import { API, Auth } from "aws-amplify";

import Bubble, { BubbleGroup, BubbleCodeBlock } from "../components/Bubble";
import { Sidebar, SidebarGroup } from "../components/Sidebar";
import { Button, AddButton } from "../components/BasicControls";
import { StreamSelector } from "../components/StreamSelector";
import { ContentWithSidebarPage } from "../components/Page";

function ConfigViewer() {
  const [streams, setStreams] = useState([
    {
      name: "Output",
      command: "",
      status: "Ready",
      data: "Content goes here",
      active: true,
    },
  ]);
  const [activeIndex, setActiveIndex] = useState(0);

  function bubbleClickHandler(i) {
    const originalValue = streams[i].active;
    setStreams((streams) => {
      let newStreams = streams.slice();
      newStreams.map((value, index) => {
        newStreams[index].active = false;
      });
      newStreams[i].active = !originalValue;
      setActiveIndex(i);
      return newStreams;
    });
  }

  function addBubble() {
    setStreams(
      streams.concat({
        name: "Output",
        command: "",
        status: "Ready",
        data: "",
        active: false,
      })
    );
  }

  function bubbleGet(path, action) {
    setActiveBubble({
      status: "Running",
      command: action,
    });
    API.get(
      process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
      path,
      {
        headers: {
          "Cache-Control": "max-age=87000",
          "Content-type": "application/json",
        },
        queryStringParameters: {
          action: action,
          stream_name: streams[activeIndex]["name"],
        },
      }
    )
      .then((response) => {
        setActiveBubble({
          data: JSON.stringify(response, null, 2),
          status: "Success",
        });
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
        setActiveBubble({
          data: error.response.data,
          status: "Failure",
        });
      });
  }

  function bubbleGetXml(path, action) {
    setActiveBubble({
      status: "Running",
      command: action,
    });
    API.get(
      process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
      path,
      {
        headers: {
          "Cache-Control": "max-age=87000",
          "Content-type": "application/json",
        },
        queryStringParameters: {
          action: action,
          stream_name: streams[activeIndex]["name"],
          dry_run: true,
        },
      }
    )
      .then((response) => {
        setActiveBubble({
          data: response,
          status: "Success",
        });
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
        setActiveBubble({
          data: error.response.data,
          status: "Failure",
        });
      });
  }

  function bubblePost(path, action) {
    setActiveBubble({
      status: "Running",
      command: action,
    });
    console.log(
      "DV_LIVE_ENVIRONMENT:",
      process.env.REACT_APP_DV_LIVE_ENVIRONMENT
    );
    API.post(
      process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
      path,
      {
        response: true,
        body: {
          stream_name: streams[activeIndex]["name"],
          action: action,
          dry_run: true,
        },
      }
    )
      .then((response) => {
        setActiveBubble({
          data: JSON.stringify(response, null, 2),
          status: "Success",
        });
      })
      .catch((error) => {
        console.log(error.response);
        setActiveBubble({
          data: error.response.data,
          status: "Failure",
        });
      });
  }

  function bubblePostXml(path, action) {
    setActiveBubble({
      status: "Running",
      command: action,
    });
    console.log(
      "DV_LIVE_ENVIRONMENT:",
      process.env.REACT_APP_DV_LIVE_ENVIRONMENT
    );
    API.post(
      process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
      path,
      {
        response: true,
        body: {
          stream_name: streams[activeIndex]["name"],
          action: action,
          dry_run: true,
        },
      }
    )
      .then((response) => {
        setActiveBubble({
          data: response.data,
          status: "Success",
        });
      })
      .catch((error) => {
        console.log(error.response);
        setActiveBubble({
          data: error.response.data,
          status: "Failure",
        });
      });
  }

  function testCredentials(method) {
    Auth.currentUserCredentials()
      .then((response) => {
        setActiveBubble({
          data: JSON.stringify(response, null, 2),
          status: "Success",
        });
      })
      .catch((error) => {
        console.log(error.response);
        setActiveBubble({
          data: JSON.stringify(error, null, 2),
          status: "Failure",
        });
      });
  }

  function setActiveBubble(newValues) {
    setStreams((streams) => {
      let newStreams = streams.slice();
      Object.keys(newValues).map(function (key, index) {
        newStreams[activeIndex][key] = newValues[key];
      });
      return newStreams;
    });
  }

  function handleSearch(event) {
    setActiveBubble({ name: event.target.value });
  }

  function formatXML(xml, tab = "\t", nl = "\n") {
    let formatted = "",
      indent = "";
    const nodes = xml.slice(1, -1).split(/>\s*</);
    if (nodes[0][0] == "?") formatted += "<" + nodes.shift() + ">" + nl;
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node[0] == "/") indent = indent.slice(tab.length); // decrease indent
      formatted += indent + "<" + node + ">" + nl;
      if (
        node[0] != "/" &&
        node[node.length - 1] != "/" &&
        node.indexOf("</") == -1
      )
        indent += tab; // increase indent
    }
    return formatted;
  }

  function makeEnterBlur(event) {
    if (event.keyCode === 13) {
      event.target.blur();
    }
  }

  return (
    <ContentWithSidebarPage>
      <Sidebar>
        <StreamSelector
          onBlur={handleSearch}
          onKeyDown={makeEnterBlur}
          underlined={true}
          placeholder="Stream Name"
        />
        <SidebarGroup name="Stream Config Read">
          <Button onClick={() => bubbleGet("stream_config/view/config", "")}>
            View Config
          </Button>
          <Button onClick={() => bubbleGet("stream_config/resolve", "")}>
            Resolve Config
          </Button>
          <Button
            onClick={() => bubbleGetXml("hammerhand/read", "generate_live_xml")}
          >
            Generate XML
          </Button>
          <Button
            onClick={() => bubbleGet("stream_config/ping", "list_all_configs")}
          >
            List All Configs
          </Button>
        </SidebarGroup>
        <SidebarGroup name="Dagon Viewer">
          <Button
            onClick={() =>
              bubbleGetXml("deployment_data/read", "get_deployment_data")
            }
          >
            View Deployment XML
          </Button>
          <Button
            onClick={() =>
              bubbleGetXml("elemental_live/read", "get_live_event_xml")
            }
          >
            View Encoder XML
          </Button>
          <Button
            onClick={() => bubbleGetXml("dagon_monitors/read", "view_diff_xml")}
          >
            View XML Difference
          </Button>
        </SidebarGroup>
        <SidebarGroup name="Diagnostics">
          <Button onClick={() => testCredentials(Auth.currentSession)}>
            Display Session
          </Button>
          <Button onClick={() => Auth.federatedSignIn()}>Force Login</Button>
        </SidebarGroup>
      </Sidebar>
      <BubbleGroup id="bubbleGroup" style={{ marginRight: "40px" }}>
        {streams.map((stream, index) => (
          <Bubble
            active={true}
            key={index}
            data={stream}
            headerKeys={["name", "command"]}
            headerClick={() => bubbleClickHandler(index)}
          >
            <BubbleCodeBlock>{stream.data}</BubbleCodeBlock>
          </Bubble>
        ))}
      </BubbleGroup>
      <AddButton onClick={() => addBubble()}>
        <Icon iconName="Add" />
      </AddButton>
    </ContentWithSidebarPage>
  );
}

export default ConfigViewer;
