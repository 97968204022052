import React from "react";
import styled from "styled-components";

export const TwelveSquareGridPage = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
`;

export const ThreeColumnGridPage = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const ContentWithSidebarPage = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.sidebarWidth || "20rem"} 1fr;
`;

export const ColumnFlexPage = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowFlexPage = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div``;

export const Preview = styled.div`
  width: 100%;
  background-color: White;
  margin-bottom: "10px";
  margin-top: "10px";
  border-radius: 30px;
  padding: 0 20px 0 20px;
`;

export const TwoColumnGridPage = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;
