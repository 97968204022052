import React from 'react';
import { useState } from 'react';
import { API } from 'aws-amplify';
//Components
import Bubble from '../Bubble';

function OnboardStagedBubble({
    id,
    streamName,
    stagedFunction,
    executionRegion,
    removeFromStaged,
    pushToRunning,
    status,
    setStatus,
    children,
    ...props
  }) {
    console.log(streamName)
    const [feedName, region, subregion] = streamName.split("-");
    const [error, setError] = useState();
    
    const bubbleData = {
      name: streamName,
      execution_region: executionRegion || "us-west-2",
      staged_function: stagedFunction,
    };

    return (
      <Bubble
        data={bubbleData}
        headerKeys={["name", "staged_function", "execution_region"]}
        status={status}
        enabled={true}
        deleteSelf={false}
      >
        {children}
        {error ? (
          <div>
            <h5>Error Starting Execution</h5>
            <code>{error}</code>
          </div>
        ) : null}
      </Bubble>
    );
  }

  export default OnboardStagedBubble