import React from 'react';
import ReactDOM from 'react-dom';
import Amplify, { Auth } from 'aws-amplify';
import { AuthProvider } from './contexts/AuthProvider'
import Dante from './App'

import { oauthconfig, oauthconfigGamma } from './config/oauthconfig';
import { oauthconfigLocal } from './config/oauthconfiglocal';
import { awsconfig, awsconfigGamma } from './config/awsconfig';

import { initializeIcons } from '@uifabric/icons';

import './index.css'

initializeIcons();
// Amplify.configure(awsconfig);

console.log('Node ENV', process.env.NODE_ENV)
console.log('React App ENV', process.env.REACT_APP_DV_LIVE_ENVIRONMENT)
console.log('React App API ENV', process.env.REACT_APP_API_ENVIRONMENT)
var selectedConfig


//NODE_ENV will be always 'production' in Amplify
// https://create-react-app.dev/docs/adding-custom-environment-variables/
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_API_ENVIRONMENT === "Prod") {
  console.log("Using Prod OAuth config and Prod API")
  selectedConfig = oauthconfig
  Amplify.configure(awsconfig);
  Auth.configure({ oauth: selectedConfig });
}
else if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_API_ENVIRONMENT === "Gamma") {
  console.log("Using Gamma OAuth config and Gamma API")
  selectedConfig = oauthconfigGamma
  Amplify.configure(awsconfigGamma);
  Auth.configure({ oauth: selectedConfig });
}
else {
  console.log("Using Local Oauth config and Gamma API")
  selectedConfig = oauthconfigLocal
  Amplify.configure(awsconfig);
  Auth.configure({ oauth: selectedConfig });
}

// Auth.configure({ oauth: selectedConfig });

ReactDOM.render(
  <AuthProvider>
    <Dante/>
  </AuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
