import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import ReactJson from 'react-json-view';
import { Link } from "react-router-dom";
import { Card, Container, Col, Row } from 'react-bootstrap';

import * as XLSX from 'xlsx';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { API } from 'aws-amplify';
import { AuthContext } from '../contexts/AuthProvider.js';

import Bubble, { BubbleGroup } from '../components/Bubble';
import { Button } from '../components/BasicControls';
import { TwoColumnGridPage } from '../components/Page';
import { DisasterRecovery } from '../components/ProfileFields';

const StyledFormHeader = styled.h5 `
  justify-content: center;
  align-items: center;
  margin: 0 auto; 
  text-align: center;
  font-size: 1.8vw;
  font-weight: bold;
  letter-spacing: 0.2rem;
  margin-bottom: 12px;
  color: #e47911; /* Amazon Orange */
`;

const StyledEntryCard = styled(Card)`
  background-color: white;
  padding: 0.5%;
  font-size: 1.0vw;
  margin-left: 0.5rem;
  border: 0.5px solid white;
  border-radius: 0.5rem;
  width: 100%;
`;


function FeedOnboardingForm(){
    const [streams, setStreams] = useState([
      { 
        name: "Feed Onboarding Form",
        command: "test",
        status: "Ready",
        data: "Json preview here",
        active: true, 
      },
    ]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [action, setAction] = useState("");
    const [tenant, setTenant] = useState("");
    // const [show, setShow] = useState(false);
    const {user} = useContext(AuthContext);
    const [streamData, setStreamData] = useState([]);
    // const [preview, setPreview] = useState({data: [], cols: []})
    let userName = JSON.parse(user.attributes.identities)[0].userId;
    const architectures = [
            "1 - Hardware Encoder > HAProxy > EMP",
            "2 - Zixi Broadcaster > EMX > EML > EMP",
            "3 - AppearTV > Zixi Broadcaster > EMX > EML > EMP",
            "5 - Partner > EMX > EML > EMP",
            "6 - EML > EMP"
        ];
    
    const redundancy = [
            "double",
            "quad"
        ];
        
    const realms = [
        "US",
        "EU",
        "JP"
    ];
    
    const regions = {
        "US":[
            "iad",
            "pdx",
            "sfo",
            "gru"
        ],
        "EU":[
            "dub",
            "fra",
            "lhr",
            "bom",
            "sin"
        ],
        "JP":[
            "nrt-1",
            "nrt-4",
            "syd-1",
            "syd-2"
        ]
    };

    const formValidationSchema= Yup.object().shape({
        architecture: Yup.string(),
        issue_id: Yup.string(),
        listOfFeeds: Yup.object(),
    });

    function bubbleClickHandler(i) {
      const originalValue = streams[i].active;
      setStreams(streams => {
        let newStreams = streams.slice();
        newStreams.map((value, index) => {
          newStreams[index].active = false;
        });
        newStreams[i].active = !originalValue;
        setActiveIndex(i);
        return newStreams;
      });
    }

    function handleSubmit(e){
        console.log("testing handle submit");
        console.log(e);
        processValues(e);
    }

    // const make_cols = refstr => {
    //   let o = [],
    //     C = XLSX.utils.decode_range(refstr).e.c + 1;
    //   for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    //   return o;
    // };


    // const showFile = () => {
    //     setFile({data: data, cols:make_cols(wS["!ref"])})
    // }

    const handleFile = (file) => {
        console.log("uploaded file");
        let filereader = new FileReader();
        filereader.onload = (event) => {
            let data = event.target.result;
            const wb = XLSX.read(data, {type: "binary"});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const formattedData = XLSX.utils.sheet_to_json(ws, {header:1});
            setStreamData(formattedData);
            console.log(streamData);
        };
        filereader.readAsBinaryString(file);
    };

    function processValues(values){
        console.log(values);
        console.log(values.tenantName);
        let path = "redactor/write";
        console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
        console.log(streamData);
        API.post(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            response: true,
            body: {
                feed: values, 
                stream: streamData
            }
        })
        .then(response => {
          console.log(response.data);
          console.log(response.data[0]["name"]);
          if (values.tenantName){
            setTenant(values.tenantName);
          }
          else{
            setTenant(response.data[0]["name"]);
          }
          setActiveBubble({
            data: JSON.stringify(response.data, null, 2),
            status: "Success"
          });
        })
        .catch(error => {
          console.log(error.response);
          setActiveBubble({
            data: error.response.data,
            status: "Failure"
          });
        });
    }


    function setActiveBubble(newValues) {
      setStreams(streams => {
        let newStreams = streams.slice();
        Object.keys(newValues).map(function(key, index) {
          newStreams[activeIndex][key] = newValues[key];
          console.log(newStreams[activeIndex][key]);
        });
        console.log(newStreams);
        return newStreams;
      });
    }

    function getJson(config, status){
        if (config === "Json preview here"){
            return {"value": config};
        }
        else if (config.includes("Traceback")){
            return {"Error": config};
        }
        else  {
          if(status === "Success"){
            console.log(JSON.parse(config));
            return JSON.parse(config);
          }
          else {
            return {"Error": config.data};
          }
        }
    }

    function bubblePut(path, old_json, new_json, stream_name) {
      setActiveBubble({
        status: "Running"
      });
      console.log(old_json);
      console.log(new_json);
      API.put(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            response: true,
            body: {
              original_json: old_json,
              new_json: new_json,
              author: userName,
              cr_description: "Add CR details here",
              stream_names: stream_name
            }
          })
        .then(response => {
          setAction("create_cr");
          setActiveBubble({
            data: JSON.stringify(response.data),
            status: "Success"
          });
          console.log(response);
          console.log(response.data);
        })
        .catch(error => {
          console.log(error);
          console.log(error.response);
          setActiveBubble({
            data: error.response.data,
            status: "Failure"
          });
        });
    }
    
    function formatURL(url){
      console.log(url);
      let formatted_url = url.replace(/"/g,"");
      return formatted_url;
    }

    return (
        <TwoColumnGridPage>
            <BubbleGroup id="bubbleGroup">
                <Formik
                    validateOnMount={true}
                    initialValues={{ issue_id: '', architecture: '', sources: '',}}
                    validationSchema={formValidationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={false}
                    validateOnChange={true}
                    >
                    {(props) => (
                        <StyledEntryCard>
                        <Container>
                        <Button onClick={() => window.open('https://w.amazon.com/bin/view/Amazon_Video/PV_Playback/LiveOps/Infrastructure/reference/FeedFormation/FeedOnboardingRunbook/#HEncoderMappings:')}>Help</Button>
                        <StyledFormHeader>Feed Onboarding Form </StyledFormHeader>
                        <Form autoComplete="off">
                          <Row style={{marginBottom: "15px"}}>
                            <Col>
                              <label htmlFor="architecture" style={{marginRight: "46px"}}><strong>Architecture:</strong></label>
                              <Field 
                                name="architecture"
                                as="select" 
                                className={'form-control' + (props.errors.architecture && props.touched.architecture ? ' is-invalid' : '')}
                              >
                              <option value=''>Choose...</option>
                              {architectures.map((op, idx) => (
                                  <option key={`architecture-idx-${op}`}   id={`idx-${op}`} >{op}</option>
                              ))}
                              </Field>
                              <ErrorMessage name="architecture" component="div" className="invalid-feedback" />
                            </Col>
                          </Row>
                          {(props.values.architecture === "1 - Hardware Encoder > HAProxy > EMP") ?
                            <div>
                              <Row style={{marginBottom: "15px"}}>
                                <Col>
                                  <label htmlFor="issue_id" style={{marginRight: "35px"}}><strong>Issue ID:</strong></label>
                                  <Field 
                                    name="issue_id"
                                    type="text" placeholder="eg. LIVE-VID-OPS-1234"
                                    className={'form-control font-size-0-8' + (props.errors.issue_id && props.touched.issue_id ? ' is-invalid' : '')}
                                  >
                                  </Field>
                                  <ErrorMessage name="issue_id" component="div" className="invalid-feedback" />
                                </Col>
                              </Row>
                              <Row style={{marginBottom: "15px"}}>
                                <Col>
                                  <label htmlFor="disasterRecovery" style={{marginRight: "43px"}}><strong>Disaster Recovery:</strong></label>
                                  <DisasterRecovery name={'disasterRecovery'} />
                                  <ErrorMessage name="disasterRecovery" component="div" className="invalid-feedback" />
                                </Col>
                              </Row>
                            </div>:
                          (props.values.architecture === "3 - AppearTV > Zixi Broadcaster > EMX > EML > EMP")?
                            <div>
                              <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="tenantName" style={{marginRight: "35px"}}><strong>Tenant Name:</strong></label>
                                    <Field 
                                      name="tenantName"
                                      type="text" placeholder="eg. romeovoid2021"
                                      className={'form-control font-size-0-8' + (props.errors.tenantName && props.touched.tenantName ? ' is-invalid' : '')}
                                    >
                                    </Field>
                                    <ErrorMessage name="tenantName" component="div" className="invalid-feedback" />
                                </Col>
                              </Row>
                              <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="realm" style={{marginRight: "77px"}}><strong>Realm:</strong> </label>
                                    <Field 
                                      name="realm"
                                      as="select" 
                                      className={'form-control font-size-0-8' + (props.errors.realm && props.touched.realm ? ' is-invalid' : '')}
                                    >
                                    <option value=''>Choose...</option>
                                    {realms.map((op, idx) => (
                                        <option key={`realm-idx-${op}`}   id={`idx-${op}`} >{op}</option>
                                    ))}
                                    </Field>
                                    <ErrorMessage name="realm" component="div" className="invalid-feedback" />
                                </Col>
                              </Row>
                              <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    {props.values.realm !== "" && (
                                    <div>
                                        <label htmlFor="region" style={{marginRight: "73px"}}><strong>Region:</strong> </label>
                                        {regions[props.values.realm]?.map((reg, index) => (
                                        <>
                                        <label htmlFor="region" style={{marginRight: "10px"}}><strong>{reg}</strong></label>
                                        <Field style={{marginRight: "50px"}}
                                          name="region"
                                          type="checkbox"
                                          value={reg}
                                          className={'form-control font-size-0-8' + (props.errors.region && props.touched.region ? ' is-invalid' : '')}
                                        />
                                        </>
                                        ))}
                                    </div>)}
                                    <ErrorMessage name="region" component="div" className="invalid-feedback" />
                                </Col>
                              </Row>
                            </div>:
                          (props.values.architecture === "5 - Partner > EMX > EML > EMP" || props.values.architecture === "6 - EML > EMP") ?
                            <div>
                              <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="tenantName" style={{marginRight: "35px"}}><strong>Tenant Name:</strong></label>
                                    <Field 
                                      name="tenantName"
                                      type="text" placeholder="eg. romeovoid2021"
                                      className={'form-control font-size-0-8' + (props.errors.tenantName && props.touched.tenantName ? ' is-invalid' : '')}
                                    >
                                    </Field>
                                    <ErrorMessage name="tenantName" component="div" className="invalid-feedback" />
                                </Col>
                              </Row>
                              <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="realm" style={{marginRight: "77px"}}><strong>Realm:</strong> </label>
                                    <Field 
                                      name="realm"
                                      as="select" 
                                      className={'form-control font-size-0-8' + (props.errors.realm && props.touched.realm ? ' is-invalid' : '')}
                                    >
                                    <option value=''>Choose...</option>
                                    {realms.map((op, idx) => (
                                        <option key={`realm-idx-${op}`}   id={`idx-${op}`} >{op}</option>
                                    ))}
                                    </Field>
                                    <ErrorMessage name="realm" component="div" className="invalid-feedback" />
                                </Col>
                              </Row>
                              <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    {props.values.realm !== "" && (
                                    <div>
                                        <label htmlFor="region" style={{marginRight: "73px"}}><strong>Region:</strong> </label>
                                        {regions[props.values.realm]?.map((reg, index) => (
                                        <>
                                        <label htmlFor="region" style={{marginRight: "10px"}}><strong>{reg}</strong></label>
                                        <Field style={{marginRight: "50px"}}
                                          name="region"
                                          type="checkbox"
                                          value={reg}
                                          className={'form-control font-size-0-8' + (props.errors.region && props.touched.region ? ' is-invalid' : '')}
                                        />
                                        </>
                                        ))}
                                    </div>)}
                                    <ErrorMessage name="region" component="div" className="invalid-feedback" />
                                </Col>
                              </Row>
                              <Row style={{marginBottom: "15px"}}>
                                <Col>
                                  <label htmlFor="redundancy" style={{marginRight: "43px"}}><strong>Redundancy:</strong></label>
                                  <Field style ={{marginBottom: "10px"}}
                                    name="redundancy"
                                    as="select" 
                                    className={'form-control font-size-0-8' + (props.errors.redundancy && props.touched.redundancy ? "is-invalid" : '')}
                                  >
                                    <option value=''>Choose...</option>
                                    {redundancy.map((op, idx) => (
                                        <option key={`redundancy-idx-${op}`}   id={`idx-${op}`} >{op}</option>
                                    ))}
                                  </Field>
                                  <ErrorMessage name="redundancy" component="div" className="invalid-feedback" />
                                </Col>
                              </Row>
                            </div>:
                          null
                          }
                          <Row style={{marginBottom: "15px"}}>
                            <Col>
                              <label htmlFor="file" style={{marginRight: "38px"}}><strong>List of feeds:</strong> </label>
                              <input
                                id="file"
                                name="file" 
                                type="file" 
                                accept = ".xlsx"
                                onChange={(e) => {handleFile(e.currentTarget.files[0])}}
                              />
                              <Button onClick={() => window.open('https://w.amazon.com/bin/view/AIV/LiveEvents/LiveOps/Infrastructure/reference/FeedFormation/templates/')}>Download Template</Button>
                            </Col>
                          </Row>
                          <Row style={{marginBottom: "15px"}}>
                            <Col>
                                <Button style= {{bottom: "10px", left: "400px", padding: "1rem"}} type="submit" variant="outline-info" >Submit</Button>
                            </Col>
                          </Row>
                        </Form>
                      </Container>
                    </StyledEntryCard>)}
                  </Formik>
                </BubbleGroup>
              <BubbleGroup id="bubbleGroup" style={{marginBottom:"10px", borderRadius:"10px"}}>
              {streams.map((stream, index) => (
                <Bubble active={true} key={index} data={stream} headerKeys={[]} headerClick={() => bubbleClickHandler(index)}>
                { action === "create_cr" ?
                    <Link to={`/review/${formatURL(stream.data)}`}>{formatURL(stream.data)}</Link>
                    :
                    <ReactJson src={getJson(stream.data, stream.status)}
                        name="config"
                        collapsed={2}
                        onEdit={false}
                        onAdd={false}
                        onDelete={false}
                        enableClipboard={true}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        displayArrayKey={false}
                        theme="bright-inverted"
                    />
                }
                <Button style= {{top: "90px", right: "40px", padding: "1rem", position: "fixed"}}
                onClick={() => bubblePut("redactor/cr", {}, stream.data, tenant)}>Create CR
                </Button>
                </Bubble>
            ))}
          </BubbleGroup>
        </TwoColumnGridPage>
      );
    }
export default FeedOnboardingForm;