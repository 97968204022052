import React from 'react';
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StatusIndicatorStyles = styled.div`
  background-color: ${(props) => props.background};
  border-radius: 10px;
  padding: 0.3rem 0.75rem;
  color: white;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  margin-right: 5px;
  margin-left: 5px;

  svg {
    margin-right: 0px;
  }
`;

function StatusIndicator(props) {
  const statusUppercase = props.status.toUpperCase(); // Make it case insensitive
  const statusLowercase = props.status.toLowerCase() // CSS Captitilize only works with lowercase strings
  let statusIcon = "";
  let backgroundColor = "";
  switch (statusUppercase) {
    case "COMPLETE":
    case "SUCCESS":
    case "SUCCEEDED":
      statusIcon = "check-circle";
      backgroundColor = "green";
      break;
    case "ERROR":
    case "FAILURE":
    case "INVALID":
      statusIcon = "exclamation-triangle";
      backgroundColor = "red";
      break;
    case "WARNING":
      statusIcon = "exclamation-triangle";
      backgroundColor = "Yellow";
      break;

    case "STAGED":
      statusIcon = "info-circle";
      backgroundColor = "LightBlue";
      break;
    case "RUNNING":
      statusIcon = "spinner";
      backgroundColor = "LightBlue";
      return (
        <StatusIndicatorStyles background={backgroundColor}>
          <span>{[statusLowercase]}</span>
          <FontAwesomeIcon icon={statusIcon} spin />
        </StatusIndicatorStyles>
      );
    default:
      statusIcon = false;
      backgroundColor = "LightGray";
  }
  return (
    <StatusIndicatorStyles background={backgroundColor}>
      <span>{[statusLowercase]}</span>
      { statusIcon ?
      <FontAwesomeIcon icon={statusIcon} />
      :
      null
      }
      
    </StatusIndicatorStyles>
  );
}

export default StatusIndicator;
