import React, {useEffect, useState} from "react";
import { useArray, useNumber } from "react-hanger";
import { API } from "aws-amplify";
import { Accordion } from "../components/Accordion";
import { Button, StyledInput, ToggleButton } from "../components/BasicControls";
import { ContentWithSidebarPage } from "../components/Page";
import { Sidebar, SidebarGroup } from "../components/Sidebar";
import { handleError } from "../utilities/galileoApi";
import { FlexboxRows } from "../components/Layout";

export default function Nuke() {
//to keep track of buttons that get selected/deselected
const [iad1Name, setIad1Name] = useState('Not Selected');
const [iad2Name, setIad2Name] = useState('Not Selected');
const [pdx1Name, setPdx1Name] = useState('Not Selected');
const [pdx2Name, setPdx2Name] = useState('Not Selected');
const [revertIad1Name, setRevertIad1Name] = useState('Not Selected');
const [revertIad2Name, setRevertIad2Name] = useState('Not Selected');
const [revertPdx1Name, setRevertPdx1Name] = useState('Not Selected');
const [revertPdx2Name, setRevertPdx2Name] = useState('Not Selected');

const [error, setError] = useState("");
const stagedForExecution = useArray([]);
const streamValues = useArray([]);
const executionArn = useArray([]);
const executionRunning = useArray([]);
const executionFailure = useArray([]);
const executionSuccess = useArray([]);


	function HandleIad1Click(){
		if (iad1Name === 'Not Selected'){
	      		setIad1Name('Selected');
		} else { 
		setIad1Name('Not Selected')
		}
	}

	function HandleIad2Click(){
		if (iad2Name === 'Not Selected'){
			setIad2Name('Selected');
		} else { 
		setIad2Name('Not Selected')
		}
	}

        function HandlePdx1Click(){
		if (pdx1Name === 'Not Selected'){
			setPdx1Name('Selected');
		} else { 
		setPdx1Name('Not Selected')
		}
	}

        function HandlePdx2Click(){
		if (pdx2Name === 'Not Selected'){
			setPdx2Name('Selected');
		} else { 
		setPdx2Name('Not Selected')
		}
	}

        function HandleRevertIad1Click(){
		if (revertIad1Name === 'Not Selected'){
			setRevertIad1Name('Selected');
		} else {
			setRevertIad1Name('Not Selected')
		}                       
	}   

	function HandleRevertIad2Click(){
		if (revertIad2Name === 'Not Selected'){
			setRevertIad2Name('Selected');
		} else {
			setRevertIad2Name('Not Selected')
			}
	}

        function HandleRevertPdx1Click(){
		if (revertPdx1Name === 'Not Selected'){
			setRevertPdx1Name('Selected');
		} else {
			setRevertPdx1Name('Not Selected')
			}
		}

	function HandleRevertPdx2Click(){
		if (revertPdx2Name === 'Not Selected'){
			setRevertPdx2Name('Selected');
		} else {
		setRevertPdx2Name('Not Selected')
		}
	}

	function PerformFunction(stagedFunction){
		//these 2 blocks of if statements are set up to block a user from selecting
		//buttons on both failover and revert options.
		if (stagedFunction === "dr_reconfigure_live"){
			if(revertIad1Name === "Selected" || revertIad2Name == "Selected" ||
				revertPdx1Name === "Selected" || revertPdx2Name == "Selected"){
				alert("Please make sure no revert streams are selected");
				return;
			}
		}

                if (stagedFunction === "rollback_dr_reconfigure_live"){
			if(iad1Name === "Selected" || iad2Name == "Selected" ||
				pdx1Name === "Selected" || pdx2Name == "Seected"){
				alert("Please make sure no failover streams are selected");
				return;
			}
		}
		
		if (iad1Name === "Selected" && iad2Name === "Selected"){
			alert("You cant select both iad values to failover at once");
			return;
		}

		if (pdx1Name === "Selected" && pdx2Name === "Selected"){
			alert("You cant select both pdx values to failover at once");
			return;
		}

		if (revertIad1Name === "Selected" && revertIad2Name === "Selected"){
			alert("You cant select both Iad values to revert at once");
			return;
		}
		if (revertPdx1Name === "Selected" && revertPdx2Name === "Selected"){
			alert("You cant select both PDX values to revert at once");
			return;
		}
		//clears all values on accordions prior to running
		stagedForExecution.clear();
		streamValues.clear();
		executionRunning.clear();
		executionSuccess.clear();
		executionFailure.clear();
		executionArn.clear();

		//here we querytag names depending on what button was selected
                if (iad1Name === "Selected" || revertIad1Name === "Selected") {
		//	stagedForExecution.push("cmt_tnf2022_iad1");
		//	QueryTag("cmt_tnf2022_iad1", stagedFunction);

		}	

		if (iad2Name === "Selected" || revertIad2Name === "Selected") {
		//	stagedForExecution.push("cmt_tnf2022_iad2");
		//	QueryTag("cmt_tnf2022_iad2", stagedFunction);
		}
		if (pdx1Name === "Selected" || revertPdx1Name === "Selected") {
		//	stagedForExecution.push("cmt_tnf2022_pdx1");
		//	QueryTag("cmt_tnf2022_pdx1", stagedFunction);
			stagedForExecution.push("cmt_jose_tnf_dr_test_pdx_2");
			QueryTag("cmt_jose_tnf_dr_test_pdx_2", stagedFunction);
		}
		if (pdx2Name === "Selected" || revertPdx2Name === "Selected") {
		//	stagedForExecution.push("cmt_tnf2022_pdx2");
		//	QueryTag("cmt_tnf2022_pdx2", stagedFunction);
			stagedForExecution.push("cmt_jose_tnf_dr_test_pdx_2");
			QueryTag("cmt_jose_tnf_dr_test_pdx_2", stagedFunction);
		}
	}

	function QueryTag(stream, stagedFunction){
		const headers = {
			        "Content-Type": "application/json",
			        };
		const apiEnv = process.env.REACT_APP_API_ENVIRONMENT;
		const path = "config_tags/read/";
		const requestConfig = {
			        headers: headers,
			       queryStringParameters: {action: "get_tag", tag_name: stream,},
			        };
	    		API.get(
		          apiEnv, //API endpoints are configured per environment
		          path,
		          requestConfig
		        )
		      .then((response) => {
			streamValues.push(response);
			console.log("Results: ", response);
			TriggerStateMachine(response, response.length, stagedFunction);
			  })
			.catch((error) => {
			setError(handleError(error));
			});
		console.log("streamValues: ", streamValues.value);
	}

	function TriggerStateMachine(responseOutput, countValue, stagedFunction){
		let executionRegion = "";

		if (responseOutput[0].includes("iad")){
			executionRegion = "us-east-1";
		}

		if (responseOutput[0].includes("pdx")){
			 executionRegion = "us-west-2";
		}

                const headers = {
			"Content-Type": "application/json",
		};
		const apiEnv = process.env.REACT_APP_API_ENVIRONMENT;
		const path = "step_functions/write";
		const streamName = "";		
		for (let i = 0; i < countValue; i++) {
			  console.log(responseOutput[i]);
			  executionRunning.push(responseOutput[i]);
			  const requestConfig = {
				headers: headers,               
				body: {action: "start_step_function", 
				state_machine: stagedFunction, region: executionRegion,
				stream_name: responseOutput[i],},
			};
    		API.post(
    			apiEnv, //API endpoints are configured per environment
	        	path,
		      	requestConfig
		        )
			.then((response) => {
			console.log('Response', response)
			executionArn.push(response)
			setTimeout(function () {
					PollStatus(apiEnv, countValue, response)}, 2000);
			})
			.catch((error) => {
			console.log(error.response);
			setError(handleError(error));
			});
		}
	}

	function ParseStreamFromArn(execution_Arn, inputValue){
		const myArray = execution_Arn.split(":");
		let arnTemp = myArray[7];
		const myArray2 = arnTemp.split("-");
		execution_Arn = myArray2[0] + "-" + myArray2[1] + "-" + myArray2[2];
		if(inputValue === "Failure"){
			executionFailure.push(execution_Arn);
		} else if (inputValue === "SUCCEEDED"){
			executionSuccess.push(execution_Arn);
		}
		console.log("output: ", inputValue);
		executionRunning.pop(execution_Arn);
		streamValues.pop(execution_Arn);
		stagedForExecution.clear();
	}

	function PollStatus(apiEnv, countValue, execution_Arn){
		console.log("made it to polling");
		const headers = {
			"Content-Type": "application/json",
		};
		const path = "step_functions/read";
		console.log("gave executionarn value of: ", execution_Arn); 	
		const params = { action: "status", execution_arn: execution_Arn};
		const requestConfig = {
			headers: headers,
			queryStringParameters: params,
		};
		API.get(
		apiEnv, //API endpoints are configured per environment
		path,
		requestConfig
		)
		.then((response) => {
		console.log('Response', response.status)
		if (response.status === "Failure"){
			ParseStreamFromArn(execution_Arn, "Failure");
		}
		else if (response.status === "SUCCEEDED"){
			ParseStreamFromArn(execution_Arn, "SUCCEEDED");
		}
		else if (response.status === "RUNNING"){
			console.log("polling again", execution_Arn);
			setTimeout(function () { PollStatus(apiEnv, countValue, execution_Arn)}, 2000);
		}
		})
		.catch((error) => {
		setError(handleError(error));
		});
	}

  return (
	  <>
	  <h1 style={{textAlign: "center"}}>Nuclear Button </h1>
	  <div style={{display: 'flex',  justifyContent:'left', alignItems:'right'}}>
	  <ContentWithSidebarPage>
	  <Sidebar>
	  <h3>Failover</h3>
	    <Button onClick={HandleIad1Click} >DR failover IAD-1 Switch {iad1Name}</Button>
	    <Button onClick={HandleIad2Click}>DR failover IAD-2 NBC {iad2Name}</Button>
	    <Button onClick={HandlePdx1Click}>DR failover PDX-1 Switch {pdx1Name}</Button>
	    <Button onClick={HandlePdx2Click}>DR failover PDX-2 Switch {pdx2Name}</Button>
	    <Button onClick={() => {PerformFunction("dr_reconfigure_live")}}> Failover Button </Button>
	  <h3>Revert</h3>
    <Button onClick={HandleRevertIad1Click}>Revert to IAD-1 Switch {revertIad1Name}</Button>
	    <Button onClick={HandleRevertIad2Click}>Revert to IAD-2 NBC {revertIad2Name}</Button>
	    <Button onClick={HandleRevertPdx1Click}>Revert to PDX-1 Switch {revertPdx1Name}</Button>
	    <Button onClick={HandleRevertPdx2Click}>Revert to PDX-2 Switch {revertPdx2Name}</Button>
	    <Button onClick={() => {PerformFunction("rollback_dr_reconfigure_live")}}> Revert Button </Button>
	  </Sidebar>
	  </ContentWithSidebarPage>
	   
	  <div style={{display: 'flex',  justifyContent:'right', alignItems:'right', height: '100vh'}}>
		  </div>
	  
	  <FlexboxRows  style={{height: "50px", width: "100%"}}>
	      <Accordion title="Pending">
		<h3> {stagedForExecution.value.map((item) => (<> <span>{item}</span> <hr /> </>),)} </h3>	
	  	</Accordion> 
              <Accordion title="Staged">
	         <h3>{streamValues.value.map((item) => (<> <span>{item}</span> <hr /> </>),)} </h3>
	      </Accordion>
	      <Accordion title="Running">
		 <h3> {executionRunning.value.map((item) => (<> <span>{item}</span> <hr /> </>),)}</h3>
	      </Accordion>
	      <Accordion title="Failed">
	       	 <h3>{executionFailure.value.map((item) => (<> <span>{item}</span> <hr /> </>),)} </h3>
	      </Accordion>
	      <Accordion title="Complete">
	         <h3>{executionSuccess.value.map((item) => (<> <span>{item}</span> <hr /> </>),)} </h3>
	      </Accordion>
	  </FlexboxRows>
	  </div> 
	  </>
  );
}
