import { useEffect } from "react";

function getStorageValue(key, defaultValue) {
    // getting stored value
    const saved = localStorage.getItem(key);
    const initial = JSON.parse(saved);
    return initial || defaultValue;
  }

function useRecentFeednames() {
    let recentFeeds = getStorageValue("recent_feeds", [])
    
    function addRecentFeed(stream_name) {
        if (stream_name){ //If you pass in a stream name, we'll only store the feed name
            const feed_name = stream_name.split('-')[0]
            recentFeeds.push(feed_name)
        }
        if (recentFeeds.length > 100){
            recentFeeds = recentFeeds.slice(-100)
        }
        var unique = recentFeeds.filter((v, i, a) => a.indexOf(v) === i); //Dedupe the array
        localStorage.setItem("recent_feeds", JSON.stringify(unique));
    }

    return [recentFeeds, addRecentFeed];
}

export default useRecentFeednames;