//https://overreacted.io/making-setinterval-declarative-with-react-hooks/
import React, { useState, useEffect, useRef, useCallback } from 'react';

export function useInterval(callback, delay) {
  const savedCallback = useRef();
  const timeout = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      timeout.current = setInterval(tick, delay);
      return () => clearInterval(timeout.current);
    }
  }, [delay]);

  // the clear method
  const clear = useCallback(() => {
    if (timeout.current) {
      clearInterval(timeout.current)
    }
  }, [])

  return clear

}