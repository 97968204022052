import React from "react";
import styled from "styled-components";
import { DefaultEffects } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBoolean } from "react-hanger";

//Components
import StatusIndicator from "./StatusIndicator";

const StyledBubble = styled.div`
  width: 100%;
  background-color: White;
  box-shadow: ${(props) => (props.active ? DefaultEffects.elevation8 : null)};
  margin-bottom: ${(props) => (props.active ? "5px" : "1px")};
  margin-top: ${(props) => (props.active ? "5px" : "1px")};
  border-radius: 20px;
  padding: 0 10px 0 10px;
  box-sizing: border-box;

  :hover {
    box-shadow: ${DefaultEffects.elevation16};
  }

  .Header {
    color: ${(props) => (props.enabled ? "Black" : "LightGray")};
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    padding: 0.75rem;
    min-height: 1rem;
    cursor: pointer;
  }

  .Label {
    margin-right: 1rem;
  }

  .Content {
    min-height: 20rem;
  }

  .group {
    display: flex;
    align-items: center;

  }
`;



export const StyledBubbleCodeBlock = styled.div`
  code {
  }
`;

export const BubbleGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;



function Bubble(props) {
  const active = useBoolean(props.active || false);

  const enabled = props.data.enabled || props.enabled;
  const status = props.data.status || props.status;

  return (
    <StyledBubble
      active={active.value}
      enabled={props.data.enabled || props.data.enabled === undefined}
    >
      <div
        className="Header"
        onClick={() => {
          active.toggle();
          if (props.headerClick) {
            props.headerClick();
          }
        }}
      >
        <div className="Section">
          {props.headerKeys.map((key, index) => (
            <span key={index} className="Label">
              {props.data[key]}
            </span>
          ))}
        </div>
        <div className="group">
          {status && enabled ? <StatusIndicator status={status} /> : null}
          {props.rerunSelf ? (
            <div onClick={props.rerunSelf}>
              <FontAwesomeIcon
                icon={"undo"}
                size="lg"
                style={{ cursor: "pointer" }}
              />
            </div>
          ) : null}
          {props.deleteSelf ? (
            <div onClick={props.deleteSelf}>
              <FontAwesomeIcon
                icon={"times"}
                size="lg"
                style={{ cursor: "pointer" }}
              />
            </div>
          ) : null}
          {props.rerun ? (
            <div onClick={props.rerun}>
              <FontAwesomeIcon
                icon={"undo"}
                size="lg"
                style={{ cursor: "pointer" }}
              />
            </div>
          ) : null}
        </div>
      </div>
      {active.value ? <div className="Content">{props.children}</div> : null}
    </StyledBubble>
  );
}

export function BubbleCodeBlock(props) {
  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function isStreamConfig(str) {
    const parsed_blob = JSON.parse(str);
    if (parsed_blob.hasOwnProperty("parents")) {
      return true;
    }
    return false;
  }

  function isPythonTraceback(str) {
    return false;
    //  return str.includes("Traceback (most recent call last):")
  }

  var whiteSpace = "pre";
  var blob = props.children;
  console.log(blob);
  if (isJsonString(props.children)) {
    if (isStreamConfig(props.children)) {
      console.log("Fun fact, this is a stream config");
      // We don't do anything with this information, but
      // we might someday
    }
  } else if (isPythonTraceback(props.children)) {
    console.log("Fun fact, this is a Python Traceback");
  }
  return (
    <StyledBubbleCodeBlock>
      <code style={{ whiteSpace: whiteSpace }}>{blob}</code>
    </StyledBubbleCodeBlock>
  );
}

export default Bubble;
