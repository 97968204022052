import React from "react";
import styled from "styled-components";

import { DefaultEffects } from "@fluentui/react";

const SidebarContainer = styled.div`
  font-size: 1.5em;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: White;
  box-shadow: ${DefaultEffects.elevation8};

  #sidebarContent {
    margin: 25px;
    display: flex;
    flex-direction: inherit;
    flex-grow: 1;
  }

  #sidebarFooter{
    margin-top: auto;
  }

  button {
    width: 100%;
  }

`;

const SideBarGroupStyles = styled.div`
  h3 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .MuiTypography-body1 {
    font-weight: inherit;
  }
`;
export function SidebarGroup(props) {
  return (
    <SideBarGroupStyles>
      <h3>{props.name}</h3>
      {props.children}
    </SideBarGroupStyles>
  );
}

export function Sidebar(props) {
  return (
    <SidebarContainer>
      <div id="sidebarContent">{props.children}</div>
    </SidebarContainer>
  );
}
