import React, { useState } from 'react';
import styled from 'styled-components';
import { DefaultEffects } from '@fluentui/react';
import { API } from 'aws-amplify';

//Components
import { ColumnFlexPage } from '../components/Page'
import { Sidebar, SidebarGroup } from '../components/Sidebar'
import { StreamSelector } from '../components/StreamSelector'
import { Button, StyledInput } from '../components/BasicControls'
import { StatusBadge } from '../components/StatusBadge'


const return_blob = {
  "readiness_report": [
    [{
        "name": "tnf2020foxsportsplintp2-iad-1",
        "elemental_version": "2.17.9.3020827",
        "service_endpoint": "http://mx1-haw-e059.enc.amazonavoc.com/",
        "video_codec": "H264",
        "gop_size": "120.0",
        "framerate_numerator": "60",
        "highest_video_resolution": 720,
        "udp_output_configured": true,
        "dagon_compare_status": "bad",
        "caption_language_code": "eng",
        "audio_stream_info": [{
            "audio_normalization": true,
            "audio_language": "eng",
            "audio_language_description": "English US",
            "audio_sample_rate": "32000",
            "audio_source_name": "Audio Selector 1",
            "audio_codec": "aac"
          },
          {
            "audio_normalization": true,
            "audio_language": "aua",
            "audio_language_description": "Storm - Kremer",
            "audio_sample_rate": "32000",
            "audio_source_name": "Audio Selector 2",
            "audio_codec": "aac"
          },
          {
            "audio_normalization": true,
            "audio_language": "aub",
            "audio_language_description": "Scout's Feed",
            "audio_sample_rate": "32000",
            "audio_source_name": "Audio Selector 3",
            "audio_codec": "aac"
          }
        ],
        "input_format": "1280x720@59.940",
        "prime_bug_path": null,
        "alert_count": "0",
        "pdt_drift_in_seconds": 0,
        "chrony_sync": "good",
        "encoder_image_files": "Make sure 'mx1-haw-e059.enc.amazonavoc.com' is accepted on saltmaster."
      },
      {
        "name": "tnf2020foxsportsplintp2-iad-2",
        "elemental_version": "2.17.9.3020827",
        "service_endpoint": "http://edm-sta-e024.enc.amazonavoc.com/",
        "video_codec": "H264",
        "gop_size": "120.0",
        "framerate_numerator": "60",
        "highest_video_resolution": 720,
        "udp_output_configured": true,
        "dagon_compare_status": "bad",
        "caption_language_code": "eng",
        "audio_stream_info": [{
            "audio_normalization": true,
            "audio_language": "eng",
            "audio_language_description": "English US",
            "audio_sample_rate": "32000",
            "audio_source_name": "Audio Selector 1",
            "audio_codec": "aac"
          },
          {
            "audio_normalization": true,
            "audio_language": "aua",
            "audio_language_description": "Storm - Kremer",
            "audio_sample_rate": "32000",
            "audio_source_name": "Audio Selector 2",
            "audio_codec": "aac"
          },
          {
            "audio_normalization": true,
            "audio_language": "aub",
            "audio_language_description": "Scout's Feed",
            "audio_sample_rate": "32000",
            "audio_source_name": "Audio Selector 3",
            "audio_codec": "aac"
          }
        ],
        "input_format": "1280x720@59.940",
        "prime_bug_path": null,
        "alert_count": "0",
        "pdt_drift_in_seconds": 0,
        "chrony_sync": "good",
        "encoder_image_files": "Make sure 'edm-sta-e024.enc.amazonavoc.com' is accepted on saltmaster."
      },
      {
        "name": "tnf2020foxsportsplintp2-pdx-1",
        "elemental_version": "2.17.9.3020827",
        "service_endpoint": "http://mx1-haw-e063.enc.amazonavoc.com/",
        "video_codec": "H264",
        "gop_size": "120.0",
        "framerate_numerator": "60",
        "highest_video_resolution": 720,
        "udp_output_configured": true,
        "dagon_compare_status": "good",
        "caption_language_code": "eng",
        "audio_stream_info": [{
            "audio_normalization": true,
            "audio_language": "eng",
            "audio_language_description": "English US",
            "audio_sample_rate": "32000",
            "audio_source_name": "Audio Selector 1",
            "audio_codec": "aac"
          },
          {
            "audio_normalization": true,
            "audio_language": "aua",
            "audio_language_description": "Storm - Kremer",
            "audio_sample_rate": "32000",
            "audio_source_name": "Audio Selector 2",
            "audio_codec": "aac"
          },
          {
            "audio_normalization": true,
            "audio_language": "aub",
            "audio_language_description": "Scout's Feed",
            "audio_sample_rate": "32000",
            "audio_source_name": "Audio Selector 3",
            "audio_codec": "aac"
          }
        ],
        "input_format": "1280x720@59.940",
        "prime_bug_path": null,
        "alert_count": "0",
        "pdt_drift_in_seconds": 0,
        "chrony_sync": "good",
        "encoder_image_files": "Make sure 'mx1-haw-e063.enc.amazonavoc.com' is accepted on saltmaster."
      },
      {
        "name": "tnf2020foxsportsplintp2-pdx-2",
        "elemental_version": "2.17.9.3020827",
        "service_endpoint": "http://edm-sta-e025.enc.amazonavoc.com/",
        "video_codec": "H264",
        "gop_size": "120.0",
        "framerate_numerator": "60",
        "highest_video_resolution": 720,
        "udp_output_configured": true,
        "dagon_compare_status": "good",
        "caption_language_code": "eng",
        "audio_stream_info": [{
            "audio_normalization": true,
            "audio_language": "eng",
            "audio_language_description": "English US",
            "audio_sample_rate": "32000",
            "audio_source_name": "Audio Selector 1",
            "audio_codec": "aac"
          },
          {
            "audio_normalization": true,
            "audio_language": "aua",
            "audio_language_description": "Storm - Kremer",
            "audio_sample_rate": "32000",
            "audio_source_name": "Audio Selector 2",
            "audio_codec": "aac"
          },
          {
            "audio_normalization": true,
            "audio_language": "aub",
            "audio_language_description": "Scout's Feed",
            "audio_sample_rate": "32000",
            "audio_source_name": "Audio Selector 3",
            "audio_codec": "aac"
          }
        ],
        "input_format": "1280x720@59.940",
        "prime_bug_path": null,
        "alert_count": "0",
        "pdt_drift_in_seconds": 0,
        "chrony_sync": "good",
        "encoder_image_files": "Make sure 'edm-sta-e025.enc.amazonavoc.com' is accepted on saltmaster."
      }
    ]
  ]
}


const LessFuglyTable = styled.table `
  background-color: white;
  border-collapse: collapse;
  padding: 1rem;
  
  td th{
    border-bottom: 1px solid lightgray;
    padding: 16px;
  }
`



export default function Scantron() {

  const [streamName, setStreamName] = useState("")
  const [reports, setReports] = useState([])
  const [errorText, setErrorText] = useState("")
  const [status, setStatus] = useState("Ready")
  //const reports = return_blob.readiness_report[0]

  function stringifyObject(thing) {
    if (thing instanceof String || thing instanceof Number) {
      return thing
    }
    else {
      return JSON.stringify(thing);
    }
  }

  function copytable(id) {
    var urlField = document.getElementById(id)
    var range = document.createRange()
    var selection = window.getSelection()
    range.selectNode(urlField)
    selection.removeAllRanges(); // Remove all ranges from the selection.
    selection.addRange(range); // Add the new range.
    document.execCommand('copy')
  }

  async function runReport() {
    setStatus("Running")
   const returnBlob = await API.get(
        process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
        'scantron/read', {
          headers: {
            'Cache-Control': 'max-age=87000',
            'Content-type': 'application/json',
          },
          queryStringParameters: {
            action: 'get_scantron_data',
            stream_name: streamName
          }
        })
      .then(response => {
        console.log(response)

        if (response['readiness_report'].length > 0) {
          setReports(response['readiness_report'])
          setStatus("Success")
        }
        else {
	  setStatus("Error")
	  setErrorText(returnBlob)
          return response
        }
      })
      .catch(error => {
        console.log(error)
	setStatus("Error")
        if (error.response != undefined) {
          if (error.response.data != undefined) {
            return error.response.data
          }
          return error.response
        }
        else {
          return "Error Attempting to Fetch Results"
        }
      });
   setErrorText(returnBlob)
  }

  return (
    <ColumnFlexPage style={{paddingLeft:"30px", paddingRight:"50px", paddingTop:"10px", boxSizing:"border-box", width:"100vw", backgroundColor:"white"}}>
          <div name="Header" style={{display:"flex", justifyContent:"space-between"}}>
            <h1>Readiness Report Card</h1>
            <StatusBadge status={status}/>
          </div>
          
          <div name="Data Entry" style={{display:"grid", gridTemplateColumns: "1fr repeat(2, fit-content(30rem))", columnGap:"10px",marginBottom:"25px"}}>
              <StreamSelector placeholder="Stream Name" onChange={(e) => setStreamName(e.target.value)}/>
              <Button style={{width:"10rem", borderRadius:"2px"}} onClick={() => runReport()} >Scan the Tron</Button>
              <Button style={{width:"10rem", borderRadius:"2px"}} onClick={() => copytable("report")}>Copy to Clipboard</Button>
          </div>
          { reports.length > 0 ?
          <div style={{overflowX:"scroll"}}>
            <LessFuglyTable id="report">
              <thead>
                <tr>
                  <th>Data Key</th> 
                  {
                  reports.map((stream, index) => (
                    <th key={'header'+index}>Stream {index+1}</th>
                  ))
                } 
                </tr>
              </thead>
              <tbody>
                        {
                Object.keys(reports[0]).map((key) => (
                <tr>
                    <td>{key}</td>
                    {reports.map((_, index) => (
                    <td>{stringifyObject(reports[index][key])}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </LessFuglyTable>
          </div>
           :
            <pre>{errorText}</pre>
          }
        </ColumnFlexPage>
  );
}
