import React, { createContext, useReducer } from 'react';

const hwConextInitialState = {
    tableRowData : [],
    hwCurrentUser : null,
    dbDataReceived : false,
    tableAlertMsg: null,
    tableAlertFlag: false,
}

const hwReducer = (hwState, hwAction) => {
    switch (hwAction.type) {

        case 'SET_MUI_TABLE_ALERT':
            return {
                ...hwState,
                tableAlertMsg: hwAction.tableAlertMsg,
                tableAlertFlag: hwAction.tableAlertFlag,
            };

        case 'SET_HW_TABLE_ROW_DATA':
            return {
                ...hwState,
                tableRowData: hwAction.tableRowData
            };

        case 'SET_HW_CURRENTUSER':
            return {
                ...hwState,
                hwCurrentUser: hwAction.hwCurrentUser
            }

        case 'SET_DB_DATA_RECEIVED':
            return {
                ...hwState,
                dbDataReceived: hwAction.dbDataReceived
            }

        default: return hwState;
    }
}

const HwContext = createContext(null);

const HwContextProvider = ({children}) => {
    const hwContextValue = useReducer(hwReducer, hwConextInitialState );

    return(
        <HwContext.Provider value={hwContextValue}>
            {children}
        </HwContext.Provider>
    )
}

export {
    HwContext,
    HwContextProvider,
}