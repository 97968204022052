//Third Party Imports
import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

//Amplify
import { Auth } from "aws-amplify";

//Components
import { Button } from "./components/BasicControls";
import NavBar from "./components/Navbar";

//Contexts
import { AuthContext } from "./contexts/AuthProvider.js";
import { HwContextProvider } from "./contexts/HwContextProvider";
import { DxContextProvider } from "./contexts/DxContextProvider";

//Pages
import ConfigViewer from "./pages/ConfigViewer";
import StepFunctions from "./pages/StepFunctions";
import Beaker from "./pages/Beaker";
import Slate from "./pages/Slate";
import Scantron from "./pages/Scantron";
import RbacTestPage from "./pages/RbacTest";
import HwConfigSelector from "./pages/HwConfig";
import DxConfigSelector from "./pages/DxConfig";
import Redactor from "./pages/Redactor";
import Review from "./pages/Review";
import FeedOnboardingForm from "./pages/FeedOnboardingForm";
import OnboardStreams from "./pages/OnboardStreams";
import TagConfig from "./pages/TagConfig";
import ProfilePage from "./pages/Profile";
// import Nexium from "./pages/Nexium";
import EditMultipleConfigs from "./pages/EditMultipleConfigs";
import Nuke from "./pages/Nuke";

library.add(fas);

const AppContainer = styled.div`
  display: grid;
  grid-template-rows: 3rem 1fr;
  height: 100vh;
  width: 100vw;
`;

function Dante() {
  const userObject = useContext(AuthContext);

  useEffect(() => {
    //Every N seconds, reload the user's auth credentials
    const refreshDelaySeconds = 120;
    setInterval(() => {
      Auth.currentAuthenticatedUser({ bypassCache: true }).then((user) => {
        console.log("Current User", user);
      });
    }, refreshDelaySeconds * 1000);
  }, []);

  //Return a loading screen while we check for credentials
  if (userObject.isLoading) {
    return (
      <Slate>
        <h1>Loading...</h1>
      </Slate>
    );
  } else if (userObject.isAuthenticated === false) {
    return (
      <Slate>
        <h3>Please Sign In</h3>
        <Button onClick={() => Auth.federatedSignIn()}>Sign In</Button>
      </Slate>
    );
  }

  return (
    <AppContainer>
      <Router>
        <NavBar />
        <Switch>
          <Route path="/login">
            <Slate>
              <h3>Please Sign In</h3>
              <Button onClick={() => Auth.federatedSignIn()}>Sign In</Button>
            </Slate>
          </Route>
          <Route path="/confirmation">
            <Slate>
              <h3>This is the development version of Dante</h3>
              <Button
                onClick={() => window.open("https://dante.amazonavoc.com", "_blank", 'noopener, noreferrer')}
              >
                <h2>Take me to Prod </h2>
              </Button>
              <a href="/">I understand that this action is unsafe</a>
            </Slate>
          </Route>
          <Route path="/stepfunctions">
            <StepFunctions />
          </Route>
          <Route path="/pipeline_analysis">
            <Beaker />
          </Route>
          <Route path="/readiness_report">
            <Scantron />
          </Route>
          <Route path="/rbac">
            <RbacTestPage />
          </Route>
          <Route path="/hwconfig">
            <HwContextProvider>
              <HwConfigSelector />
            </HwContextProvider>
          </Route>
          <Route path="/dxconfig">
            <DxContextProvider>
              <DxConfigSelector />
            </DxContextProvider>
          </Route>
          <Route path="/redactor">
            <Redactor />
          </Route>
          <Route path="/review">
            <Review />
          </Route>
          <Route path="/editMultipleConfigs">
            <EditMultipleConfigs />
          </Route>
          <Route path="/feedOnboardingForm">
            <FeedOnboardingForm />
          </Route>
          <Route path="/onboardStreams">
            <OnboardStreams />
          </Route>
          <Route path="/tagConfig">
            <TagConfig />
          </Route>
          <Route path="/profile">
            <ProfilePage />
          </Route>
          <Route path="/nuke">
            <Nuke />
          </Route>
          <Route path="/">
            {/* Make sure that path / is last */}
            <ConfigViewer />
          </Route>
        </Switch>
      </Router>
    </AppContainer>
  );
}

export default Dante;
