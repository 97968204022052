import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import ReactJson from 'react-json-view';
import { Link } from "react-router-dom";
import { Card, Container, Col, Row, Modal } from 'react-bootstrap';
import * as XLSX from 'xlsx';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { API, Auth } from 'aws-amplify';
import { AuthContext } from '../contexts/AuthProvider.js';

import Bubble, { BubbleGroup } from '../components/Bubble';
import { Button } from '../components/BasicControls';
import { TwoColumnGridPage } from '../components/Page';

const StyledFormHeader = styled.h5 `
  justify-content: center;
  align-items: center;
  margin: 0 auto; 
  text-align: center;
  font-size: 1.8vw;
  font-weight: bold;
  letter-spacing: 0.2rem;
  margin-bottom: 12px;
  color: #e47911; /* Amazon Orange */
`;

const StyledEntryCard = styled(Card)`
  background-color: white;
  padding: 0.5%;
  font-size: 1.0vw;
  margin-left: 0.5rem;
  border: 0.5px solid white;
  border-radius: 0.5rem;
  width: 100%;
`;

function EditMultipleConfigs(){
    const [streams, setStreams] = useState([{ name: 'Feed Onboarding Form', command: 'test', status: 'Ready', data: "Json preview here", active: true }]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [action, setAction] = useState("");
    // const [tenant, setTenant] = useState("");
    // const [show, setShow] = useState(false);
    const {user} = useContext(AuthContext);
    const [streamData, setStreamData] = useState([]);
    // const [preview, setPreview] = useState({data: [], cols: []})
    let userName = JSON.parse(user.attributes.identities)[0].userId;
    
    const source_types = [
        "SDI",
        "IP Multicast",
        "File",
        "Add DR Parameters",
        "Add Secondary Outputs",
        ];
    
    function bubbleClickHandler(i) {
        const originalValue = streams[i].active;
        setStreams(streams => {
            let newStreams = streams.slice();
            newStreams.map((value, index) => {
                newStreams[index].active = false;
            });
            newStreams[i].active = !originalValue;
            setActiveIndex(i);
            return newStreams;
        });
    }

    function handleSubmit(e){
        console.log("testing handle submit");
        console.log(e);
        processValues(e);
    }
    
    const handleFile = (file) => {
        console.log("uploaded file");
        let filereader = new FileReader();
        filereader.onload = (event) => {
            let data = event.target.result;
            const wb = XLSX.read(data, {type: "binary"});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const formattedData = XLSX.utils.sheet_to_json(ws, {header:1});
            setStreamData(formattedData);
            console.log(streamData);
        };
        filereader.readAsBinaryString(file);
    };
    
    function processValues(values){
        console.log(values);
        console.log(values.source_type);
        let path = "redactor/write";
        console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
        console.log(streamData);
        API.post(
            process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
            path, {
                response: true,
                body: {
                    action: "edit_multiple_configs",
                    entity: values.source_type,
                    feed: values,
                    stream: streamData
                }
            })
        .then(response => {
            console.log(response.data);
            setActiveBubble({
                data: JSON.stringify(response.data, null, 2),
                status: "Success"
            });
        })
        .catch(error => {
            console.log(error.response);
            setActiveBubble({
                data: error.response.data,
                status: "Failure"
            });
        });
    }

    function setActiveBubble(newValues) {
        setStreams(streams => {
            let newStreams = streams.slice();
            Object.keys(newValues).map(function(key, index) {
                newStreams[activeIndex][key] = newValues[key];
                console.log(newStreams[activeIndex][key]);
            });
            console.log(newStreams);
            return newStreams;
        });
    }

    function getJson(data){
        if (data === "Json preview here"){
            return {"value": data};
        }
        else if (data.includes("Traceback")){
            return {"Error": data};
        }
        else {
            return JSON.parse(data);
        }
    }

    function bubblePut(path, old_json, new_json, stream_name) {
        setActiveBubble({
            status: "Running"
        });
        console.log(old_json);
        console.log(new_json);
        API.put(
            process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
            path, {
                response: true,
                body: {
                    original_json: old_json,
                    new_json: new_json,
                    author: userName,
                    cr_description: "Add CR details here",
                    stream_names: stream_name
                }
            })
        .then(response => {
            setAction("create_cr");
            setActiveBubble({
                data: JSON.stringify(response.data),
                status: "Success"
            });
            console.log(response);
            console.log(response.data);
        })
        .catch(error => {
            console.log(error);
            console.log(error.response);
            setActiveBubble({
                data: error.response.data,
                status: "Failure"
            });
        });
    }
    
    function formatURL(url){
        console.log(url);
        let formatted_url = url.replace(/"/g,"");
        return formatted_url;
    }
    
    return (
        <TwoColumnGridPage>
            <BubbleGroup id="bubbleGroup">
                <Formik
                    validateOnMount={true}
                    initialValues={{ source_type: '', file:'' }}
                    onSubmit={handleSubmit}
                    enableReinitialize={false}
                    validateOnChange={true}
                    >
                    {(props) => (
                        <StyledEntryCard>
                        <Container>
                        <Button onClick={() => window.open('https://w.amazon.com/bin/view/Amazon_Video/PV_Playback/LiveOps/Infrastructure/reference/FeedFormation/RedactorMultipleConfigChanges/')}>Help</Button>
                        <StyledFormHeader>Multiple Config Changes</StyledFormHeader>
                        <Form autoComplete="off">
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="source_type" style={{marginRight: "46px"}}><strong>Source Type:</strong></label>
                                    <Field 
                                      name="source_type"
                                      as="select" 
                                      className={'form-control' + (props.errors.source_type && props.touched.source_type ? ' is-invalid' : '')}
                                    >
                                    <option value=''>Choose...</option>
                                    {source_types.map((op, idx) => (
                                        <option key={`source_type-idx-${op}`}   id={`idx-${op}`} >{op}</option>
                                    ))}
                                    </Field>
                                    <ErrorMessage name="source_type" component="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <label htmlFor="file" style={{marginRight: "38px"}}><strong>List of feeds:</strong> </label>
                                    <input
                                        id="file"
                                        name="file" 
                                        type="file" 
                                        accept = ".xlsx"
                                        onChange={(e) => {handleFile(e.currentTarget.files[0])}}
                                        />
                                </Col>
                            </Row>
                            <Row style={{marginBottom: "15px"}}>
                                <Col>
                                    <Button style= {{bottom: "10px", padding: "1rem"}} type="submit" variant="outline-info" >Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                        </Container>
                    </StyledEntryCard>)}
                </Formik>
            </BubbleGroup>
            <BubbleGroup id="bubbleGroup" style={{marginBottom:"10px", borderRadius:"10px"}}>
            {streams.map((stream, index) => (
                <Bubble key={index} data={stream} headerKeys={[]} headerClick={() => bubbleClickHandler(index)}>
                { action === "create_cr" ?
                    <Link to={`/review/${formatURL(stream.data)}`}>{formatURL(stream.data)}</Link>
                    :
                    <ReactJson src={getJson(stream.data)}
                        name="config"
                        collapsed={2}
                        onEdit={false}
                        onAdd={false}
                        onDelete={false}
                        enableClipboard={true}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        displayArrayKey={false}
                        theme="bright-inverted"
                    />
                }
                <Button style= {{top: "90px", right: "40px", padding: "1rem", position: "fixed"}}
                onClick={() => bubblePut("redactor/cr", {}, stream.data, "Multiple Config Changes")}>Create CR
                </Button>
                </Bubble>
            ))}
            </BubbleGroup>
        </TwoColumnGridPage>
        );
    }
export default EditMultipleConfigs;