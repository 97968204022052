import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import ReactJson from 'react-json-view';
import Select from "react-select";

import { API, Auth } from 'aws-amplify';
import { AuthContext } from '../contexts/AuthProvider.js';
import { MemberRestrictedSection } from '../components/RoleBasedAccessControl';

import { Sidebar, SidebarGroup } from '../components/Sidebar';
import Bubble, { BubbleGroup } from '../components/Bubble';
import { Button } from '../components/BasicControls';
import { StreamSelector } from '../components/StreamSelector';
import { ContentWithSidebarPage } from '../components/Page';
import { Link } from "react-router-dom";

const StyledTable = styled.div `{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
  
table {
  border: 2px solid black;
  width: 1000px;
  height: 200px;
}
  
th {
  border-bottom: 1px solid black;
  text-align: left;
}
  
td {
  text-align: left;
}`;

  function Redactor(props) {
    const [streams, setStreams] = useState([{ name: '', command: '', status: 'Ready', data: "Edit Config here", active: true }]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [action, setAction] = useState("");
    let newJson = "";
    let crUrl = "";
    const {user} = useContext(AuthContext);
    let userName = JSON.parse(user.attributes.identities)[0].userId;
    const actionOptions = [
        { label: 'View Group Properties', value: 'View Group Properties' },
        { label: 'View Packager Status', value: 'View Packager Status' },
        { label: 'Onboard Group', value: 'Onboard Group' },
        { label: 'Onboard Contribution Streams', value: 'Onboard Contribution Streams' },
    ];
    const entityOptions = [
        { label: 'Group', value: 'Group' },
        { label: 'Contribution Stream', value: 'CS' },
        { label: 'Stream', value: 'Stream' },
    ];
    const [group, setGroup] = useState('Select Group');
    const [cS, setCS] = useState('Select CS Name');
    const [entity, setEntity] = useState('Select Entity');
    const [streamName, setStreamName] = useState('Select Stream Name');
    const [groups, setGroups] = useState([]);
    const [name, setName] = useState("");
    const [groupStatus, setGroupStatus] = useState([]);
    const [cSNames, setCSNames] = useState([]);
    const [stream_names, setStream_names] = useState([]);
    const [streamStatus, setStreamStatus] = useState([{}]);
    const [cSStatus, setCSStatus] = useState([{}]);
    const [ticketID, setTicketID] = useState("Enter Ticket ID");
    const [crData, setCrData] = useState({});
    
    useEffect(() => {
        getGroupList();
    }, []);
    
    useEffect(() => {
      if (action === "View Packager Status" && group !== "Select Group" && entity === "Stream"){
          getStreamsInGroup();
      }
    }, [group,entity]);
    
    useEffect(() => {
      if (action === "View Packager Status" && group !== "Select Group" && entity === "CS"){
        getCSInGroup();
      }
    },[group, entity]);
    
    useEffect(() => {
        if (action === "View Packager Status" && group !== "Select Group" && entity === "Stream"){
            getStreamsinCS();
        }
    }, [cS]);
    
    const handleAction = (event) => {
        console.log(event);
        setAction(event);
        setEntity('Select Entity');
        setGroup('Select Group');
        setCS('Select CS Name');
        setStreamName('Select Stream Name');
    };
    
    const handleGroup = (event) => {
        console.log("Group:",event);
        setGroup(event);
        console.log(group);
    };
    
    const handleEntity = (event) => {
        console.log("Entity:",event);
        setEntity(event);
    };
    const handleCS = (event) => {
        console.log("CS:",event);
        setCS(event);
    };
    const handleStream = (event) => {
        console.log("StreamName:",event);
        setStreamName(event);
    };
    
    function bubbleClickHandler(i) {
      const originalValue = streams[i].active;
      setStreams(streams => {
        let newStreams = streams.slice();
        newStreams.map((value, index) => {
          newStreams[index].active = false;
        });
        newStreams[i].active = !originalValue;
        setActiveIndex(i);
        return newStreams;
      });
    }
    
    function bubbleGet(path, action) {
      setActiveBubble({
        status: "Running",
        command: action
      });
      API.get(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            headers: {
              'Cache-Control': 'max-age=87000',
              'Content-type': 'application/json',
            },
            queryStringParameters: {
              action: action,
              stream_name: streams[activeIndex]["name"],
              data: streams[activeIndex]["data"]
            }
          })
        .then(response => {
          setActiveBubble({
            data: JSON.stringify(response, null, 2),
            status: "Success"
          });
          console.log(response);
        })
        .catch(error => {
          console.log(error);
          console.log(error.response);
          setActiveBubble({
            data: error.response,
            status: "Failure"
          });
        });
    }

    function getTagConfig(path,action){
      setActiveBubble({
        status: "Running",
        command: action
      });
      console.log(streams);
      API.get(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            queryStringParameters: {
              action: action,
              property_id: streams[activeIndex]["name"]
            }
          })
        .then(response => {
          setActiveBubble({
            data: JSON.stringify(response, null, 2),
            status: "Success"
          });
          console.log(response);
        })
        .catch(error => {
          console.log(error);
          console.log(error.response);
          setActiveBubble({
            data: error.response,
            status: "Failure"
          })
        });
    }

    function bubblePut(path, old_json, new_json, stream_name) {
      setActiveBubble({
        status: "Running"
      });
      console.log(old_json);
      console.log(new_json);
      if (new_json.onboard_property_payload){
        createGroupCR(path, old_json, new_json, new_json.onboard_property_payload.group_name);
      }
      else if (new_json.includes("property_id")){
        bubbleTag(path, old_json, new_json, stream_name);
      }
      else if (new_json.includes("group_id")){
        bubbleStream(path, old_json, new_json, JSON.parse(new_json).group_id);
      }
      else{
        bubbleStream(path, old_json, new_json, stream_name)
      }
    }
      
    function bubbleStream(path, old_json, new_json, stream_name) {
      // setActiveBubble({
      //   status: "Running"
      // });
      console.log(old_json);
      console.log(stream_name);
      console.log(new_json);
      API.put(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            response: true,
            body: {
              original_json: old_json,
              new_json: new_json,
              author: userName,
              cr_description: "Add CR details here",
              stream_names: stream_name
            }
          })
        .then(response => {
          setAction("create_cr");
          setActiveBubble({
            data: JSON.stringify(response.data),
            status: "Success"
          });
          console.log(response);
          console.log(response.data);
          crUrl = response.data;
        })
        .catch(error => {
          console.log(error);
          console.log(error.response);
          setActiveBubble({
            data: error.response.data,
            status: "Failure"
          });
        });
    }

    function bubbleTag(path, old_json, new_json, stream_name) {
      setActiveBubble({
        status: "Running"
      });
      console.log(old_json);
      console.log(new_json);
      API.put(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            response: true,
            body: {
              original_json: old_json,
              new_json: new_json,
              author: userName,
              cr_description: "Add CR details here",
              propertyId: stream_name
            }
          })
        .then(response => {
          setAction("create_cr");
          setActiveBubble({
            data: JSON.stringify(response.data),
            status: "Success"
          });
          console.log(response);
          console.log(response.data);
          crUrl = response.data;
        })
        .catch(error => {
          console.log(error);
          console.log(error.response);
          setActiveBubble({
            data: error.response.data,
            status: "Failure"
          });
        });
    }

    function createGroupCR(path, old_json, new_json, stream_name) {
      console.log(old_json);
      console.log(stream_name);
      console.log(new_json);
      API.put(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            response: true,
            body: {
              original_json: old_json,
              new_json: new_json,
              author: userName,
              cr_description: "Add CR details here",
              stream_names: stream_name
            }
          })
        .then(response => {
          setAction("create_cr");
          setActiveBubble({
            data: JSON.stringify(response.data),
            status: "Success"
          });
          console.log(response);
          console.log(response.data);
          crUrl = response.data;
        })
        .catch(error => {
          console.log(error);
          console.log(error.response);
          setActiveBubble({
            data: error.response.data,
            status: "Failure"
          });
        });
    }


    function setActiveBubble(newValues) {
      setStreams(streams => {
        let newStreams = streams.slice();
        Object.keys(newValues).map(function(key, index) {
          newStreams[activeIndex][key] = newValues[key];
          console.log(newStreams[activeIndex][key]);
        });
        console.log(newStreams);
        return newStreams;
      });
    }


    function handleSearch(event) {
      setActiveBubble({ name: event.target.value });
    }


    function makeEnterBlur(event) {
      if (event.keyCode === 13) {
        event.target.blur();
      }
    }


    function onEdit(event){
      console.log(event);
      newJson=JSON.stringify(event.updated_src);
      console.log(newJson);
      return true;
    }


    function onAdd(event){
      console.log(event);
      newJson=JSON.stringify(event.updated_src);
      console.log(newJson);
      return true;
    }


    function onDelete(event){
      console.log(event);
      newJson=JSON.stringify(event.updated_src);
      console.log(newJson);
      return true;
    }


    function getJson(config, status){
      console.log(status);
      console.log(config);
      if (config === "Edit Config here"){
        return {"value": config};
      }
      else if (config.includes("Traceback")){
        return {"Error": config};
      }
      else{
        if(status === "Success"){
          console.log(JSON.parse(config))
          return JSON.parse(config);
        }
        else {
          return {"Error": config.data};
        }
      }
    }


    function formatURL(url){
      console.log(url);
      let formatted_url = url.replace(/"/g,"");
      return formatted_url;
    }

    function getGroupList(){
    let path = "nexium/list_groups";
        console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
        API.get(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            headers: {
              'Cache-Control': 'max-age=87000',
              'Content-type': 'application/json',
            },
            queryStringParameters: {
              environment: "PROD"
            }
        })
        .then(response => {
          console.log(response);
          let groups_list = [];
          for (var i = 0; i < response.groupDetails.length; ++i) groups_list[i] = {label:response.groupDetails[i]["name"], value:response.groupDetails[i]["groupId"]};
          console.log(response);
          setGroups(groups_list);
        })
        .catch(error => {
          console.log(error.response);
        });
    }

    function getGroupProperties(){
        let path = "nexium/get_group_property";
        console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
        API.get(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            headers: {
              'Cache-Control': 'max-age=87000',
              'Content-type': 'application/json',
            },
            queryStringParameters: {
              action: "get_group_property",
              group_id: group
            }
        })
        .then(response => {
          console.log(response);
          setAction("View Properties")
          setActiveBubble({
            data: JSON.stringify(response, null, 2),
            status: "Success"
          });
          setName(response.groupName);
        })
        .catch(error => {
          console.log(error.response);
        });
    }
    
    function getGroupDeploymentStatus(){
      let path = "nexium/scan_status/groupid";
        console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
        API.get(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            headers: {
              'Cache-Control': 'max-age=87000',
              'Content-type': 'application/json',
            },
            queryStringParameters: {
              group_id: group
            }
        })
        .then(response => {
          setGroupStatus(response);
          console.log(response);
          console.log(groupStatus);
          setName(response.groupName);
        })
        .catch(error => {
          console.log(error.response);
        });
    }

    function getStreamsInGroup(){
    let path = "nexium/get_stream_names/by_group_id";
        console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
        API.get(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            headers: {
              'Cache-Control': 'max-age=87000',
              'Content-type': 'application/json',
            },
            queryStringParameters: {
              group_id: group
            }
        })
        .then(response => {
          console.log(response);
          let stream_names_list = [];
          for (var i = 0; i < response.length; ++i) stream_names_list[i] = {label:response[i], value:response[i]};
          console.log(stream_names_list);
          setStream_names(stream_names_list);
          console.log(stream_names);
        })
        .catch(error => {
          console.log(error.response);
        });
    }
    
    function getCSInGroup(){
    let path = "nexium/get_cs_names/by_group_id";
        console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
        API.get(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            headers: {
              'Cache-Control': 'max-age=87000',
              'Content-type': 'application/json',
            },
            queryStringParameters: {
              group_id: group
            }
        })
        .then(response => {
          console.log(response);
          let cs_names_list = [];
          for (var i = 0; i < response.length; ++i) cs_names_list[i] = {label:response[i].cs_name, value:response[i].cs_id};
          console.log(cs_names_list);
          setCSNames(cs_names_list); 
          console.log(cSNames);
        })
        .catch(error => {
          console.log(error.response);
        });
    }
    
    function getStreamsinCS(){
      let path = "nexium/get_stream_names/by_cs_id";
        console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
        API.get(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            headers: {
              'Cache-Control': 'max-age=87000',
              'Content-type': 'application/json',
            },
            queryStringParameters: {
              cs_id: cS
            }
        })
        .then(response => {
          console.log(response);
          let stream_names_list = [];
          for (var i = 0; i < response.length; ++i) stream_names_list[i] = {label:response[i], value:response[i]};
          console.log(stream_names_list);
          setStream_names(stream_names_list); 
          console.log(stream_names);
        })
        .catch(error => {
          console.log(error.response);
        });
    }
    
    function getStreamDeploymentStatus(){
    let path = "nexium/get_emp_status/stream_name";
        console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
        API.get(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            headers: {
              'Cache-Control': 'max-age=87000',
              'Content-type': 'application/json',
            },
            queryStringParameters: {
              stream_name: streamName
            }
        })
        .then(response => {
          setStreamStatus(response);
          console.log(path);
          console.log(response);
        })
        .catch(error => {
          console.log(error.response);
        });
    }
    
    function getCSDeploymentStatus(){
    let path = "nexium/scan_status/csid";
        console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
        API.get(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            headers: {
              'Cache-Control': 'max-age=87000',
              'Content-type': 'application/json',
            },
            queryStringParameters: {
              cs_id: cS
            }
        })
        .then(response => {
          setCSStatus(response);
          console.log(response);
        })
        .catch(error => {
          console.log(error.response);
        });
    }
    
    function getTicketPreview(){
    setAction("Preview Ticket");
    let path = "nexium/read";
        console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
        API.get(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            headers: {
              'Cache-Control': 'max-age=87000',
              'Content-type': 'application/json',
            },
            queryStringParameters: {
              action: "get_ticket_data",
              issue_id: ticketID
            }
        })
        .then(response => {
          console.log(response);
          setActiveBubble({
            data: JSON.stringify(response.ticket_info, null, 2),
            status: "Success"
          });
          setName(response.group_name);
        })
        .catch(error => {
          console.log(error.response);
          setActiveBubble({
            data: JSON.stringify(error.response.data, null, 2),
            status: "Success"
          });
        });
    }
    
    function getPropertyPreview(){
    setAction("Preview Property");
    let path = "nexium/read";
        console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
        API.get(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            headers: {
              'Cache-Control': 'max-age=87000',
              'Content-type': 'application/json',
            },
            queryStringParameters: {
              action: "get_ticket_data",
              issue_id: ticketID
            }
        })
        .then(response => {
          console.log(response);
          let output = {}
          output["onboard_property_payload"] = response.onboard_property_payload
          output["cr_payload"] = response.cr_payload
          setCrData(output)
          setActiveBubble({
            data: JSON.stringify(response.cr_payload, null, 2),
            status: "Success"
          });
          console.log(crData)
          setName(response.group_name);
        })
        .catch(error => {
          console.log(error.response);
        });
    }
    
    function getCSList(){
    setAction("Get CS List");
    let path = "nexium/read";
        console.log("DV_LIVE_ENVIRONMENT:", process.env.REACT_APP_DV_LIVE_ENVIRONMENT);
        API.get(
          process.env.REACT_APP_API_ENVIRONMENT, //API endpoints are configured per environment
          path, {
            headers: {
              'Cache-Control': 'max-age=87000',
              'Content-type': 'application/json',
            },
            queryStringParameters: {
              action: "get_ticket_data",
              issue_id: ticketID
            }
        })
        .then(response => {
          console.log(response);
          response.onboard_cs_payload.group_id = group;
          setActiveBubble({
            data: JSON.stringify((response.onboard_cs_payload), null, 2),
            status: "Success"
          });
          setName(group);
        })
        .catch(error => {
          console.log(error.response);
        });
    }
    

    return (
      <ContentWithSidebarPage>
      <Sidebar>
       <StreamSelector onBlur={handleSearch} onKeyDown={makeEnterBlur} underlined={true} placeholder="Stream Name"/>
       <MemberRestrictedSection includeGroups={["redactor-edit-create-config-access"]}>
        <SidebarGroup name="Onboarding New Feed?">
         <Button onClick={() => window.open('https://w.amazon.com/bin/view/Amazon_Video/PV_Playback/LiveOps/Infrastructure/reference/FeedFormation/FeedOnboardingRunbook/')}>Here is the SOP</Button>
        </SidebarGroup>
        <SidebarGroup name="Stream Config">
            <Link to= {`/feedOnboardingForm`}><Button>Create Config</Button></Link>
            <Button onClick={() => bubbleGet("stream_config/view/config", "")}>Edit Config</Button>
            <Link to= {`/editMultipleConfigs`}><Button>Edit Multiple Configs</Button></Link>
            <Link to= {`/onboardStreams`}><Button>Onboard Streams</Button></Link>
        </SidebarGroup>
      </MemberRestrictedSection>
        <SidebarGroup name="TAG Config">
          <Link to= {`/tagConfig`}><Button>Create TAG Property Configs</Button></Link>
          <Button onClick={() => getTagConfig("redactor/read", "view_tag_config")}>Edit Config</Button>
        </SidebarGroup>
        <SidebarGroup name="Nexium Actions">
          <Select style={{marginBottom: "60px"}}
            options={actionOptions}
            onChange={({ value, label }) => handleAction(value)}
            defaultValue={{ value: "", label: "Select" }}
          />
          {
            action==="View Group Properties" ? (
              <div>
                <Select style={{marginBottom: "15px"}}
                  options={groups}
                  onChange={({ value, label }) => handleGroup(value)}
                  defaultValue={{ value: "", label: "Select Group" }}
                />
                {
                  group !== "Select Group" ? (
                      <Button onClick={() => getGroupProperties()}>View Properties</Button>
                  ):
                  null
                }
              </div>
            ):
            action==="View Packager Status" ? (
                <div>
                    <Select style={{marginBottom: "15px"}}
                        options={entityOptions}
                        onChange={({ value, label }) => handleEntity(value)}
                        defaultValue={{ value: "", label: "Select Entity" }}
                    />
                    {
                    entity==="Group" ? (
                    <div>
                        <Select style={{marginBottom: "15px"}}
                            options={groups}
                            onChange={({ value, label }) => handleGroup(value)}
                            defaultValue={{ value: "", label: "Select Group" }}
                        />
                        {
                            group !== "Select Group" ? (
                                <Button onClick={() => getGroupDeploymentStatus()}>View Packager Status</Button>
                            ):
                            null
                        }
                    </div>
                    ):
                    entity==="CS" ? (
                    <div>
                        <Select style={{marginBottom: "15px"}}
                            options={groups}
                            onChange={({ value, label }) => handleGroup(value)}
                            defaultValue={{ value: "", label: "Select Group" }}
                        />
                        <Select style={{marginBottom: "15px"}}
                            options={cSNames}
                            onChange={({ value, label }) => handleCS(value)}
                            defaultValue={{ value: "", label: "Select CS Name" }}
                        />
                        {
                            group !== "Select Group" && cS !== "Select CS Name" ? (
                                <Button onClick={() => getCSDeploymentStatus()}>View Packager Status</Button>
                            ):
                            null
                        }
                        </div>
                    ):
                    entity==="Stream" ? (
                    <div>
                        <Select style={{marginBottom: "15px"}}
                            options={groups}
                            onChange={({ value, label }) => handleGroup(value)}
                            defaultValue={{ value: "", label: "Select Group" }}
                        />
                        <Select style={{marginBottom: "15px"}}
                            options={stream_names}
                            onChange={({ value, label }) => handleStream(value)}
                            defaultValue={{ value: "", label: "Select Stream Name" }}
                        />
                        {
                            group !== "Select Group" && streamName !== "Select Stream Name" ? (
                                <Button onClick={() => getStreamDeploymentStatus()}>View Packager Status</Button>
                            ):
                            null
                        }
                    </div>
                    ):
                  null
                }
              </div>
            ):
            action==="Onboard Group" ? (
              <MemberRestrictedSection includeGroups={["redactor-edit-create-config-access"]}>
                  <input style={{width: "262px", marginBottom: "10px", lineHeight: "30px", fontSize: "20px"}}
                      type="text" 
                      value={ticketID}
                      onChange={(e) => setTicketID(e.target.value)}/>
                  {
                      ticketID !== "Enter Ticket ID" ? (
                          <Button onClick ={()=> getTicketPreview()}>Preview Ticket</Button>
                      ):
                      null
                  }
              </MemberRestrictedSection>
            ):
            action==="Onboard Contribution Streams" ? (
              <MemberRestrictedSection includeGroups={["redactor-edit-create-config-access"]}>
                <Select style={{marginTop: "10px"}}
                    options={groups}
                    onChange={({ value, label }) => handleGroup(value)}
                    defaultValue={{ value: "", label: "Select Group" }}
                />
                <input style={{ width: "262px", marginBottom: "10px", lineHeight: "30px", fontSize: "20px" }}
                    type="text" 
                    value={ticketID}
                    onChange={(e) => setTicketID(e.target.value)}/>
                {
                    ticketID !== "Enter Ticket ID" ? (
                        <Button onClick ={()=> getTicketPreview()}>Preview Ticket</Button>
                    ):
                    null
                }
              </MemberRestrictedSection>
          ):
        null
      }
      </SidebarGroup>
      </Sidebar>
      <BubbleGroup>
        {streams.map((stream, index) => (
        <Bubble key={index} data={stream} headerKeys={['name', 'command']} headerClick={() => bubbleClickHandler(index)}>
          { action === "create_cr" ?
            <Link to={`/review/${formatURL(stream.data)}`}>{formatURL(stream.data)}</Link>
            :
            action==="View Packager Status" && entity=== "Group" && groupStatus.length !== 0 ? (
              <StyledTable>
                <table>
                  <tr>
                    <th>Stream Name</th>
                    <th>CS Name</th>
                    <th>CS ID</th>
                    <th>EMP Status</th>
                  </tr>
                  {groupStatus.map((val, key) => {
                    return (
                    <tr key={key}>
                      <td>{val.name}</td>
                      <td>{val.cs_name}</td>
                      <td>{val.cs_id}</td>
                      <td style={{ color: "forestgreen" }}><strong>{val.emp_status}</strong></td>
                    </tr>
                  );
                  })}
                </table>
              </StyledTable>
            ):
            action==="View Packager Status" && entity=== "CS" && cS !== "Select CS Name" && cSStatus.length !== 0 ? (
              <StyledTable>
                <table>
                  <tr>
                    <th>Stream Name</th>
                    <th>EMP Status</th>
                    <th>Infra Status</th>
                  </tr>
                  {cSStatus.map((val, key) => {
                    return (
                    <tr key={key}>
                      <td>{val.name}</td>
                      <td style={{ color: "forestgreen" }}><strong>{val.emp_status}</strong></td>
                      <td style={{ color: "forestgreen" }}><strong>{streamStatus.infra_status}</strong></td>
                    </tr>
                  );
                  })}
                </table>
              </StyledTable>
            ):
            action==="View Packager Status" && entity === "Stream" && streamName !== "Select Stream Name" && streamStatus !== [{}] ? (
              <StyledTable>
                <table>
                  <tr>
                    <th>Name</th>
                    <th>CS Name</th>
                    <th>CS ID</th>
                    <th>EMP Status</th>
                    <th>Infra Status</th>
                  </tr>
                  <tr>
                    <td>{streamName}</td>
                    <td>{streamStatus.cs_name}</td>
                    <td>{streamStatus.cs_id}</td>
                    <td style={{ color: "forestgreen" }}><strong>{streamStatus.emp_status}</strong></td>
                    <td style={{ color: "forestgreen" }}><strong>{streamStatus.infra_status}</strong></td>
                  </tr>
                </table>
              </StyledTable>
            ):
            action==="Preview Ticket" && group=== "Select Group" ? (
              <div>
                <ReactJson src={getJson(stream.data, stream.status)}
                  name={name}
                  collapsed={false}
                  onEdit={false}
                  onAdd={false}
                  onDelete={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  displayArrayKey={false}
                  theme="bright-inverted"
                />
                <Button style= {{top: "90px", right: "40px", padding: "1rem", position: "fixed"}}
                 onClick ={()=> getPropertyPreview()}>Create Property Config
                </Button>
              </div>
            ):
            action==="Preview Property"? (
              <div>
                <ReactJson src={crData.cr_payload}
                  name={stream["group_name"]}
                  collapsed={false}
                  onEdit={false}
                  onAdd={false}
                  onDelete={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  displayArrayKey={false}
                  theme="bright-inverted"
                />
                <Button style= {{top: "90px", right: "100px", padding: "1rem", position: "fixed"}}
                onClick={() => bubblePut("redactor/cr", "", crData, stream)}>Create CR
                </Button>
              </div>
            ):
            group!== "Select Group" && action==="Preview Ticket" ? (
              <div>
                <ReactJson src={getJson(stream.data, stream.status)}
                  name={name}
                  collapsed={false}
                  onEdit={false}
                  onAdd={false}
                  onDelete={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  displayArrayKey={false}
                  theme="bright-inverted"
                />
                <Button style= {{top: "90px", right: "40px", padding: "1rem", position: "fixed"}}
                 onClick ={()=> getCSList()}>Create CS list
                </Button>
              </div>
            ):
            action==="Get CS List" ? (
              <div>
                <ReactJson src={getJson(stream.data, stream.status)}
                  name={name}
                  collapsed={false}
                  onEdit={false}
                  onAdd={false}
                  onDelete={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  displayArrayKey={false}
                  theme="bright-inverted"
                />
                <Button style= {{top: "90px", right: "100px", padding: "1rem", position: "fixed"}}
                onClick={() => bubblePut("redactor/cr", "", stream.data, stream["group_id"])}>Create CR
                </Button>
              </div>
            ):
            action==="View Properties" ? (
              <ReactJson src={getJson(stream.data, stream.status)}
                name={name}
                collapsed={false}
                onEdit={false}
                onAdd={false}
                onDelete={false}
                displayDataTypes={false}
                displayObjectSize={false}
                displayArrayKey={false}
                theme="bright-inverted"
              />
            ):
            <div>
              <ReactJson src={getJson(stream.data, stream.status)}
                name={stream.name}
                collapsed={false}
                onEdit={(e)=>onEdit(e)}
                onAdd={(e)=>onAdd(e)}
                onDelete={(e)=>onDelete(e)}
                displayDataTypes={false}
                displayObjectSize={false}
                displayArrayKey={false}
                theme="bright-inverted"
              />
              <Button style= {{bottom: "40px", right: "40px", padding: "1rem", position: "fixed"}}
                onClick={() => bubblePut("redactor/cr", stream.data, newJson, stream.name)}>Create CR
              </Button>
            </div>
          }
          </Bubble>
        ))}
      </BubbleGroup>
    </ContentWithSidebarPage>
  );
}

export default Redactor;