import React from "react";
import { FlexHeader } from "./BasicControls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBoolean } from "react-hanger";

export function Accordion(props) {
  const active = useBoolean(true);

  return (
    <div>
      <FlexHeader>
        <h2>{props.title}</h2>
        <div className="float-right">
          {props.rerunAll ? (
            <FontAwesomeIcon
              icon="undo"
              size="lg"
              onClick={() => props.rerunAll}
            />
          ) : null}
          {props.deleteAll ? (
            <FontAwesomeIcon
              icon="trash"
              size="lg"
              onClick={() => props.deleteAll}
            />
          ) : null}
          <FontAwesomeIcon
            icon={active.value ? "caret-down" : "caret-left"}
            size="lg"
            onClick={() => active.toggle()}
            style={{ cursor: "pointer" }}
          />
          {props.addChild ? (
            <FontAwesomeIcon
              icon="plus"
              size="lg"
              onClick={() => props.addChild}
            />
          ) : null}
        </div>
      </FlexHeader>
      <hr />
      {active.value ? <div>{props.children}</div> : null}
    </div>
  );
}
