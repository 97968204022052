//https://axios-http.com/docs/handling_errors
export function handleError(error) {
  let returnValue = "";
  if (error instanceof Error) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response && "data" in error.response) {
      returnValue = error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      returnValue = error.request;
    }
  } else if (error instanceof String) {
    console.log("I solemnly swear I have not mangled the error message")
    returnValue = error;
  } else {
    returnValue = JSON.stringify(error);
  }

  // Format Python Traceback if applicable
  if (returnValue.indexOf('Traceback') > -1){
    returnValue = formatPythonTraceBack(returnValue)
  }
  console.log("Parsed Error:", returnValue)
  return returnValue;
}


function formatPythonTraceBack(traceback){
  console.log("Attempting to parse as Python Traceback", traceback)
  let returnValue = traceback.replace(/\\+n/g, '\n') //Unescape newlines
  returnValue = returnValue.replace(/\\+"/g, '"') // Unescape quotation marks
  returnValue = returnValue.replace('""', '"') // Replace doubled quotes right next to each other
  console.log("Parsed Traceback:", returnValue)

  return returnValue
}