import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

const StyledNavBar = styled.ul`
  margin: 0;
  list-style-type: none;
  background-color: Black;
  color: White;
  display: flex;
  height: 100%;

  align-self: center;
  align-items: center;
  font-size: 16px;
`;

const NavItem = styled.li`
  margin-right: 10px;
  padding: 5px;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: white;
`;

export default function NavBar() {
  return (
    <StyledNavBar>
      <NavItem>
        Dante{" "}
        {process.env.REACT_APP_DV_LIVE_ENVIRONMENT === "prod"
          ? null
          : process.env.REACT_APP_DV_LIVE_ENVIRONMENT}
      </NavItem>
      <NavItem>
        <NavLink to="/">Stream Config Viewer</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/stepfunctions">Step Functions</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/pipeline_analysis">Pipeline Analysis</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/readiness_report">Readiness Report Card</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/hwconfig">Hardware Database</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/dxconfig">DX Database</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/redactor">Redactor</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/profile">Profile</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/nuke">Nuclear Button</NavLink>
      </NavItem>
    </StyledNavBar>
  );
}
