import React, { Component, createContext } from "react";
import { Auth } from "aws-amplify";

// create the context
export const AuthContext = createContext();

// create a provider
export class AuthProvider extends Component {
  state = {
    isLoading: true,
    isAuthenticated: false,
    user: null,
  };

  componentDidMount() {
    this.initializeAuth();
  }

  // Initialize auth credentials
  // this has to be it's own function so we can use
  // async await
  initializeAuth = async () => {
    let user = await Auth.currentUserInfo();

    const userCredentials = await Auth.currentUserCredentials();

    const isAuthenticated = userCredentials["authenticated"] === true;
    if (isAuthenticated) {
      user = await Auth.currentAuthenticatedUser();
    }

    // Currently, the user has to log out to get group changes
    // this is an attempt to fix that. If it's commented out
    // it doesn't work

    // try {
    //   const cognitoUser = await Auth.currentAuthenticatedUser();
    //   const currentSession = await Auth.currentSession();
    //   cognitoUser.refreshSession(
    //     currentSession.refreshToken,
    //     (err, session) => {
    //       console.log("session", err, session);
    //       const { idToken, refreshToken, accessToken } = session;
    //       // do whatever you want to do now :)
    //     }
    //   );
    // } catch (e) {
    //   console.log("Unable to refresh Token", e);
    // }

    this.setState({ isLoading: false, isAuthenticated, user });
  };

  render() {
    const { isLoading, isAuthenticated, user } = this.state;
    const { children } = this.props;

    let username;
    let groups;

    console.log("User", user);

    if (user != null) {
      username = user.signInUserSession.idToken.payload.identities[0].userId;
      groups = user.signInUserSession.idToken.payload["cognito:groups"];
    }

    const configObject = {
      isLoading,
      isAuthenticated,
      user,
      username,
      groups,
    };

    return (
      <AuthContext.Provider value={configObject}>
        {children}
      </AuthContext.Provider>
    );
  }
}
