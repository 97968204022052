import React from 'react';
import styled from 'styled-components'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DefaultButton } from '@fluentui/react';


export const Button = styled(DefaultButton)
`
    background-color: ${(props) => props.disabled ? 'lightgrey':'#df7514'};
    color: White;
    border-color: white;
`

export function ToggleButton(props) {
    return (
        <FormControlLabel
            control={
              <Switch
                checked={props.checked}
                onChange={props.onChange}
                color="primary"
                style={props.style}
              />
    }
    label = { props.label.toUpperCase() } labelPlacement="bottom" />
)}

export const AddButton = styled.div `
  padding: 1rem;
  font-size: 1rem;
  border-radius: 50%;
  color: white;
  position: fixed;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  background-color: Orange;
`

export const StyledInput = styled.div `
    input{
        width: 100%;
        height: 100%;
        border-width: 0px 0px 1px;
        font-size: 1em;
    }
    
    input:focus{
        border-color: Salmon;
        border-width: 0px 0px 2px;
    }
`

export const FlexHeader = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;

    .float-right{

      display: flex;
    align-items: inherit;
    margin-left: auto;
    }

    svg{
      padding: 10px;
    }
    
`

