import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { handleError } from "../utilities/galileoApi";

//const apiEnv = "Gamma"; //Get this from localStorage or something
const apiEnv = process.env.REACT_APP_API_ENVIRONMENT; //API endpoints are configured per environment

export function useAmplifyGet(path, params) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [runCount, setRuncount] = useState(0);

  const headers = {
    "Content-Type": "application/json",
  };

  const requestConfig = {
    headers: headers,
    queryStringParameters: params,
  };

  useEffect(() => {
    setLoading("loading...");
    setData(null);
    setError(null);

    API.get(
      apiEnv, //API endpoints are configured per environment
      path,
      requestConfig
    )
      .then((response) => {
        //console.log('Response', response)
        setLoading(false);
        setData(response);
      })
      .catch((error) => {
        //console.log(error.response);
        setLoading(false);
        setError(handleError(error));
      });
  }, [path, runCount]);

  function run() {
    setRuncount(runCount + 1);
  }

  return [data, loading, error, run];
}

export function useAmplifyPost(path, params) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [runCount, setRuncount] = useState(0);

  const headers = {
    "Content-Type": "application/json",
  };

  const requestConfig = {
    headers: headers,
    body: params,
  };

  useEffect(() => {
    setLoading("loading...");
    setData(null);
    setError(null);

    API.post(
      apiEnv, //API endpoints are configured per environment
      path,
      requestConfig
    )
      .then((response) => {
        //console.log('Response', response)
        setLoading(false);
        setData(response);
      })
      .catch((error) => {
        //console.log(error.response);
        setLoading(false);
        setError(handleError(error));
      });
  }, [path, runCount]);

  function run() {
    setRuncount(runCount + 1);
  }

  return [data, loading, error, run];
}
