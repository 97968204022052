import React from 'react';
import styled from 'styled-components'

const StyledSlate = styled.div `
  display: flex;
  flex-direction: column;
  height: calc(100vh - 5rem);
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  
  div{
    width: auto;
  }
`

function Slate(props) {
  return (
    <StyledSlate>
    {props.children}
    </StyledSlate>
  );
}

export default Slate;
