//Source: https://material-table.com/#/docs/features/editable

/* 
This version allows bulk edit to entire table, similar to spreadsheet.
Upon accpeting change, rows with changes are presented.
We can then loop through changes to update state and also parallely update DB.
This approach seems better, although I'll have to see how state changes goes,
and how it fares with respect to time.
*/
import React, {useContext, useState, useEffect} from "react";
import MaterialTable, { MTableToolbar } from 'material-table';
import Alert from '@material-ui/lab/Alert';
import LinkIcon from '@material-ui/icons/Link';
import { API, Auth } from 'aws-amplify';

import { DxContext } from "../contexts/DxContextProvider";


/** 
 * Create nested JSON hirarchy per DB schema
 *  returns unflattened data
 *  @param {string} data -  data to be unflattened
*/
function unflattenDbData(data) {
  let result = {}
  for (const item in data) {
    let objKeys = item.split('.')
    objKeys.reduce(function(reducer, currentItem, currentIdx) {
      return reducer[currentItem] || (
        reducer[currentItem] = isNaN(
          Number(objKeys[currentIdx + 1])
        ) ? (
          objKeys.length - 1 === currentIdx ? data[item] : {}
        ) : []
      )
    }, result)
  }
  //remove tableData key thats added by muitable lib
  if ("tableData" in result) {
    delete result["tableData"]
  }
  return result
}


export default function DXTable(props) {
  const [data, setData] = useState(props.rows);
  const [ dxState, dxDispatch] = useContext(DxContext);

  const rowsPerPage = 10;

  const columns = props.columns.map((column) => {
    return { ...column };
  });  

  function createDbData(config_dict_list) {
    const path = "dx_management/upload";
    API.post(
      process.env.REACT_APP_API_ENVIRONMENT, 
      path, {
        response: true,
        body: {
          dxconfig_dicts : config_dict_list,
          force: false,
          action : "upload_config",
        }
      })
    .then(response => {
      console.log(response);
      window.location.reload();
    })
    .catch(error => {
      console.log(error)
      console.log(error.response);
    });
  }

  /** 
   * Update existing DB data
   *  returns DDB response
   *  @param {string} config_dict_list -  data to be  uploaded to DDB
  */
  function updateDbData(config_dict_list) {
    const path = "dx_management/merge";
    API.post(
      process.env.REACT_APP_API_ENVIRONMENT, 
      path, {
        response: true,
        body: {
          dxconfig_dicts : config_dict_list,
          action : "merge_dxconfig",
        }
      })
    .then(response => {
      console.log(response);
      window.location.reload();
    })
    .catch(error => {
      console.log(error)
      console.log(error.response);
    });
  }


  /** 
   * delete existing DB data
   *  returns DDB response
   *  @param {string} config_dict -  data to be  deleted from DDB
  */
  function deleteDbRow(config_dict) {
    const path = "dx_management/delete";
    console.log("deleting data from db");
    API.post(
      process.env.REACT_APP_API_ENVIRONMENT, 
      path, {
        response: true,
        body: {
          action : "delete_config",
          name : config_dict,
        }
      })
    .then(response => {
      console.log(response);
      window.location.reload();
    })
    .catch(error => {
      console.log(error)
      console.log(error.response);
    });
  }


  /** 
   * new row add handler, calls createDbData
   *  returns nothing
   *  @param {string} newRowData -  new row data to be processed
  */
  function handleRowAdd(newRowData){
    const updatedData = unflattenDbData(newRowData);
    createDbData(updatedData);
    dxDispatch({ type: 'SET_DB_DATA_RECEIVED', dbDataReceived: false });
  }

  /** 
   * delete row handler, calls deleteDbRow
   *  returns nothing
   *  @param {string} rowData -  rowData to be processed
  */
  function handleRowDelete(rowData) {
    const updatedData = unflattenDbData(rowData);
    console.log(dxState.dxCurrentUser);
    setData([...data, updatedData]);
    deleteDbRow(updatedData['name']);
    dxDispatch({ type: 'SET_DB_DATA_RECEIVED', dbDataReceived: false });
  }

  /** 
   * row update handler, calls updateDbData
   *  returns nothing
   *  @param {string} rowData -  rowData to be processed
  */
  function handleRowUpdates(rowData) {
    console.log(rowData);
    const updatedData = [];
    for (const [key, value] of Object.entries(rowData)){
      const tmpData = unflattenDbData(rowData[key].newData);
      tmpData["_user"] = dxState.dxCurrentUser;
      updatedData.push(tmpData);
    }
    updateDbData(updatedData);
    dxDispatch({ type: 'SET_DB_DATA_RECEIVED', dbDataReceived: false });
  }

  const rowDataValidated = (newData) => {
    console.log(newData);
    const found = data.filter(obj => obj.name === newData.name);
    if (found.length  !== 0) {
      console.log("duplicate name found=>" + newData.name);
      dxDispatch({ 
        type: 'SET_MUI_TABLE_ALERT',
        tableAlertFlag: true,
        tableAlertMsg:  `duplicate name found ${newData.name}`,
      });
      return false;
    }
    dxDispatch({ 
      type: 'SET_MUI_TABLE_ALERT',
      tableAlertFlag: false,
      tableAlertMsg:  '',
    });
    return true;
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(
      (userData) => {
        console.log(userData);
        dxDispatch({ type: 'SET_DX_CURRENTUSER', dxCurrentUser: userData["username"] });
      }
    );
  }, []);
  
  
  return (
    <MaterialTable
      // title="DX DB Table"
      columns={columns}
      data={data}
      actions={[
        {
          icon: LinkIcon,
          tooltip: 'Suggestions & Bug Report Quip Link',
          isFreeAction: true,
          // onClick: (event) => window.open(
          //   'https://quip-amazon.com/faMSAk1qb3nx/HwConfig-Suggestions','_blank','resizable=yes'
          // )
          onClick: (event) => window.open(
            'https://quip-amazon.com/4bTNAQYaJHVO/Hardware-Inventory-Project-HwConfig','_blank','resizable=yes'
          )
        }
      ]}
      components={{
        Toolbar: props => (
          <div>
            <MTableToolbar {...props} />
            { 
              dxState.tableAlertFlag && 
              <div style={{padding: '0px 10px'}}>
                <Alert severity="error"> <bold> {dxState.tableAlertMsg} </bold> </Alert>
              </div>
            }
          </div>
        ),
      }}
      options={{
        // filtering: true,
        // grouping: true,
        // paging: false,
        showTitle: false,
        exportButton: true,
        search: true,
        sorting: true,
        whiteSpace: 'nowrap',
        // actionsColumnIndex: -1,
        headerStyle: {
          backgroundColor: '#01579b',
          color: '#FFF',
          padding: '10px',
          position: 'sticky',
          top: 0
        },
        maxBodyHeight: '70vh',
        rowStyle: x => {
          if (x.tableData.id % 2) {
              return {backgroundColor: "#f2f2f2",fontSize: 12 }
          } else {
            return {fontSize: 12 }
           }
      },
      pageSize: rowsPerPage,
      pageSizeOptions: [5, 10, 20, { value: data.length, label: 'All' }],
      addRowPosition: 'first',
      }}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
              setTimeout(() => {
                  /* setData([...data, newData]); */
                  if (!rowDataValidated(newData)){
                    reject();
                  } else {
                    handleRowAdd(newData);
                    resolve();
                  }
                 
              }, 1000);
          }),
        onBulkUpdate: changes =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              handleRowUpdates(changes);
              resolve();
            }, 1000);
          }), 
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              handleRowDelete(oldData);
              resolve();
            }, 1000);
          }),     
      }}
    />
  );
}