import React from "react";
import { useField, useFormikContext } from "formik";
import Select from "react-select";

export const MultiSelect = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  return (
      <React.Fragment>
          <Select isMulti
            {...field}
            {...props}
            // className="form-control"
            options={props.options}
            selected={(field.value) || null}
            onChange={val => {
                console.log(val); //DEBUG
                setFieldValue(field.name, val);
            }}
            />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null
    }
      </React.Fragment>
  );
};

export default MultiSelect;