import React from "react";

import { ColumnFlexPage } from "../components/Page";
import { MemberRestrictedSection } from "../components/RoleBasedAccessControl";
import { StatusBadge } from "../components/StatusBadge";
import {
  Red,
  Orange,
  Yellow,
  Green,
  Blue,
  Purple,
  LightGray,
} from "../components/ColorTheme";

export default function RbacTestPage() {
  return (
    <ColumnFlexPage>
      <h1>Performing Membership Checks</h1>
      <div>
        <h3>Dante Admin Role</h3>
        <MemberRestrictedSection includeGroups={["dante-admin-access"]}>
          <StatusBadge color={Orange} status="Ready">
            You Are Special
          </StatusBadge>
        </MemberRestrictedSection>
      </div>
      <div>
        <h3>Include by Username</h3>
        <MemberRestrictedSection includeUsernames={["muraokad", "huweih"]}>
          <StatusBadge color={Purple} status="Ready">
            You are Enough
          </StatusBadge>
        </MemberRestrictedSection>
        <MemberRestrictedSection includeUsernames={["cwcook"]}>
          <StatusBadge color={Purple} status="Ready">
            You are Charlie
          </StatusBadge>
        </MemberRestrictedSection>
      </div>
      <div>
        <h3>Support Engineer Role</h3>
        <MemberRestrictedSection includeGroups={["dv-live-ops-support-eng"]}>
          <StatusBadge status="Ready">You are Needed</StatusBadge>
        </MemberRestrictedSection>
      </div>
      <div>
        <h3>Exclude by Username</h3>
        <MemberRestrictedSection
          includeGroups={["us-west-2_JRGz3ZQzO_AWSFederate"]}
          excludeUsernames={["huweih"]}
        >
          <StatusBadge color={Red}>You are Not Omar</StatusBadge>
        </MemberRestrictedSection>
      </div>
    </ColumnFlexPage>
  );
}
