/* eslint-disable */

export const oauthconfig = {
  "domain": "dvlivedante8da310fb-8da310fb-dev.auth.us-west-2.amazoncognito.com",
  "scope": [
    "phone",
    "email",
    "openid",
    "profile",
    "aws.cognito.signin.user.admin"
  ],
  "redirectSignIn": "https://dante.amazonavoc.com/",
  "redirectSignOut": "https://dante.amazonavoc.com/signOut/",
  "responseType": "code"

}

export const oauthconfigGamma = {
  "domain": "dvlivedante8da310fb-8da310fb-dev.auth.us-west-2.amazoncognito.com",
  "scope": [
    "phone",
    "email",
    "openid",
    "profile",
    "aws.cognito.signin.user.admin"
  ],
  "redirectSignIn": "https://gamma.dante.amazonavoc.com/confirmation",
  "redirectSignOut": "https://gamma.dante.amazonavoc.com/signOut/",
  "responseType": "code"

}

