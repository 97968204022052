import React from 'react';
import styled from 'styled-components'
import { DefaultEffects } from "@fluentui/react";

export const FlexboxRows = styled.section `
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
`

export const Surface = styled.div`
box-shadow: ${DefaultEffects.elevation8};
`
export const Container = styled.div`
    margin-top: 2em;
    margin-bottom: 2em;
    padding-bottom: 2em;
    color: #666;
`


